<template>
	<div
		class="common-page"
		@mousemove="move"
		@mouseup="end"
	>
		<img
			class="next-btn"
			src="@/assets/image/next.png"
			@click.stop="gonext()"
		/>
		<div class="container">
			<img src="./images/L5_认识五线谱_bg.png" />
			<img
				class="staff"
				src="./images/五线.png"
			/>
			<!-- <img
				class="beat"
				src="./images/2 _ 4.png"
			/> -->
		</div>
		<div class="footer">
			<img
				:src="item.src"
				v-for="(item, index) in notes"
				:key="index"
				:data-height="item.height"
				:style="{ height: item.height }"
				@mousedown="start"
			/>
		</div>
		<img
			@click="reset"
			class="reset"
			src="./images/reset.png"
		/>
	</div>
</template>

<script>
import { Resize } from "@/utils/Resize";
export default {
	components: {},
	data() {
		return {
			resize: new Resize(),
			isMove: false,
			node: null,
			offset: {},
			notes: [
				{
					src: require("./images/高音谱号.png"),
					height: "43vh",
				},
				{
					src: require("./images/低音谱号.png"),
					height: "20vh",
				},
				{
					src: require("./images/四分音符.png"),
					height: "23vh",
				},
				{
					src: require("./images/四分音符x2.png"),
					height: "24vh",
				},
			],
		};
	},
	mounted() {
		this.resize.on(this.reset);
	},
	destroyed() {
		this.resize.unListen();
	},
	methods: {
		gonext() {
			this.$router.push("/lesson5/page?page=7");
		},
		start(e) {
			this.isMove = true;
			const { target, clientX, clientY, offsetX, offsetY } = e;
			this.node = target.cloneNode();
			this.node.classList.add("note");
			this.node.onmousedown = (event) => {
				this.isMove = true;
				this.node = event.target;
				this.offset = {
					x: event.offsetX,
					y: event.offsetY,
				};
			};
			this.offset = {
				x: offsetX,
				y: offsetY,
			};
			this.node.style.cssText = `left: ${clientX - offsetX}px;top: ${
				clientY - offsetY
			}px;height: ${this.node.dataset.height}`;
			const father = document.querySelector(".common-page");
			father.appendChild(this.node);
		},
		move(e) {
			if (this.isMove) {
				const { clientX, clientY } = e;
				this.node.style.cssText = `left: ${clientX - this.offset.x}px;top: ${
					clientY - this.offset.y
				}px;height: ${this.node.dataset.height}`;
			}
		},
		end() {
			this.isMove = false;
			this.node = false;
		},
		reset() {
			const els = document.querySelectorAll(".note");
			els.forEach((el) => el.remove());
		},
		changeOrder() {
			let oldOrder = JSON.stringify(this.notes);
			this.notes = this.notes.sort(() => {
				return Math.random() - 0.5;
			});
			let newOrder = JSON.stringify(this.notes);
			if (oldOrder == newOrder) {
				this.changeOrder();
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("./images/bg_L5_normal.jpg");
}

.container {
	position: absolute;
	width: 90.781vw;
	top: 9vh;
	left: 4.583vw;
	z-index: 10;
	img:first-child {
		width: 100%;
	}
	.staff {
		position: absolute;
		width: 71.875vw;
		top: 2vh;
		left: 9.0625vw;
		cursor: pointer;
		z-index: 11;
	}
	.beat {
		position: absolute;
		top: 174px;
		left: 16vw;
		z-index: 12;
		transform: scale(0.9);
	}
}
.footer {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 0;
	z-index: 11;
	left: 50%;
	transform: translateX(-50%) scale(0.9);
	height: 30vh;
	img {
		// height: 200px;
		margin: 0 40px;
		cursor: pointer;
	}
}
.note {
	position: absolute;
	z-index: 15;
	transform: scale(0.9);
	cursor: pointer;
}
.reset {
	width: 148px;
	height: auto;
	position: absolute;
	bottom: 20px;
	right: 25px;
	cursor: pointer;
	z-index: 99;
}
</style>
