<template>
  <div class="common-game-page">
    <img
      src="@/assets/image/next.png"
      class="next-btn"
      @click.stop="gonext()"
    />
    <img class="game-title" src="@/assets/image/playtime-2.png" />
    <img class="musical-note" src="@/assets/image/note_start.png" />

    <div class="custom-audio">
      <audio
        ref="audioTip"
        :src="audio.src"
        controls
        autoplay
      ></audio>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      audio: {
        // 消息通知
        src: require("@/assets/music/xiao-tiao-zao-yuanchang.mp3"),
      },
    };
  },
  mounted() {},
  methods: {
    gonext() {
      this.$router.push("/lesson4/game-4");
    },
  },
};
</script>
  
  <style scoped lang="scss">
@import "~@/assets/scss/game-page.scss";
.common-game-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-image: url("~@/assets/image/back04.png");
  .musical-note {
    position: absolute;
    bottom: 220px;
    height: 440px;
  }
  .custom-audio {
    position: absolute;
    bottom: 100px;
  }
}
</style>