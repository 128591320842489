<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson20/page?page=2')"
			class="next-btn"
		/>
		<!-- <audio
			ref="audioPlayer"
			:src="audioSrc"
			style="display: none"
		></audio> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			// audioSrc: require("./audio/泡泡声音.mp3"),
			list: [],
		};
	},
	methods: {},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson20/images/bg.png");
	.item {
		position: absolute;
		width: 270px;
		cursor: pointer;
		transform: rotateY(var(--y));
		transition: transform 0.3s;
		&-fish {
			width: 100%;
		}
		&-node {
			position: absolute;
			width: 50%;
			left: 35%;
			bottom: 40px;
		}
		&.scale {
			transform: scale(1.2) rotateY(var(--y)) !important;
		}
	}
}
</style>
