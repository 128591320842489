<template>
  <div class="common-page" @click="changetalk()">
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />
    <Magic v-if="openmagic"></Magic>
    <LessonEnd v-if="isEnd" nextLessonPath="/lesson4/page-home" />
    <div class="musician-box">
      <img src="@/assets/image/role/musician.png" class="musician" />
    </div>

    <div class="magician-box">
      <img src="@/assets/image/role/magician.png" class="magician" />
    </div>

    <div class="knight-box">
      <img src="@/assets/image/role/knight.png" class="knight" />
    </div>
    <div class="shimengmo-box">
      <img src="@/assets/image/role/shimengmo.png" class="shimengmo" />
    </div>
    <ChatDialog
      :talkList="talkList"
      :talkIndex="talkIndex"
      @audioStateChange="(state) => (isTalking = state)"
    ></ChatDialog>
  </div>
</template>

<script>
const Magic = () =>
  import(/* webpackChunkName:"magic-animation" */ "@/components/Magic/index.vue");
export default {
  data() {
    return {
      talkList: [
        {
          talker: "shimengmo",
          talkerCHN: "食梦貘",
          content:
            "是啊，在我睡觉的时候才能潜入其他人的梦乡，替他们吃掉恶梦。但是我现在失眠了，就会有很多人做噩梦。",
          audio: require("@/assets/audio/lesson3/page5-shimengmo-voice1.mp3"),
        },
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content: "所以我们能帮助你吗？",
          audio: require("@/assets/audio/lesson3/page5-musician-voice1.mp3"),
        },
        {
          talker: "shimengmo",
          talkerCHN: "食梦貘",
          content:
            "那太好了。可是我已经想了很多的办法都不能治疗我的失眠，你们真的能够治疗我吗？",
          audio: require("@/assets/audio/lesson3/page5-shimengmo-voice2.mp3"),
        },
      ],
      talkIndex: -1,
      isTalking: true,
      openmagic: false,
      isEnd: false,
    };
  },
  components: {
    Magic,
  },
  mounted() {},
  methods: {
    gonext() {
      this.isEnd = true;
    },
    changetalk() {
      if (this.talkIndex < this.talkList.length - 1) {
        this.talkIndex += 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.common-page {
  background-image: url("~@/assets/image/back04.png");
}
.magician-box {
  left: 620px;
  bottom: 260px;
}
.knight-box {
  left: 900px;
  bottom: 120px;
}
.musician-box {
  left: 260px;
  bottom: 100px;
}
.shimengmo-box {
  left: 560px;
  bottom: 120px;
}
</style>