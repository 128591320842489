<template>
	<div
		v-if="isPageStart"
		class="cover-page common-lesson-page"
		:style="{ backgroundImage: `url(${pageConfig.pageBg})` }"
	>
		<img
			class="page-cover"
			:src="pageConfig.pageTitleImage"
		/>
		<img
			class="start-btn"
			:src="pageConfig.pageBtnImage"
			@click="$router.push(pageConfig.nextPageRoute)"
		/>
		<div
			class="custom-audio"
			v-if="pageConfig.audio"
			:style="{
				display:
					pageConfig.audio && pageConfig.audio.controls ? 'block' : 'none',
			}"
		>
			<audio
				ref="audioTip"
				:src="pageConfig.audio && pageConfig.audio.src"
				:autoplay="pageConfig.audio && pageConfig.audio.autoplay"
				:loop="pageConfig.audio && pageConfig.audio.loop"
				:controls="pageConfig.audio && pageConfig.audio.controls"
			></audio>
		</div>
	</div>
	<div
		v-else
		class="common-page common-lesson-page"
		:style="{ backgroundImage: `url(${pageConfig.pageBg})` }"
		@click="changetalk()"
	>
		<img
			:src="pageConfig.pageNextBtnImage || '@/assets/image/next.png'"
			@click.stop="goNextPage(pageConfig)"
			class="next-btn"
		/>
		<div
			class="role-box"
			:class="role.roleClass + '-box'"
			:style="role.style"
			v-for="role of pageConfig.roles"
			:key="role.roleClass"
		>
			<img
				:src="role.imgSrc"
				:class="role.roleClass"
				:style="role.roleStyle"
			/>
		</div>
		<template v-if="pageConfig.otherElement">
			<OtherElement
				v-for="(element, elIndex) of pageConfig.otherElement"
				:key="elIndex"
				:element="element"
			/>
		</template>
		<ChatDialog
			:talkList="pageConfig.talkList"
			:talkIndex="talkIndex"
			@audioStateChange="(state) => (isTalking = state)"
		/>
		<div
			v-if="pageConfig.audioPlayer"
			class="custom-audio"
			:style="{
				display: pageConfig.audioPlayer.controls ? 'block' : 'none',
				...pageConfig.audioPlayer.style,
			}"
		>
			<audio
				ref="audioPlayer"
				@ended="audioPlayerEnded"
				:src="pageConfig.audioPlayer.src"
				:style="{ display: pageConfig.audioPlayer.controls ? 'block' : 'none' }"
				:autoplay="pageConfig.audioPlayer.autoplay"
				:loop="pageConfig.audioPlayer.loop"
				:controls="pageConfig.audioPlayer.controls"
				@timeupdate="timeupdate"
			></audio>
		</div>
		<audio
			@ended="pageAudioEnded"
			v-if="pageConfig.audio"
			ref="bgAudio"
			:src="pageConfig.audio.src"
			:style="{ display: pageConfig.audio.controls ? 'block' : 'none' }"
			:loop="pageConfig.audio.loop"
			:autoplay="pageConfig.audio.autoplay"
			:controls="pageConfig.audio.controls"
		></audio>
		<LessonEnd
			v-if="isEnd"
			:nextLessonPath="pageConfig.nextPageRoute"
		/>
		<MagicBook
			@close="showMagicBook = false"
			v-if="showMagicBook"
			:animalSrc="animalDiamond"
		/>
		<Magic v-if="showMagic"></Magic>
	</div>
</template>

<script>
import OtherElement from "./other-element.vue";
import MagicBook from "@/components/MagicBook/index.vue";
const Magic = () =>
	import(
		/* webpackChunkName:"magic-animation" */ "@/components/Magic/index.vue"
	);
export default {
	name: "",
	components: { OtherElement, MagicBook, Magic },
	mixins: [],
	props: {
		allPageConfig: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			talkIndex: -1,
			isTalking: false,
			isEnd: false,
			showMagicBook: false,
			showMagic: false,
			animalDiamond: "",
		};
	},
	computed: {
		isPageStart() {
			return !this.$route.query.page;
		},
		pageConfig() {
			let page = this.$route.query.page;
			if (this.isPageStart) {
				return this.allPageConfig.lessonStart;
			} else {
				return this.allPageConfig[page];
			}
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.talkIndex = -1;
				if (this.pageConfig.audio) {
					this.$nextTick(() => {
						let audio = this.$refs.bgAudio;
						if (audio) {
							audio.pause();
							audio.currentTime = 0;
							this.isTalking = true;
							this.$nextTick(() => {
								audio.currentTime = 0;
								audio.play();
							});
						}
					});
				} else {
					let audio = this.$refs.bgAudio;
					if (audio) {
						this.isTalking = false;
						audio.pause();
						audio.currentTime = 0;
					}
				}
			},
		},
	},
	filter: {},
	created() {},
	mounted() {},
	methods: {
		changetalk() {
			if (this.isTalking) {
				// 正在读旁白或者正在播放对话内容
				// let NODE_ENV = process.env.NODE_ENV;
				// if (NODE_ENV !== "development") {
				//   return;
				// }
				return;
			}
			this.talkIndex += 1;
			let currentTalk = this.pageConfig?.talkList[this.talkIndex];
			if (currentTalk && typeof currentTalk.handler === "function") {
				currentTalk.handler(this);
			}
			if (currentTalk && currentTalk.isBreak && currentTalk.callback) {
				// 如果对话框需要暂停，比如展示魔法书加水晶动物时，执行节点的回调方法
				currentTalk.callback(this);
			}
		},
		audioPlayerEnded() {
			if (this?.pageConfig?.audioPlayer?.ended) {
				this.pageConfig.audioPlayer.ended(this);
			}
		},
		pageAudioEnded() {
			this.isTalking = false;
			if (this?.pageConfig?.audio?.ended) {
				this.pageConfig.audio.ended(this);
			}
		},
		timeupdate() {
			if (this?.pageConfig?.audioPlayer?.update) {
				this.pageConfig.audioPlayer.update(
					this,
					this.$refs.audioPlayer.currentTime
				);
			}
		},
		goNextPage(config) {
			if (this?.pageConfig?.clickNextPageCallBack) {
				this.pageConfig.clickNextPageCallBack(this);
			}
			if (this.pageConfig.isEnd) {
				// 如果该页面是结束页面，开启遮罩
				this.isEnd = true;
			} else {
				this.$router.push(config.nextPageRoute);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.custom-audio {
	position: absolute;
	left: 50%;
	bottom: 50px;
	transform: translateX(-50%);
	z-index: 12;
}
</style>
