import { render, staticRenderFns } from "./page.vue?vue&type=template&id=97a3be68&scoped=true&"
import script from "./page.vue?vue&type=script&lang=js&"
export * from "./page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97a3be68",
  null
  
)

export default component.exports