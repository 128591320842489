<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson11/page?page=6')"
			class="next-btn"
		/>
		<div class="container">
			<div class="content">
				<img
					class="table"
					src="./images/table.png"
				/>
				<div class="group">
					<img
						class="chouyou"
						src="@/assets/image/role/chouyou.png"
					/>
					<img
						class="fart"
						src="./images/fart.png"
					/>
				</div>
			</div>
			<!-- <div class="group">
				<img
					class="chouyou"
					src="@/assets/image/role/chouyou.png"
				/>
				<img
					class="fart"
					src="./images/fart.png"
				/>
			</div> -->
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	mounted() {},
	methods: {},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson11/images/bg_blur.png");
}
.container {
	position: fixed;
	inset: 0;
	background-color: rgba($color: #000000, $alpha: 0.5);
	display: flex;
	align-items: end;
	justify-content: center;
	.content {
		.table {
			width: 1484px;
		}
	}
	.group {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 150px;
		left: 700px;
		animation: showchouyou 5s;
	}
	.chouyou {
		width: 822px;
		position: relative;
		z-index: 2;
	}
	.fart {
		position: absolute;
		width: 1300px;
		opacity: 0.5;
		bottom: 0;
		z-index: 0;
	}
}
@keyframes showchouyou {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
</style>
