<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson15/page?page=2')"
			class="next-btn"
		/>
		<div class="container">
			<div class="warp">
				<img
					class="flower"
					src="./images/flower_bg@2x.png"
				/>
				<img
					class="leaf"
					src="./images/leaf@2x.png"
				/>
				<img
					class="music"
					src="./images/music@2x.png"
				/>
			</div>
		</div>
		<div class="custom-audio">
			<audio
				ref="audioPlayer"
				src="./audio/古风C调.mp3"
				controls
			></audio>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: [false, false, false],
		};
	},
	methods: {
		toggle(index) {
			this.$set(this.list, index, !this.list[index]);
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson15/images/bg_blur.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.warp {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		.flower {
			height: 90vh;
		}
		.leaf {
			position: absolute;
			height: 80vh;
			margin-top: 100px;
		}
		.music {
			position: absolute;
			height: 40vh;
			margin-top: -60px;
		}
	}
}
.custom-audio {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}
</style>
