<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson15/game-2')"
			class="next-btn"
		/>
		<div class="container">
			<div class="warp">
				<img
					class="flower"
					src="./images/flower_bg@2x.png"
				/>
				<div class="content">
					<div class="item">
						<img
							v-if="list[0]"
							class="note"
							src="./images/note_16.png"
							@click="toggle(0)"
						/>
						<span
							v-else
							@click="toggle(0)"
							>乱花青草</span
						>
						<span>眼迷离，</span>
					</div>
					<div class="item">
						<img
							v-if="list[1]"
							class="note"
							src="./images/note_16.png"
							@click="toggle(1)"
						/>
						<span
							v-else
							@click="toggle(1)"
							>春泥浅踏</span
						>
						<span>脚步急。</span>
					</div>
					<div class="item">微风暖意人欲醉，</div>
					<div class="item">
						<img
							v-if="list[2]"
							class="note"
							src="./images/note_16.png"
							@click="toggle(2)"
						/>
						<span
							v-else
							@click="toggle(2)"
							>转角衣裙</span
						>
						<span>沾花蜜。</span>
					</div>
				</div>
			</div>
		</div>
		<div class="custom-audio">
			<audio
				ref="audioPlayer"
				src="./audio/古风C调.mp3"
				controls
			></audio>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: [false, false, false],
		};
	},
	methods: {
		toggle(index) {
			this.$set(this.list, index, !this.list[index]);
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson15/images/bg_blur.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.warp {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		.flower {
			height: 90vh;
		}
		.content {
			position: absolute;
			font-size: 80px;
			font-weight: 800;
			color: #ffffff;
			line-height: 140px;
			letter-spacing: 4px;
			margin-left: 60px;
			cursor: pointer;
			.item {
				height: 140px;
				.note {
					height: 9.18vw;
					margin-bottom: -80px;
				}
			}
		}
	}
}
.custom-audio {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}
</style>
