<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson12/game-9')"
			class="next-btn"
		/>
		<div class="container">
			<div class="imgs">
				<div
					v-for="(item, index) in list"
					:key="index"
					:class="{ active: index === current }"
				>
					<img
						:src="item.img"
						@click="touchHand(index)"
					/>
					<img
						class="select"
						src="./images/hands/highlight@2x.png"
					/>
				</div>
			</div>
		</div>
		<div class="custom-audio">
			<audio
				ref="audioPlayer"
				controls
				autoplay
				src="./audio/L12_4_56365.mp3"
			></audio>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			current: null,
			list: [
				{
					img: require("./images/hands/柯尔文手势_Sol@2x.png"),
				},
				{
					img: require("./images/hands/柯尔文手势_La@2x.png"),
				},
				{
					img: require("./images/hands/柯尔文手势_Mi@2x.png"),
				},
				{
					img: require("./images/hands/柯尔文手势_La@2x.png"),
				},

				{
					img: require("./images/hands/柯尔文手势_Sol@2x.png"),
				},
			],
		};
	},
	methods: {
		touchHand(index) {
			if (index === -99) {
				this.current = this.current ? null : index;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson12/images/bg_blur.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.imgs {
		display: flex;
		align-items: center;
		position: relative;
		.select {
			display: none;
		}
		img {
			width: 245px;
			cursor: pointer;
		}
		div {
			position: relative;
			height: 300px;
			width: 300px;
			display: flex;
			align-items: center;
			justify-content: center;
			&:nth-child(2),
			&:nth-child(4) {
				bottom: 100px;
			}
			&:nth-child(3) {
				bottom: -100px;
			}
			&.active {
				.select {
					display: block;
					width: 100vw;
					position: absolute;
					z-index: -1;
					top: -107%;
					left: -476%;
				}
			}
		}
	}
}
.custom-audio {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}
</style>
