import { render, staticRenderFns } from "./other-element.vue?vue&type=template&id=5ed5cfe3&scoped=true&"
import script from "./other-element.vue?vue&type=script&lang=js&"
export * from "./other-element.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed5cfe3",
  null
  
)

export default component.exports