<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson21/game-3')"
			class="next-btn"
		/>
		<img
			v-for="(item, index) in list"
			:key="index"
			class="item"
			:class="{ rotate: item.rotate }"
			:style="item.style"
			src="./images/wand.png"
			@click="item.rotate = !item.rotate"
		/>
		<div class="custom-audio">
			<audio
				ref="audioPlayer"
				controls
				autoplay
				src="./audio/聖桑-動物狂歡節：水族館.mp3"
			></audio>
		</div>
	</div>
</template>

<script>
class CreateWand {
	constructor(left = 0, top = 0) {
		this.rotate = Math.random() < 0.5;
		this.style = {
			left: left + "vw",
			top: top + "vh",
		};
	}
}
export default {
	data() {
		return {
			list: [
				new CreateWand(5, 33.8),
				new CreateWand(14, 34),
				new CreateWand(23, 30),
				new CreateWand(32.2, 39),
				new CreateWand(41.2, 31),
				new CreateWand(50.3, 34.5),
				new CreateWand(59.4, 37.5),
				new CreateWand(68.5, 32.4),
				new CreateWand(77.6, 28.8),
				new CreateWand(85.7, 38.3),
			],
		};
	},
	methods: {},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson21/images/wxp_2.png");
	.item {
		position: fixed;
		transition: transform 0.3s;
		height: 40vh;
		width: 4.5vw;
		&.rotate {
			transform: rotate(180deg);
		}
	}
	.custom-audio {
		position: absolute;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
	}
}
</style>
