const pageBg = require("@/views/lesson12/images/bg.png");
// const startPageBg = require("@/views/lesson12/images/bg_blur.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale) => [
	{
		roleClass: "musician",
		imgSrc: require("@/assets/image/role/musician.png"),
		style: {
			width: "auto",
			left: "50vw",
			bottom: "10vh",
			zIndex: 8,
		},
		roleStyle: {
			height: `${52.778 * scale}vh`,
			transform: "rotateY(180deg)",
		},
	},
	{
		roleClass: "magician",
		imgSrc: require("@/assets/image/role/magician.png"),
		style: {
			width: "auto",
			left: "27vw",
			bottom: "18vh",
			zIndex: 7,
		},
		roleStyle: {
			height: `${35 * scale}vh`,
		},
	},
	{
		roleClass: "knight",
		imgSrc: require("@/assets/image/role/knight.png"),
		style: {
			width: "auto",
			left: "20.5vw",
			bottom: "1vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${60 * scale}vh`,
			transform: "rotateY(180deg)",
		},
	},
	{
		roleClass: "goose",
		imgSrc: require("@/assets/image/role/goose.png"),
		style: {
			width: "auto",
			left: "70vw",
			bottom: "2vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${35 * scale}vh`,
		},
	},
];

const roles_two = (scale) => [
	{
		roleClass: "musician",
		imgSrc: require("@/assets/image/role/musician.png"),
		style: {
			width: "auto",
			left: "34vw",
			bottom: "15vh",
			zIndex: 8,
		},
		roleStyle: {
			height: `${52.778 * scale}vh`,
			transform: "rotateY(180deg)",
		},
	},
	{
		roleClass: "magician",
		imgSrc: require("@/assets/image/role/magician.png"),
		style: {
			width: "auto",
			left: "16vw",
			bottom: "24vh",
			zIndex: 7,
		},
		roleStyle: {
			height: `${35 * scale}vh`,
		},
	},
	{
		roleClass: "knight",
		imgSrc: require("@/assets/image/role/knight.png"),
		style: {
			width: "auto",
			left: "10vw",
			bottom: "6vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${60 * scale}vh`,
			transform: "rotateY(180deg)",
		},
	},
	{
		roleClass: "goose",
		imgSrc: require("@/assets/image/role/goose.png"),
		style: {
			width: "auto",
			left: "40vw",
			bottom: "12vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${35 * scale}vh`,
			transform: "scaleX(-1)",
		},
	},
	{
		roleClass: "panda",
		imgSrc: require("@/assets/image/role/panda.png"),
		style: {
			width: "auto",
			left: "66vw",
			bottom: "12vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${40 * scale}vh`,
		},
	},
];

export default {
	lessonStart: {
		// 首页
		pageBg, // 首页背景
		pageTitleImage: require("@/views/lesson12/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson12/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson12/page?page=2",
		roles: roles(1.1),
		audio: {
			src: require("../audio/独白1.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "快看，臭鼬在前面。我们终于找到他了",
				audio: require("../audio/宫廷1.mp3"),
			},
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "咦，这只熊猫怎么和臭鼬打了起来。",
				audio: require("../audio/鹅1.mp3"),
			},
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson12/game-1",
		talkList: [
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "你这个小偷，我存储的竹子都被你偷走了，今天你一定跑不了。",
				audio: require("../audio/熊猫01.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "熊猫大师，我们也是来追臭鼬的。他破坏了火车的轮子。",
				audio: require("../audio/宫廷2.mp3"),
			},
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "你们先不要插手，我要和他比试一下，拿回我丢的竹子。",
				audio: require("../audio/熊猫02.mp3"),
			},
			{
				audio: require("../audio/独白2.mp3"),
			},
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "就凭你的本事，也就干一些偷鸡摸狗的勾当。你是打不过我的。",
				audio: require("../audio/熊猫03.mp3"),
			},
			{
				talker: "chouyou",
				talkerCHN: "臭鼬",
				content: "嘿嘿，我虽然功夫没你好，但是我逃跑的本事还是有的。",
				audio: require("../audio/臭1.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/音乐课_13_熊猫下_04.gif"),
				style: {
					position: "absolute",
					width: "86vw",
					bottom: "0",
					left: "7vw",
				},
			},
		],
	},
	3: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson12/page?page=4",
		roles: roles(1.1),
		talkList: [
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "天啊，这简直就是毒气弹，大家快捂好口鼻。",
				audio: require("../audio/鹅2.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "不好，熊猫好像晕过去了。。。我们快去看看",
				audio: require("../audio/宫廷3.mp3"),
			},
		],
	},
	4: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson12/game-2",
		roles: roles_two(1.1),
		audio: {
			src: require("../audio/独白4.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "太卑鄙了，居然用这样的手段暗算我，我要报仇！",
				audio: require("../audio/熊猫04.mp3"),
			},
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "那我们快走，也许还能追上他。",
				audio: require("../audio/鹅3.mp3"),
			},
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content:
					"不行了，不行了，他把我的竹子都偷走了。我现在可以没有力气去追他。",
				audio: require("../audio/熊猫05.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"要不然，你先休息一会儿。我们去帮你砍一些竹子来，等你吃饱了，我们再一起去找臭鼬算账。",
				audio: require("../audio/宫廷4.mp3"),
			},
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "那就有劳大家了。",
				audio: require("../audio/熊猫06.mp3"),
			},
			{
				audio: require("../audio/独白5.mp3"),
			},
		],
	},
	5: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson12/game-3",
		roles: roles_two(1.1),
		talkList: [
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "熊猫可真是太能吃了，要砍这么多的竹子，快累死我了。",
				audio: require("../audio/鹅4.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "我们一边唱歌一边砍竹子吧，这样我们还可以快一点。",
				audio: require("../audio/宫廷5.mp3"),
			},
		],
	},
	6: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson12/game-4",
		roles: roles_two(1.1),
		audio: {
			src: require("../audio/独白6.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content:
					"终于吃饱了，不过这个臭鼬还是挺难对付的。这样吧，我教给你们一套拳法。这样我们更有胜算。",
				audio: require("../audio/熊猫07.mp3"),
			},
			{
				audio: require("../audio/独白7.mp3"),
			},
		],
	},
	7: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson13/page",
		roles: roles_two(1.1),
		isEnd: true,
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "谢谢熊猫大师，我们已经学会了。我们抓紧时间去追臭鼬吧。",
				audio: require("../audio/宫廷6.mp3"),
			},
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content:
					"哈哈哈，不着急。看到地上的脚印了吗？我猜他一定是躲到附近的山洞中了。刚才吃的太饱，我们先睡上一觉再去追他也不迟。",
				audio: require("../audio/熊猫08.mp3"),
			},
		],
	},
};
