<template>
  <div class="common-page">
    <img src="@/assets/image/next.png" @click.stop="gonext()" class="next-btn"  >
    <div class="imgtalk" >
      <img src="@/assets/image/note1.png" class="img2"  >
      <div class="imgmargin">
        <img src="@/assets/image/note2.png" class="img3"   >
        <img src="@/assets/image/note3.png" class="img4"  >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  mounted() {


  },
  methods: {
    gonext() {
      this.$router.push("/lesson2/page-3");

    },
  }
};
</script>

<style scoped lang="scss">
.imgmargin{
  margin-top: 60px;
}
.img4 {
  width: 80px;height: 200px;
}
.img3 {
  width: 80px;height: 200px;margin-right: 100px;
}
.img2 {
  width: 300px;height: 77px;margin-top: 50px;
}
.img1 {
  width: 148px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.common-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
}

.imgtalk {
  background-image: url('~@/assets/image/back03.png');
  width: 1000px;
  height: 520px;
  background-size: 100%;
  background-repeat: no-repeat;
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  box-sizing: border-box;
}
</style>
