<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson21/page?page=4-2')"
			class="next-btn"
		/>
		<img
			v-for="(item, index) in list"
			:key="index"
			class="item"
			:class="{ rotate: item.rotate }"
			:style="item.style"
			src="./images/wand.png"
			@click="item.rotate = !item.rotate"
		/>
		<div class="custom-audio">
			<audio
				ref="audioPlayer"
				controls
				autoplay
				src="./audio/聖桑-動物狂歡節：水族館.mp3"
			></audio>
		</div>
	</div>
</template>

<script>
class CreateWand {
	constructor(left = 0, top = 0) {
		this.rotate = Math.random() < 0.5;
		this.style = {
			left: left + "vw",
			top: top + "vh",
		};
	}
}
export default {
	data() {
		return {
			list: [
				// 上
				new CreateWand(10, 16.8),
				new CreateWand(22.1, 4.5),
				new CreateWand(32.6, 9),
				new CreateWand(43, 3.75),
				new CreateWand(55, -1.4),
				new CreateWand(65.4, 12),
				new CreateWand(74.9, 5.5),
				new CreateWand(86, 12.4),
				// 下
				new CreateWand(13.5, 45.2),
				new CreateWand(25.2, 39.5),
				new CreateWand(37.78, 44.2),
				new CreateWand(49.55, 45),
				new CreateWand(56.4, 54),
				new CreateWand(68.1, 59),
				new CreateWand(77.8, 50),
			],
		};
	},
	methods: {},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson21/images/wxp_3.png");
	.item {
		position: fixed;
		transition: transform 0.3s;
		height: 40vh;
		width: 4.5vw;
		&.rotate {
			transform: rotate(180deg);
		}
	}
	.custom-audio {
		position: absolute;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
	}
}
</style>
