<template>
	<img
		v-if="element.type == 'img'"
		:src="element.src"
		:style="element.style"
		@click="handler"
	/>
	<div
		v-else-if="element.type == 'text-lines'"
		:style="element.style"
	>
		<div
			v-for="(line, tIndex) of element.textLines"
			:key="tIndex"
			:style="line.style"
		>
			{{ line.text }}
		</div>
	</div>
	<div
		v-else-if="element.type == 'html'"
		:style="element.style"
		v-html="element.html"
	></div>
</template>

<script>
export default {
	name: "",
	components: {},
	mixins: [],
	props: {
		element: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {};
	},
	computed: {},
	watch: {},
	filter: {},
	created() {},
	mounted() {},
	methods: {
		handler(event) {
			if (typeof this.element.click === "function") {
				this.element.click(this);
				event.stopPropagation();
			}
		},
	},
};
</script>
<style lang="scss" scoped></style>
