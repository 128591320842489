<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson12/page?page=7')"
			class="next-btn"
		/>
		<div class="container">
			<div
				v-for="(item, index) in list"
				:key="index"
				class="item"
			>
				<img
					class="bg"
					src="./images/hand_bg@2x.png"
				/>
				<div class="left">
					<img
						class="hand"
						v-for="(hand, handCur) in item.hands"
						:src="hand"
						:key="handCur"
					/>
				</div>
				<div class="right">
					<img src="./images/播放器背景_small@2x.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import noteDo from "./images/hands/柯尔文手势_Do@2x.png";
import noteLa from "./images/hands/柯尔文手势_La@2x.png";
import noteMi from "./images/hands/柯尔文手势_Mi@2x.png";
import noteSol from "./images/hands/柯尔文手势_Sol@2x.png";
export default {
	data() {
		return {
			// TODO: 音频
			list: [
				{
					hands: [noteSol, noteMi, noteSol, noteLa, noteDo],
					addio: "",
				},
				{
					hands: [noteMi, noteDo, noteSol, noteLa, noteSol],
					addio: "",
				},
				{
					hands: [noteSol, noteLa, noteMi, noteLa, noteSol],
					addio: "",
				},
				{
					hands: [noteDo, noteMi, noteSol, noteMi, noteDo],
					addio: "",
				},
			],
		};
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson12/images/bg_blur.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: end;
	bottom: 4vh;
	.item {
		font-size: 0;
		width: 1400px;
		position: relative;
		.bg {
			width: 100%;
		}
		.left {
			position: absolute;
			left: 26px;
			top: 16px;
			bottom: 34px;
			right: 250px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			.hand {
				width: 170px;
			}
		}
		.right {
			position: absolute;
			left: 1160px;
			top: 16px;
			bottom: 34px;
			right: 26px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 80%;
				margin-top: 20px;
				cursor: pointer;
			}
		}
	}
}
</style>
