<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson14/page?page=3')"
			class="next-btn"
		/>
		<div class="container">
			<img
				v-for="(item, index) in list"
				:key="index"
				class="item"
				:src="item.disable === true ? item.fill : item.note"
				:class="`note-${item.type}`"
				:style="item.style"
				@click="toggle(index)"
			/>
		</div>
		<audio
			ref="audioPlayer"
			:src="audioSrc"
			style="display: none"
		></audio>
	</div>
</template>

<script>
class CreateEgg {
	constructor(type = 1, x = 0, y = 0) {
		const disable = [1, 3, 5, 6].includes(type);
		this.img = require("./images/bud.png");
		this.note = disable
			? require(`./images/egg/note_${type}_success.png`)
			: require(`./images/egg/note_${type}.png`);
		this.fill = disable
			? require(`./images/egg/note_${type}_success_fill.png`)
			: "";
		this.disable = disable ? false : "none";
		function random(min = 0.85, max = 1) {
			return Math.random() * (max - min) + min;
		}
		this.style = {
			bottom: `${y}vh`,
			left: `${x}vw`,
			transform: `scale(${random()})`,
			zIndex: type,
		};
	}
}
export default {
	data() {
		return {
			audioSrc: require("./audio/泡泡声音.mp3"),
			list: [
				new CreateEgg(1, 30, 43),
				new CreateEgg(1, 80, 8),
				new CreateEgg(3, 33, 14),
				new CreateEgg(3, 60, 33),
				new CreateEgg(5, 20, 58),
				new CreateEgg(5, 45, 40),
				new CreateEgg(6, 55, 56),
				new CreateEgg(6, 73, 31),
				new CreateEgg(4, 6, 10),
				new CreateEgg(7, 7, 50),
				new CreateEgg(8, 16, 29),
				new CreateEgg(2, 18, 0),
				new CreateEgg(4, 48, 8),
				new CreateEgg(7, 69, 55),
				new CreateEgg(8, 83, 45),
			],
		};
	},
	methods: {
		playMusic() {
			this.$nextTick(() => {
				this.$refs.audioPlayer.play();
			});
		},
		toggle(index) {
			const result = this.list[index];
			if (result.disable === "none") {
				return;
			}
			result.disable = !result.disable;
			if (result.disable) {
				this.playMusic();
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson14/images/bg.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.item {
		position: absolute;
		width: 300px;
		cursor: pointer;
	}
}
</style>
