<template>
	<div
		class="common-page"
		@mousemove="move"
		@mouseup="end"
	>
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson24/page?page=9')"
			class="next-btn"
		/>

		<div class="wrap">
			<img
				class="staff"
				src="./images/game/staff_44.png"
			/>
			<div class="input_wrap">
				<input
					type="text"
					placeholder="输入歌词"
				/>
			</div>
		</div>
		<div class="wrap">
			<img
				class="staff"
				src="./images/game/staff.png"
			/>
			<div class="input_wrap">
				<input
					type="text"
					placeholder="输入歌词"
				/>
			</div>
		</div>
		<div class="wrap wrap-notes">
			<img
				class="staff"
				src="./images/game/staff.png"
			/>
			<div class="notes-box">
				<img src="./images/game/节奏型@2x.png" />
				<img
					class="note sifen"
					src="./images/game/四分音符@2x.png"
					@mousedown="start"
				/>
				<img
					class="note bafen"
					src="./images/game/八分音符@2x.png"
					@mousedown="start"
				/>
				<img
					class="note shiliubafen"
					src="./images/game/前十六后八音符@2x.png"
					@mousedown="start"
				/>
				<img
					class="note shiliufen"
					src="./images/game/十六分音符@2x.png"
					@mousedown="start"
				/>
				<img
					class="note erfen"
					src="./images/game/二分音符@2x.png"
					@mousedown="start"
				/>
				<img
					class="note allfen"
					src="./images/game/全音符@2x.png"
					@mousedown="start"
				/>
				<img
					class="note sifenxiuzhi"
					src="./images/game/四份休止符@2x.png"
					@mousedown="start"
				/>
			</div>
		</div>
		<div class="wrap">
			<img
				class="staff"
				src="./images/game/staff_underline.png"
			/>
			<div class="box-3">
				<div
					class="item"
					v-for="(item, index) in note3"
					:key="index"
					:style="item.style"
					@click="handNode(item)"
				>
					<img
						class="item-note"
						:src="item.note"
					/>
					<img
						class="item-letter"
						:src="item.letter"
					/>
				</div>
			</div>
		</div>
		<audio
			ref="audioPlayer"
			style="display: none"
			:src="audioSrc"
		></audio>
	</div>
</template>

<script>
class CreateNode {
	constructor(letter, index) {
		this.note = require(`./images/game/note_${letter}.png`);
		this.letter = require(`./images/game/letter_${letter}.png`);
		this.audio = require(`./audio/music/${letter}.mp3`);
		this.style = {
			marginTop: (7 - index) * 1.2 + "vh",
		};
	}
}
export default {
	data() {
		return {
			note2: [],
			note3: [],
			audioSrc: "",
			isMove: false,
			node: null,
			offset: {},
		};
	},
	created() {
		const list = ["c", "d", "e", "f", "g", "a", "b", "c1"];
		list.forEach((item, index) => this.note3.push(new CreateNode(item, index)));
	},
	methods: {
		handNode(item) {
			this.audioSrc = item.audio;
			this.$nextTick(() => {
				this.$refs.audioPlayer.play();
			});
		},
		start(e) {
			this.isMove = true;
			const { offsetX, offsetY, clientX, clientY } = e;
			this.offset = {
				x: offsetX,
				y: offsetY,
			};
			this.node = e.target.cloneNode();
			if (!this.node.getAttribute("x")) {
				const result = this.node.getBoundingClientRect();
				this.node.setAttribute("x", result.left);
				this.node.setAttribute("y", result.top);
			}
			this.node.classList.add(`clone`);
			this.node.onmousedown = (event) => {
				this.isMove = true;
				this.node = event.target;
				this.offset = {
					x: event.offsetX,
					y: event.offsetY,
				};
			};
			this.node.style.cssText = `left: ${clientX - offsetX}px;top: ${
				clientY - offsetY
			}px`;
			const father = document.querySelector(".common-page");
			father.appendChild(this.node);
		},
		move(e) {
			if (this.isMove) {
				const { clientX, clientY } = e;
				this.node.style.cssText = `left: ${clientX - this.offset.x}px;top: ${
					clientY - this.offset.y
				}px`;
			}
		},
		end() {
			this.isMove = false;
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson24/images/index_bg.png");
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.note {
		cursor: pointer;
		&.clone {
			position: absolute;
		}
	}
	.sifen {
		height: 10vh;
	}
	.bafen {
		height: 10vh;
	}
	.shiliubafen {
		height: 10vh;
	}
	.shiliufen {
		height: 10vh;
	}
	.erfen {
		height: 10vh;
	}
	.allfen {
		height: 4vh;
	}
	.sifenxiuzhi {
		height: 6vh;
	}
	.wrap {
		position: relative;
		.staff {
			height: 20vh;
			width: 65vw;
		}
		.input_wrap {
			margin-left: 8vw;
			height: 60px;
			background: rgba(255, 255, 255, 0);
			border: 3px solid #53859d;
			border-radius: 12px;
			display: flex;
			input {
				width: 100%;
				background-color: transparent;
				border-color: transparent;
				outline: none;
				padding: 0 15px;
				color: #fff;
				font-size: 32px;
				&::placeholder {
					font-size: 32px;
					color: #6d95a8;
				}
			}
		}
		&-notes {
			height: 15vh;
			.staff {
				opacity: 0;
			}
			.notes-box {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		.box-3 {
			width: 53.4vw;
			position: absolute;
			top: 8.2vh;
			bottom: 2vh;
			left: 6.6vw;
			right: 4.8vw;
			display: flex;
			justify-content: space-between;
			.item {
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				&-note {
					height: 3.5vh;
				}
				&-letter {
					height: 4.2vh;
					object-fit: contain;
				}
			}
		}
	}
}
</style>
