<template>
  <div class="common-page"  @click="changetalk(1)">
    <Magic v-if="openmagic"></Magic>
    <audio
      ref="audioTip"
      autoplay
      :src="audio.src"
      style="display: none"
      @ended="isTalking = false"
    ></audio>
    <div class="musician-box">
      <img src="@/assets/image/role/musician.png" class="musician" />
    </div>

    <div class="magician-box">
      <img src="@/assets/image/role/magician.png" class="magician" />
    </div>

    <div class="knight-box">
      <img src="@/assets/image/role/knight.png" class="knight" />
    </div>
    <div class="shimengmo-box">
      <img src="@/assets/image/role/shimengmo.png" class="shimengmo" />
    </div>
    <ChatDialog
      :talkList="talkList"
      :talkIndex="talkIndex"
      @audioStateChange="(state) => (isTalking = state)"
    ></ChatDialog>
  </div>
</template>

<script>
const Magic = () =>
  import(/* webpackChunkName:"magic-animation" */ "@/components/Magic/index.vue");
export default {
  data() {
    return {
      talkList: [
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content: "你是谁？鼻子这么长，看着好奇怪呀。",
          audio: require("@/assets/audio/lesson3/page4-musician-voice1.mp3"),
        },
        {
          talker: "shimengmo",
          talkerCHN: "食梦貘",
          content:
            "我是食梦貘呀。你们之所以能见到我，是因为我已经好久没有睡觉了。最近莫名其妙的突然失眠。我只能在森林中四处溜达。",
          audio: require("@/assets/audio/lesson3/page4-shimengmo-voice1.mp3"),
        },
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content:
            "哇，你就是传说中。可以吃掉噩梦的食梦貘吗？怪不得我们都做了噩梦。我听说过一个关于你的传说。",
          audio: require("@/assets/audio/lesson3/page4-musician-voice2.mp3"),
        },
      ],
      talkIndex: -1,
      isTalking: true,
      openmagic: false,
      audio: {
        // 消息通知
        src: require("@/assets/audio/lesson3/page4-aside-voice1.mp3"),
      },
    };
  },
  components: {
    Magic,
  },
  mounted() {
    this.$refs.audioTip.play();
  },
  methods: {
    changetalk() {
      if (this.isTalking) {
        // 正在读旁白或者正在播放对话内容
        return;
      }
      if (this.talkIndex == 2) {
        this.talkIndex += 1;
        let src = require("@/assets/video/magic.wav");
        this.$set(this.audio, "src", src);
        this.openmagic = true;
        this.$refs.audioTip.play();
        this.isTalking = true;
        setTimeout(() => {
          this.openmagic = false;
          this.$router.push("/lesson3/game-4");
        }, 3000);
      } else {
        this.talkIndex += 1;
        this.isTalking = true;
      }
    },
  },
};
</script>

<style scoped>
.common-page {
  background-image: url("~@/assets/image/back04.png");
}
.magician-box {
  left: 620px;
  bottom: 260px;
  z-index: 7;
}
.knight-box {
  left: 900px;
  bottom: 120px;
  z-index: 8;
}
.musician-box {
  left: 220px;
  bottom: 140px;
  z-index: 9;
}
.shimengmo-box {
  left: 560px;
  bottom: 120px;
}
</style>