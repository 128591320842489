<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson12/page?page=5')"
			class="next-btn"
		/>
		<div class="container">
			<div
				class="bamboo"
				:class="{ movebamboo: isCleaveing }"
			>
				<div
					class="fly"
					:class="{ move: isCleaveing }"
				>
					<img
						class="bamboo-fly"
						src="./images/bamboo_fly.png"
					/>
					<img
						class="stars"
						src="./images/stars.png"
					/>
				</div>
			</div>
			<div
				class="sword"
				:class="{ move: isCleaveing }"
				@click="cleave"
			>
				<div class="star">
					<img src="./images/star.png" />
				</div>
			</div>
		</div>
		<audio
			ref="audioPlayer"
			src="./audio/砍.mp3"
			style="display: none"
		></audio>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isCleaveing: false,
		};
	},
	methods: {
		cleave() {
			if (!this.isCleaveing) {
				this.isCleaveing = true;
				this.$refs.audioPlayer.play();
				setTimeout(() => {
					this.isCleaveing = false;
				}, 100);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson12/images/bg_blur.png");
}
.container {
	--duration: 0.1s;
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.bamboo {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -527px;
		width: 1054px;
		height: 824.8px;
		background: url("./images/bamboo.png") no-repeat;
		background-size: cover;
		background-position: center bottom;
		&.movebamboo {
			animation: movebamboo var(--duration);
		}
		.fly {
			transform: scale(0.8);
			position: absolute;
			left: 150px;
			top: -100px;
			opacity: 0;
			.stars {
				position: absolute;
				left: 41%;
				top: 44%;
			}
			&.move {
				animation: movefly var(--duration) cubic-bezier(0.45, -0.35, 0.14, 1.36);
			}
		}
	}
	.sword {
		position: absolute;
		right: 200px;
		bottom: 60px;
		width: 120px;
		height: 553.23px;
		background: url("./images/宝剑.png") no-repeat;
		background-size: cover;
		transform-origin: -10vw 60vw;
		cursor: pointer;
		.star {
			img,
			&::before,
			&::after {
				position: absolute;
				top: -50px;
				content: "";
				width: 23.9px;
				height: 30.4px;
				background: url("./images/star.png");
				background-repeat: no-repeat;
				background-size: cover;
			}
			&::after {
				top: 30px;
				right: -30px;
			}
			img {
				top: 130px;
				left: -50px;
			}
		}
		&.move {
			animation: movesword var(--duration) cubic-bezier(0.45, -0.35, 0.14, 1.36);
			&:before {
				content: "";
				width: 697px;
				height: 595px;
				position: absolute;
				top: 0;
				left: -50%;
				transform: translateX(-30%) rotateZ(30deg);
				background: url("./images/刀光剑影.png") no-repeat;
				background-size: cover;
			}
			.star {
				display: none;
			}
		}
	}
}
@keyframes movesword {
	0% {
		transform: rotateZ(0);
	}
	100% {
		transform: rotateZ(-90deg);
	}
}
@keyframes movefly {
	0% {
		opacity: 0;
		transform: translateX(0) translateY(0) scale(0.8);
	}
	34% {
		opacity: 0;
		transform: translateX(0) translateY(0) scale(0.8);
	}
	35% {
		opacity: 1;
		transform: translateX(0) translateY(0) scale(0.8);
	}
	100% {
		opacity: 1;
		transform: translateX(-15vw) translateY(100vh) scale(0.6);
	}
}
@keyframes movebamboo {
	0% {
		transform: translateY(0);
	}
	34% {
		transform: translateY(0);
	}
	35% {
		transform: translateY(200px);
	}
	60% {
		transform: translateY(200px);
	}
	100% {
		transform: translateY(0);
	}
}
</style>
