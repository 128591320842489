<template>
	<div
		class="common-page"
		@click="exchange"
	>
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson11/game-2')"
			class="next-btn"
		/>
		<Magic v-if="openmagic"></Magic>
		<div class="container">
			<img src="./images/paper.png" />
			<img
				v-if="!change"
				class="empty-note"
				src="./images/empty_note.png"
			/>
			<img
				v-else
				class="empty-note"
				src="./images/has_note.png"
			/>
		</div>
	</div>
</template>

<script>
const Magic = () =>
	import(
		/* webpackChunkName:"magic-animation" */ "@/components/Magic/index.vue"
	);
export default {
	components: {
		Magic,
	},
	data() {
		return {
			change: false,
			openmagic: false,
		};
	},
	methods: {
		exchange() {
			if (this.change) return;
			this.openmagic = true;
			setTimeout(() => {
				this.openmagic = false;
				this.change = true;
			}, 3000);
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson11/images/bg_blur.png");
}
.container {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	bottom: 2vh;
	img {
		width: 50vw;
	}
	.empty-note {
		position: absolute;
		width: 26vw;
		left: 50%;
		top: 30%;
		transform: translateX(-50%);
	}
}
</style>
