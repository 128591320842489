<template>
  <div class="common-game-page" >
    <audio ref="audioTip" style="display: none"></audio>
    <img class="game-title" src="@/assets/image/playtime-3.png" />
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
      
    />
    <div class="imgtalk" >
      <img src="@/assets/image/cloud5.png" class="img2" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audio: {
        // 消息通知
        src: require("@/assets/music/xiao-tiao-zao-yuanchang.mp3"),
      },
    };
  },
  mounted() {
    // this.$refs.audioTip.play();
  },
  methods: {
    gonext() {
      this.$router.push("/lesson4/game-5");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/assets/scss/game-page.scss";
.common-game-page {
  background-image: url("~@/assets/image/back04.png");
}
.img2 {
  width: 600px;
  height: auto;
}

.imgtalk {
  position: absolute;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  background-image: url("~@/assets/image/back06.png");
  width: 1000px;
  height: 500px;
  background-size: 100%;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 38px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 80px; */
  box-sizing: border-box;
  justify-content: center;
}
</style>
