<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			class="next-btn"
			@click.stop="gonext()"
		/>
		<div class="custom-audio">
			<audio
				class="audio"
				ref="audioTip"
				:src="audio.src"
				controls
				autoplay
			>
				<!-- <source :src="audio.src" > -->
			</audio>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			audio: {
				// 消息通知
				src: require("@/assets/music/fengniao.mp3"),
			},
		};
	},
	mounted() {},
	methods: {
		gonext() {
			this.$router.push("/lesson1/page-5");
		},
	},
};
</script>

<style scoped>
.common-page {
	background-image: url("~@/assets/image/back01.png");
}
.custom-audio {
	position: absolute;
	margin: 0 auto;
	bottom: 100px;
}
.img1 {
	width: 148px;
	height: 50px;
	position: absolute;
	top: 20px;
	right: 20px;
}

.common-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
}
</style>
