<template>
  <div class="common-page"  @click="changetalk()">
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />
    <div style="display: flex; align-items: flex-end">
      <div class="musician-box" >
        <img src="@/assets/image/role/musician.png" class="musician" />
      </div>
      <div class="bird-box">
        <img src="@/assets/image/role/fengniao.png" class="bird" />
      </div>
    </div>
    <ChatDialog
      :talkList="talkList"
      :talkIndex="talkIndex"
      @audioStateChange="(state) => (isTalking = state)"
    ></ChatDialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      talkList: [
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content: "你们看，蜂鸟的歌声似乎化成了点点的幽光",
          audio: require("@/assets/audio/lesson2/page4-musician-voice1.mp3"),
        },
      ],
      talkIndex: -1,
      isTalking: true,
    };
  },
  mounted() {
    // this.$refs.audioTip.play();
  },
  methods: {
    gonext() {
      this.$router.push("/lesson2/game-3");
    },
    changetalk() {
      if (this.talkIndex < this.talkList.length - 1) {
        this.talkIndex += 1;
        if (this.talkIndex == 3) {
          this.$refs.audioTip.play();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.musician-box {
  left: 520px;
  bottom: 160px;
}
.bird-box {
  left: 760px;
  bottom: 480px;
}
</style>