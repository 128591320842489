<template>
	<CommonLessonPage :allPageConfig="allPageConfig" />
</template>

<script>
import CommonLessonPage from "@/components/CommonLessonPage/index.vue";
import allPageConfig from "./config/index.js";
export default {
	components: { CommonLessonPage },
	data() {
		return {
			allPageConfig,
		};
	},
};
</script>
<style lang="scss" scoped></style>
