<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson18/page?page=1-after')"
			class="next-btn"
		/>
		<div class="container">
			<!-- <div>
				<div class="jiepai">
					<span>4</span>
					<span class="line"></span>
					<span>4</span>
				</div>
			</div> -->
			<div>
				<div>《海怪暗礁岛》</div>
				<div>摇 摇 摇，</div>
				<div>摇到了暗礁。</div>
				<div>前方有只海怪，</div>
				<div>不要被抓到。</div>
			</div>
		</div>
		<div class="custom-audio">
			<audio
				ref="audioPlayer"
				controls
				autoplay
				src="./audio/海怪暗礁岛.mp3"
			></audio>
		</div>
	</div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson18/images/bg.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 65px;
	font-weight: bold;
	color: #986502;
	background: rgba($color: #000000, $alpha: 0.3)
		url("~@/views/lesson18/images/paper.png") no-repeat center center / 60vw
		auto;
	.jiepai {
		font-size: 34px;
		line-height: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		top: -90px;
		margin-right: 20px;
		.line {
			width: 30px;
			height: 4px;
			background-color: #986502;
		}
	}
}
.custom-audio {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}
</style>
