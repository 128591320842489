<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson10/page?page=7')"
			class="next-btn"
		/>
		<img
			class="olive-bg"
			src="./images/olive_bg.png"
			alt=""
		/>
		<div class="container">
			<div>
				石门谜语<span
					v-if="changes[0]"
					@click="exchange(0)"
					>难</span
				>
				<img
					v-else
					src="./images/circle.png"
					@click="exchange(0)"
				/>
				，
			</div>
			<div>
				深坑道路<span
					v-if="changes[1]"
					@click="exchange(1)"
					>艰</span
				>
				<img
					v-else
					src="./images/circle.png"
					@click="exchange(1)"
				/>。
			</div>
			<div>共闯神殿深处，</div>
			<div>
				携手付艰<span
					v-if="changes[2]"
					@click="exchange(2)"
					>险</span
				>
				<img
					v-else
					src="./images/circle.png"
					@click="exchange(2)"
				/>。
			</div>
			<div>黄金树，</div>
			<div>和平鸽，</div>
			<div>
				终发<span
					v-if="changes[3]"
					@click="exchange(3)"
					>现</span
				>
				<img
					v-else
					src="./images/circle.png"
					@click="exchange(3)"
				/>，
			</div>
			<div>出剑只为助他人，</div>
			<div>
				正义永不<span
					v-if="changes[4]"
					@click="exchange(4)"
					>变</span
				>
				<img
					v-else
					src="./images/circle.png"
					@click="exchange(4)"
				/>。
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			changes: [true, true, true, true, true],
		};
	},
	methods: {
		exchange(index) {
			this.$set(this.changes, index, !this.changes[index]);
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson10/images/bg_blur.png");
}
.olive-bg {
	position: fixed;
	width: 100vw;
	inset: 0;
}
.container {
	position: fixed;
	font-size: 60px;
	color: #dfffd4;
	font-weight: bold;
	line-height: 70px;
	left: 50%;
	transform: translateX(-50%);
	bottom: 6vh;
	div {
		display: flex;
		align-items: end;
	}
	img {
		width: 4vw;
	}
}
</style>
