<template>
	<div class="common-page">
		<img
			class="next-btn"
			src="@/assets/image/next.png"
			@click.stop="gonext()"
		/>
		<div class="btns-container">
			<div
				class="btn large-btn"
				@click="starts"
			>
				<img src="@/assets/image/ls.png" />
			</div>
			<div
				class="btn large-btn"
				@click="startm"
			>
				<img src="@/assets/image/lm.png" />
			</div>
			<div
				class="btn small-btn"
				@click="starts"
			>
				<img src="@/assets/image/s.png" />
			</div>
			<div
				class="btn small-btn"
				@click="starts"
			>
				<img src="@/assets/image/s.png" />
			</div>
			<div
				class="btn large-btn"
				@click="startm"
			>
				<img src="@/assets/image/lm.png" />
			</div>
		</div>
		<div class="custom-audio">
			<audio
				ref="audioBg"
				autoplay
				controls
				:src="audio.src"
			></audio>
		</div>
		<audio
			ref="btnClickAudio"
			autoplay
			:src="btnClickAudioSrc"
			style="display: none"
		></audio>
	</div>
</template>

<script>
export default {
	data() {
		return {
			audio: {
				// 消息通知
				src: require("@/assets/audio/lesson1/L1-53553-53553.mp3"),
			},
			btnClickAudioSrc: "",
		};
	},
	mounted() {},
	methods: {
		gonext() {
			this.$router.push("/lesson1/page-2");
		},
		starts() {
			this.btnClickAudioSrc = require("@/assets/music/s.mp3");
			this.$refs.audioBg.pause();
			this.$refs.btnClickAudio.load();
			this.$nextTick(() => {
				this.$refs.btnClickAudio.play();
			});
		},
		startm() {
			this.btnClickAudioSrc = require("@/assets/music/m.mp3");
			this.$refs.audioBg.pause();
			this.$refs.btnClickAudio.load();
			this.$nextTick(() => {
				this.$refs.btnClickAudio.play();
			});
		},
	},
};
</script>

<style scoped lang="scss">
.btns-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
}

.large-btn {
	width: 167px;
	height: 167px;
	img {
		width: 100%;
		height: 100%;
	}
}
.small-btn {
	width: 83px;
	height: 167px;

	img {
		width: 100%;
		height: 100%;
	}
}
.btn {
	cursor: pointer;
	& + .btn {
		margin-left: 45px;
	}
}
.custom-audio {
	position: absolute;
	left: 50%;
	bottom: 100px;
	transform: translateX(-50%);
}
</style>
