<template>
  <div class="common-page" @click="changetalk()">
    <Magic v-if="openmagic"></Magic>
    <audio
      ref="audioTip"
      :src="audio.src"
      autoplay
      style="display: none"
      @ended="isTalking = false"
    ></audio>
    <div class="magician-box">
      <img src="@/assets/image/role/magician.png" class="magician"  />
    </div>
    <div class="knight-box">
      <img src="@/assets/image/role/knight.png" class="knight"  />
    </div>
    <div class="musician-box">
      <img src="@/assets/image/role/musician.png" class="musician"  />
    </div>
    <ChatDialog
      :talkList="talkList"
      :talkIndex="talkIndex"
      @audioStateChange="(state) => (isTalking = state)"
    ></ChatDialog>
  </div>
</template>

<script>
const Magic = () =>
  import(/* webpackChunkName:"magic-animation" */ "@/components/Magic/index.vue");
export default {
  data() {
    return {
      talkList: [
        {
          talker: "knight",
          talkerCHN: "骑士",
          content:
            "我们已经走了十多天了。一路上风尘露宿，终于到达了幽光森林的边缘。这个森林看上去充满了魔法的力量。",
          audio: require("@/assets/audio/lesson1/page1-knight-voice1.mp3"),
        },
        {
          talker: "magician",
          talkerCHN: "魔法师",
          content: "是的，我的法杖似乎和这个森林的力量产生了某种共鸣。",
          audio: require("@/assets/audio/lesson1/page1-magician-voice1.mp3"),
        },
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content: "来吧，试试我新教你的魔法，看看我们能否找出有关神兽的线索。",
          audio: require("@/assets/audio/lesson1/page1-musician-voice1.mp3"),
        },
      ],
      talkIndex: -1,
      isTalking: true,
      openmagic: false,
      audio: {
        // 消息通知
        src: require("@/assets/audio/lesson1/page1-aside-voice.mp3"),
      },
    };
  },
  computed: {},
  components: {
    Magic,
  },
  mounted() {},
  methods: {
    changetalk() {
      if (this.talkIndex == 3) {
        this.talkIndex += 1;
        this.openmagic = true;
        setTimeout(() => {
          this.openmagic = false;
          this.$router.push("/lesson1/game-1");
        }, 3000);
      }
      if (this.isTalking) {
        // 正在读旁白或者正在播放对话内容
        return;
      }
      this.isTalking = true;
      this.talkIndex += 1;
    },
  },
};
</script>

<style scoped lang="scss">
.magician-box,
.knight-box,
.musician-box {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  width: 300px;
}
.magician-box {
  left: 660px;
  bottom: 140px;
}
.knight-box {
  left: 760px;
  bottom: 200px;
}
.musician-box {
  left: 960px;
  bottom: 120px;
}
</style>