const pageBg = require("@/views/lesson10/images/bg.png");
const startPageBg = require("@/views/lesson10/images/bg_blur.png");
const pageNextBtnImage = require("@/assets/image/next.png");
let isAppendPit = false;
const roles = (scale) => [
	{
		roleClass: "musician",
		imgSrc: require("@/assets/image/role/musician.png"),
		style: {
			width: "auto",
			left: "35vw",
			bottom: "21vh",
			zIndex: 8,
		},
		roleStyle: {
			height: `${52.778 * scale}vh`,
			transform: "rotateY(180deg)",
		},
	},
	{
		roleClass: "magician",
		imgSrc: require("@/assets/image/role/magician.png"),
		style: {
			width: "auto",
			left: "22vw",
			bottom: "26vh",
			zIndex: 7,
		},
		roleStyle: {
			height: `${35 * scale}vh`,
		},
	},
	{
		roleClass: "knight",
		imgSrc: require("@/assets/image/role/knight.png"),
		style: {
			width: "auto",
			left: "45vw",
			bottom: "23vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${50.092 * scale}vh`,
			transform: "rotateY(180deg)",
		},
	},
	{
		roleClass: "zuoluo",
		imgSrc: require("@/assets/image/role/zuoluo.png"),
		style: {
			width: "auto",
			left: "58vw",
			bottom: "22vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${50.092 * scale}vh`,
		},
	},
];
export default {
	lessonStart: {
		// 首页
		pageBg: startPageBg, // 首页背景
		pageTitleImage: require("@/views/lesson10/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson10/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg: startPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson10/page?page=2",
		roles: roles(0.92),
		audio: {
			src: require("../audio/旁白1.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "zuoluo",
				talkerCHN: "佐罗",
				content:
					"大家快看。前面的道路上，有这大大小小的坑。这应该是某种机关。我们需要按照一定的顺序和节奏，跨过这些坑才能顺利的到达对面。",
				audio: require("../audio/佐罗1.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"让我来仔细观察一下。我看这些坑是按照某种节奏排布的。大家一定要注意听音乐，小心不要掉进坑里哦～",
				audio: require("../audio/宫廷1.mp3"),
			},
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson10/page?page=3",
		roles: roles(0.92),
		clickNextPageCallBack() {
			removePit();
		},
		audioPlayer: {
			src: require("../audio/BreakMixer.mp3"),
			autoplay: true,
			controls: true,
			style: {
				bottom: "83vh",
				zIndex: 1,
			},
			update(vm, e) {
				if (
					show(e, 14, 15) ||
					show(e, 30, 32) ||
					show(e, 47, 48) ||
					show(e, 63, 64) ||
					show(e, 79, 80) ||
					show(e, 96, 97) ||
					show(e, 112, 113) ||
					show(e, 128, 129) ||
					show(e, 145, 146) ||
					show(e, 161, 162) ||
					show(e, 175, Infinity)
				) {
					showPit(vm);
				} else {
					removePit(vm);
				}
			},
		},
	},
	3: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson10/page?page=4",
		otherElement: [
			{
				type: "img",
				src: require("../images/L4_bg.png"),
				style: {
					position: "absolute",
					width: "90vw",
					bottom: "20vh",
					left: "5vw",
				},
			},
			{
				type: "img",
				src: require("../images/L4_note.png"),
				style: {
					position: "absolute",
					width: "15vw",
					bottom: "28vh",
					left: "50vw",
					transform: "translateX(-50%)",
				},
			},
		],
	},
	4: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson10/page?page=5",
		roles: roles(0.92),
		talkList: [
			{
				talker: "zuoluo",
				talkerCHN: "佐罗",
				content: "果然只有按照这种节奏才能够过去，幸亏遇见了你们。",
				audio: require("../audio/佐罗2.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "快看，这是什么东西，你这么金光灿灿。原来是一棵黄金树。",
				audio: require("../audio/宫廷5.mp3"),
				handler(vm) {
					console.log(vm);
					const img = document.createElement("img");
					img.src = require("../images/鸽子鸽子.png");
					img.classList.add("appendGezi");
					img.style.cssText =
						"position: absolute; bottom: 30vh; width: 20vw; right: 0;";
					vm.$el.appendChild(img);
				},
			},
			{
				talker: "zuoluo",
				talkerCHN: "佐罗",
				content: "黄金树上门还落着一只白鸽。他应该有我们要的答案。",
				audio: require("../audio/佐罗3.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "鸽子啊。你住在神殿的尽头。你一定知道有关生命音符的线索。",
				audio: require("../audio/宫廷6.mp3"),
			},
			{
				talker: "zuoluo",
				talkerCHN: "佐罗",
				content:
					"鸽子啊。那你应该也知道，这战无不胜的办法吧。我跋涉千里，就为了来到这里。",
				audio: require("../audio/佐罗4.mp3"),
			},
			{
				talker: "geza",
				talkerCHN: "鸽子",
				content:
					"你们破解了重重机关，来到这里，我一定会给你们答案。其实你们想寻找的是同一样东西。答案就藏在这个乐谱的后面，当你们唱完整篇乐曲后，你们寻找的东西就会自然显现出来。",
				audio: require("../audio/gz1.mp3"),
			},
			{
				isBreak: true,
				callback(vm) {
					vm.showMagic = true;
					setTimeout(() => {
						vm.showMagic = false;
						vm.$router.push("/lesson10/page?page=5");
						const img = document.querySelector(
							"div.common-page.common-lesson-page .appendGezi"
						);
						img && img.remove();
					}, 3000);
				},
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/pit.png"),
				style: {
					position: "absolute",
					width: "65vw",
					bottom: "10px",
					left: "17vw",
				},
			},
			// {
			// 	type: "img",
			// 	src: require("../images/鸽子鸽子.png"),
			// 	style: {
			// 		position: "absolute",
			// 		width: "20vw",
			// 		bottom: "30vh",
			// 		right: "0",
			// 	},
			// },
		],
	},
	// 4: {
	// 	pageBg,
	// 	pageNextBtnImage,
	// 	nextPageRoute: "/lesson10/page?page=5",
	// 	otherElement: [
	// 		{
	// 			type: "img",
	// 			src: require("../images/L4_bg.png"),
	// 			style: {
	// 				position: "absolute",
	// 				width: "90vw",
	// 				bottom: "20vh",
	// 				left: "5vw",
	// 			},
	// 		},
	// 		{
	// 			type: "img",
	// 			src: require("../images/L4_note.png"),
	// 			style: {
	// 				position: "absolute",
	// 				width: "15vw",
	// 				bottom: "28vh",
	// 				left: "50vw",
	// 				transform: "translateX(-50%)",
	// 			},
	// 		},
	// 	],
	// },
	5: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson10/page?page=6",
		audioPlayer: {
			src: require("../audio/55665533553653.mp3"),
			autoplay: true,
			controls: true,
			style: {
				bottom: "7vh",
				zIndex: 1,
			},
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/L4_bg.png"),
				style: {
					position: "absolute",
					width: "90vw",
					bottom: "20vh",
					left: "5vw",
				},
			},
			{
				type: "img",
				src: require("../images/staff.png"),
				style: {
					position: "absolute",
					width: "58vw",
					bottom: "28vh",
					left: "50vw",
					transform: "translateX(-50%)",
				},
			},
		],
	},
	6: {
		pageBg: startPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson10/game-1",
		talkList: [
			{
				talker: "geza",
				talkerCHN: "鸽子",
				content:
					"你们寻找的生命音符的线索，就在这橄榄枝生长的地方，也就是远处的魔法学院，你们出去之后可以坐魔法火车到达那里",
				audio: require("../audio/鸽子3.mp3"),
			},
			{
				talker: "geza",
				talkerCHN: "鸽子",
				content:
					"正义的游侠佐罗。你寻找的战无不胜的办法，也是这橄榄枝。橄榄枝象征和平。只有和平，不与人争斗，才能够真正的战无不胜。战斗并不能解决问题，和平才是最后的答案。",
				audio: require("../audio/gz2.mp3"),
			},
			{
				talker: "geza",
				talkerCHN: "鸽子",
				content:
					"我为你们献上祝福。希望你们记得，人与人之间的和平才是正确的相处之道。正义的游侠佐罗，我也希望你永远记得，你的剑只为和平出鞘。",
				audio: require("../audio/鸽子6.mp3"),
			},
			{
				isBreak: true,
				callback: (vm) => {
					vm.animalDiamond = require("../images/祝福-鸽子.png");
					vm.showMagicBook = true;
				},
			},
			{
				talker: "zuoluo",
				talkerCHN: "佐罗",
				content:
					"谢谢你们帮助我找到了战无不胜的办法，我还要去很远的地方，那里还有人需要我的帮助，我要为那里的人带去和平。再见吧，我的朋友们，或许在不远的未来，我们还会相见。",
				audio: require("../audio/zl1.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "让我来念首诗，为你送行吧，来纪念我们一路的冒险与同行。",
				audio: require("../audio/宫廷7.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/olive.png"),
				style: {
					position: "absolute",
					width: "15vw",
					bottom: "23vh",
					left: "50vw",
					transform: "translateX(-50%)",
				},
			},
		],
	},
	7: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson1/page-home",
		isEnd: true,
		roles: roles(0.98),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "再见了佐罗。我们也要向魔法学院出发了。",
				audio: require("../audio/宫廷8.mp3"),
			},
		],
	},
};

function showPit(vm) {
	if (isAppendPit) return;
	isAppendPit = true;
	const img = document.createElement("img");
	img.src = require("../images/pit.png");
	img.classList.add("appendPit");
	img.style.cssText =
		"position: absolute; bottom: 10px; width: 65vw; left: 17vw;";
	vm.$el.appendChild(img);
}
function removePit() {
	if (!isAppendPit) return;
	const img = document.querySelector(
		"div.common-page.common-lesson-page .appendPit"
	);
	img && img.remove();
	isAppendPit = false;
}
function show(e, a, b) {
	return e > a && e < b;
}
