<template>
    <div class="common-page">
        <img class="next-btn" src="@/assets/image/next.png" @click.stop="gonext()" />
        <div class="role-box" v-for="role of roles" :class="role.roleClass + '-box'" :style="role.style"
            :key="role.roleClass">
            <img :src="role.imgSrc" :class="role.roleClass" :style="role.roleStyle" />
        </div>
        <img @click="changeOrder" class="cloud" src="./images/L5_cloud.png">
        <div @click.stop="changeOrder" class="notes-box">
            <img class="note" v-for="(note, nIndex) of notes" :key="nIndex" :src="note.src" alt="">
        </div>

    </div>
</template>
  
<script>
import { roles } from "./config/index";
export default {
    components: {},
    data() {
        return {
            roles: roles(.865),
            notes: [
                {
                    src: require('./images/L5_note_2.png')
                },
                {
                    src: require('./images/L5_note_1.png')
                },
                {
                    src: require('./images/L5_note_1.png')
                },
                {
                    src: require('./images/L5_note_2.png')
                }
            ]
        };
    },
    methods: {
        gonext() {
            this.$router.push("/lesson5/page?page=3");
        },
        changeOrder() {
            let oldOrder = JSON.stringify(this.notes);
            this.notes = this.notes.sort(() => {
                return Math.random() - 0.5;
            });
            let newOrder = JSON.stringify(this.notes);
            if (oldOrder == newOrder) {
                this.changeOrder();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.common-page {
    background-image: url("./images/bg_L5_normal.jpg");
}

.cloud {
    position: absolute;
    width: 31.114vw;
    top: 9.885vh;
    left: 42.843vw;
    cursor: pointer;
    z-index: 10
}

.notes-box {
    position: absolute;
    left: 47.625vw;
    top: 22.804vh;
    z-index: 11;
    cursor: pointer;
}

.note {
    height: 13.98vh;
    &+.note {
        margin-right: 12px;
    }

}
</style>
  