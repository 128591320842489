<template>
	<div
		class="common-page"
		@mousemove="move"
		@mouseup="end"
	>
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson11/page?page=5')"
			class="next-btn"
		/>
		<div class="container">
			<div class="tooltip">
				<div class="left">
					<div
						class="item"
						v-for="(item, index) in noteList"
						:key="index"
					>
						<img
							class="window"
							src="./images/train/小火车窗户@2x.png"
						/>
						<img
							class="note-symbol notes"
							:class="`note-symbol-${index}`"
							:src="item.img"
							data-type="note"
							@mousedown="start"
						/>
					</div>
				</div>
				<div
					class="left"
					style="margin-left: 40px"
				>
					<div
						class="item"
						v-for="(item, index) in toneList"
						:key="index"
					>
						<img
							class="window"
							src="./images/train/小火车音符字母背景@2x.png"
						/>
						<img
							class="note-symbol rhythm"
							:src="item.img"
							data-type="symbol"
							:data-symbol="item.symbol"
							@mousedown="start"
						/>
					</div>
				</div>
			</div>
			<div class="rail"></div>
			<div class="rail rail-left-train"></div>
			<div class="train-left">
				<div class="train_head"></div>
				<div class="train_body">
					<div class="badge">1</div>
					<div
						class="item"
						v-for="item in 4"
						:key="item"
					>
						<img
							class="window body-note"
							src="./images/train/小火车窗户@2x.png"
						/>
						<img
							class="symbol body-symbol"
							src="./images/train/小火车音符字母背景@2x.png"
						/>
					</div>
				</div>
				<div class="train_body">
					<div class="badge">2</div>
					<div
						class="item"
						v-for="item in 4"
						:key="item"
					>
						<img
							class="window body-note"
							src="./images/train/小火车窗户@2x.png"
						/>
						<img
							class="symbol body-symbol"
							src="./images/train/小火车音符字母背景@2x.png"
						/>
					</div>
				</div>
			</div>
			<div class="train-right">
				<div class="train_body">
					<div class="badge">3</div>
					<div
						class="item"
						v-for="item in 4"
						:key="item"
					>
						<img
							class="window body-note"
							src="./images/train/小火车窗户@2x.png"
						/>
						<img
							class="symbol body-symbol"
							src="./images/train/小火车音符字母背景@2x.png"
						/>
					</div>
				</div>
				<div class="train_body">
					<div class="badge">4</div>
					<div
						class="item"
						v-for="item in 4"
						:key="item"
					>
						<img
							class="window body-note"
							src="./images/train/小火车窗户@2x.png"
						/>
						<img
							class="symbol body-symbol"
							src="./images/train/小火车音符字母背景@2x.png"
						/>
					</div>
				</div>
				<div class="train_foot">
					<img
						src="./images/reset.png"
						@click="reset"
					/>
				</div>
			</div>
		</div>
		<audio
			ref="audioPlayer"
			style="display: none"
			:src="audioSrc"
		></audio>
	</div>
</template>

<script>
import { Resize } from "@/utils/Resize";
import { gsap } from "gsap";
export default {
	data() {
		return {
			resize: new Resize(),
			isMove: false,
			node: null,
			audioSrc: "",
			offset: {},
			noteHash: [],
			symbolHash: [],
			noteList: [
				{
					img: require("./images/train/四分音符@2x.png"),
				},
				{
					img: require("./images/train/八分音符@2x.png"),
				},
				{
					img: require("./images/train/二分音符@2x.png"),
				},
				{
					img: require("./images/train/全音符@2x.png"),
				},
			],
			toneList: [
				{
					img: require("./images/train/letter_S@2x.png"),
					symbol: "s",
				},
				{
					img: require("./images/train/letter_M@2x.png"),
					symbol: "m",
				},
				{
					img: require("./images/train/letter_L@2x.png"),
					symbol: "l",
				},
				{
					img: require("./images/train/letter_D@2x.png"),
					symbol: "d",
				},
			],
		};
	},
	mounted() {
		this.resize.on(this.reset);
		this.resize.on(this.initRect);
		this.initRect();
	},
	destroyed() {
		this.resize.unListen();
	},
	methods: {
		initRect() {
			const notes = document.querySelectorAll(".notes");
			const rhythms = document.querySelectorAll(".rhythm");
			notes.forEach((node) => {
				const { x, y } = node.getBoundingClientRect();
				node.setAttribute("x", x);
				node.setAttribute("y", y);
			});
			rhythms.forEach((node) => {
				const { x, y } = node.getBoundingClientRect();
				node.setAttribute("x", x);
				node.setAttribute("y", y);
			});
			setTimeout(() => {
				const bodyNotes = document.querySelectorAll(".body-note");
				bodyNotes.forEach((node) => {
					const { top, left, right, bottom } = node.getBoundingClientRect();
					this.noteHash.push({ top, left, right, bottom });
				});
				const bodySymbols = document.querySelectorAll(".body-symbol");
				bodySymbols.forEach((node) => {
					const { top, left, right, bottom } = node.getBoundingClientRect();
					this.symbolHash.push({ top, left, right, bottom });
				});
			}, 1000);
		},
		start(e) {
			this.isMove = true;
			const { target, clientX, clientY, offsetX, offsetY } = e;
			this.node = target.cloneNode();
			this.node.classList.add("note");
			this.node.onmousedown = (event) => {
				this.isMove = true;
				this.node = event.target;
				this.offset = {
					x: event.offsetX,
					y: event.offsetY,
				};
			};
			this.offset = {
				x: offsetX,
				y: offsetY,
			};
			this.node.style.cssText = `left: ${clientX - offsetX}px;top: ${
				clientY - offsetY
			}px`;
			const father = document.querySelector(".common-page");
			father.appendChild(this.node);
		},
		move(e) {
			if (this.isMove) {
				const { clientX, clientY } = e;
				this.node.style.cssText = `left: ${clientX - this.offset.x}px;top: ${
					clientY - this.offset.y
				}px`;
			}
		},
		end() {
			this.isMove = false;
			const { x, y, width, height } = this.node.getBoundingClientRect();
			const ox = x + width / 2;
			const oy = y + height / 2;
			const type = this.node.dataset.type;
			const hash = type === "note" ? this.noteHash : this.symbolHash;
			for (const { top, right, bottom, left } of hash) {
				if (left <= ox && right >= ox && top <= oy && bottom >= oy) {
					if (type === "symbol") {
						this.audioSrc = require(`@/assets/audio/${this.node.dataset.symbol}.mp3`);
						console.log(this.audioSrc);
						this.$nextTick(() => {
							this.$refs.audioPlayer.play();
						});
					}
					return;
				}
			}
			gsap.to(this.node, {
				left: this.node.getAttribute("x"),
				top: this.node.getAttribute("y"),
				onComplete: () => {
					this.node.remove();
				},
			});
		},
		reset() {
			const els = document.querySelectorAll(".note");
			els.forEach((el) => el.remove());
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson11/images/train/bg.png");
}
.note-symbol {
	position: absolute;
	width: 56px;
	cursor: pointer;
	padding-top: 20px;
	&-1 {
		width: 4vw;
	}
	&-3 {
		width: 5vw;
	}
}
.note {
	position: absolute;
	z-index: 15;
	cursor: pointer;
}
.container {
	position: fixed;
	inset: 0;
	.tooltip {
		position: absolute;
		top: 1vh;
		left: 0;
		width: 100vw;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		.left {
			display: flex;
			align-items: center;
			.item {
				padding: 10px 6px 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.window {
			width: 126px;
		}
	}
	:deep {
		.rail {
			width: 100vw;
			position: absolute;
			left: 0;
			bottom: 20px;
			height: 15px;
			background: url("~@/views/lesson11/images/train/rail@2x.png") repeat-x;
			background-size: auto 15px;
			&-left-train {
				bottom: 420px;
			}
		}
		.train {
			position: absolute;
			&-left,
			&-right {
				position: absolute;
				bottom: 35px;
				left: 0;
				width: 100vw;
				height: 398px;
				display: flex;
				align-items: end;
			}
			&-left {
				bottom: 435px;
				padding-left: 34px;
				background: url("~@/views/lesson11/images/train/小火车_车厢@2x.png")
					no-repeat;
				background-size: 690px auto;
				background-position: 92.5vw bottom;
			}
			&-right {
				padding-left: 64px;
				background: url("~@/views/lesson11/images/train/小火车_车厢@2x.png")
					no-repeat;
				background-size: 690px auto;
				background-position: -32.5vw bottom;
			}
			&_head {
				flex: 0 0 auto;
				width: 410px;
				height: 297px;
				background: url("~@/views/lesson11/images/train/小火车_火车头@2x.png")
					no-repeat;
				background-position: left bottom;
				background-size: 100% auto;
			}
			&_body {
				position: relative;
				flex: 0 0 auto;
				width: 690px;
				height: 398px;
				background: url("~@/views/lesson11/images/train/小火车_车厢@2x.png")
					no-repeat;
				background-position: left bottom;
				background-size: 100% auto;
				margin-left: -16px;
				box-sizing: border-box;
				padding: 120px 44px 110px 49px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.badge {
					position: absolute;
					top: 36px;
					left: 50px;
					color: #fff;
					font-weight: 700;
					font-size: 20px;
				}
				.item {
					display: flex;
					flex-direction: column;
					padding: 0 6px;
					.window {
						width: 100%;
					}
					.symbol {
						width: 100%;
						margin-top: 4px;
					}
				}
			}
			&_foot {
				flex: 0 0 auto;
				width: 370px;
				height: 267px;
				background: url("~@/views/lesson11/images/train/小火车_尾车@2x.png")
					no-repeat;
				background-position: left bottom;
				background-size: 100% auto;
				margin-left: -16px;
				img {
					width: 200px;
					margin-left: 70px;
					margin-top: 70px;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
