const pageBg = require("../images/bg_deep.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "40vw",
				bottom: "9vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "52vw",
				bottom: "9vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${31 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "65.5vw",
				bottom: "9vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${44 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

const roles_deep = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "47vw",
				bottom: "22vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${30 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "50vw",
				bottom: "23vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${26 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "62vw",
				bottom: "23vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${30 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

const roles_water = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "34vw",
				bottom: "12vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "12vw",
				bottom: "12vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${31 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "8vw",
				bottom: "10vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${44 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

export default {
	lessonStart: {
		// 首页
		pageBg: require("../images/index_bg.png"), // 首页背景
		pageTitleImage: require("@/views/lesson24/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson24/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=2",
		audio: {
			src: require("../audio/旁白2401.mp3"),
			autoplay: true,
		},
		roles: roles(1),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "大家准备战斗，这个黑衣人身上可以感受到非常强大的力量。",
				audio: require("../audio/宫廷乐师2401.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "他的魔法异乎寻常，一定是黑魔法。",
				audio: require("../audio/魔法师2401.mp3"),
			},
			{
				talker: "blackman",
				talkerCHN: "黑衣人",
				content:
					"你们真的很厉害，可以找到这里。不枉我跟了你们这么久。谢谢你们帮我找到了生命音符的下落。",
				audio: require("../audio/黑衣人-你们真的很厉害.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你为什么也要找生命音符？",
				audio: require("../audio/宫廷乐师2402.mp3"),
			},
			{
				talker: "blackman",
				talkerCHN: "黑衣人",
				content:
					"生命音符是创世音符中最神秘的一个。我想国王没有告诉你们，它可以控制这个世界上所有生命的力量。不仅可以让人起死回生，也可以让任何生命迅速消失。",
				audio: require("../audio/黑衣人拆分_1_1.mp3"),
			},
			{
				talker: "blackman",
				talkerCHN: "黑衣人",
				content:
					"更何况，如果让我找到所有的创世音符的话。哈哈哈，如果你们有命回去的话，去问问国王吧。",
				audio: require("../audio/黑衣人拆分_1_2.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"世界上就是因为有你这样的坏人才会有这么多的战争和混乱。你到底是什么人？",
				audio: require("../audio/宫廷乐师2403.mp3"),
			},
			{
				talker: "blackman",
				talkerCHN: "黑衣人",
				content:
					"我是什么人不重要。反正你们没有人可以离开这座神殿，在我们的力量面前，你们太弱小了。",
				audio: require("../audio/黑衣人2403.mp3"),
			},
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "太小看我们了！",
				audio: require("../audio/骑士2401.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/黑衣人和生命音符.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "0",
					left: "0",
					zIndex: 10,
				},
			},
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=3",
		audio: {
			src: require("../audio/旁白2402.mp3"),
			autoplay: true,
		},
		roles: roles(1),
		otherElement: [
			{
				type: "img",
				src: require("../images/黑衣人和生命音符还有闪电@2x.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "0",
					left: "0",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/碎掉的剑.png"),
				style: {
					position: "absolute",
					width: "14vw",
					bottom: "4vh",
					right: "22vw",
					zIndex: 10,
				},
			},
		],
	},
	3: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=4",
		audio: {
			src: require("../audio/旁白2403.mp3"),
			autoplay: true,
		},
		roles: roles(1, [1], {
			1: {},
		}),
		otherElement: [
			{
				type: "img",
				src: require("../images/黑衣人击倒魔法师@2x.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "0",
					left: "0",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/碎掉的剑.png"),
				style: {
					position: "absolute",
					width: "12vw",
					bottom: "4vh",
					right: "20vw",
					zIndex: 10,
				},
			},
		],
	},
	4: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=5",
		roles: roles(1),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"这片大陆上有如此力量的人不多，而且从你魔法的感觉来看。恐怕我认识你，所以你才穿着黑袍，不敢露出真面部。藏头露尾，不是君子所为。",
				audio: require("../audio/宫廷乐师2404.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/黑衣人和生命音符.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "0",
					left: "0",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/碎掉的剑.png"),
				style: {
					position: "absolute",
					width: "12vw",
					bottom: "4vh",
					right: "20vw",
					zIndex: 10,
				},
			},
		],
	},
	5: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=6",
		roles: [
			{
				roleClass: "musician",
				imgSrc: require("../images/实战魔法的宫廷乐师@2x.png"),
				style: {
					width: "auto",
					left: "45vw",
					bottom: "7vh",
					zIndex: 8,
				},
				roleStyle: {
					height: `${40}vh`,
				},
			},
			{
				roleClass: "magician",
				imgSrc: require("@/assets/image/role/magician.png"),
				style: {
					width: "auto",
					left: "46vw",
					bottom: "9vh",
					zIndex: 7,
				},
				roleStyle: {
					height: `${31}vh`,
				},
			},
			{
				roleClass: "knight",
				imgSrc: require("@/assets/image/role/knight.png"),
				style: {
					width: "auto",
					left: "73vw",
					bottom: "7vh",
					zIndex: 9,
				},
				roleStyle: {
					height: `${44}vh`,
					transform: "rotateY(180deg)",
				},
			},
		],
		audio: {
			src: require("../audio/旁白2404.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "太强大了，掌握这种闪电魔法的人并不多见",
				audio: require("../audio/宫廷乐师2405.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/双手举起射出耀眼闪电的黑衣人@2x.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "0",
					left: "0",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/碎掉的剑.png"),
				style: {
					position: "absolute",
					width: "12vw",
					bottom: "3vh",
					right: "20vw",
					zIndex: 10,
				},
			},
		],
	},
	6: {
		pageBg: require("../images/bg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=7",
		roles: roles_deep(1),
		audio: {
			src: require("../audio/旁白2405.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				audio: require("../audio/旁白2406.mp3"),
			},
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "是海神的三叉戟，人鱼族来了？",
				audio: require("../audio/骑士2402.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/黑衣人和生命音符.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "0",
					left: "0",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/碎掉的剑.png"),
				style: {
					position: "absolute",
					width: "8vw",
					bottom: "20vh",
					right: "30vw",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/插入的三叉戟.png"),
				style: {
					position: "absolute",
					height: "60vh",
					bottom: "12vh",
					left: "36vw",
					zIndex: 10,
				},
			},
		],
	},
	7: {
		pageBg: require("../images/bg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=8",
		roles: roles_deep(1),
		audio: {
			src: require("../audio/旁白2407-人鱼女王带领.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "mermaid",
				talkerCHN: "人鱼女王",
				content: "宫廷乐师，好久不见。我来履行承诺了。",
				audio: require("../audio/人鱼女王-1.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "幸亏你来的即时，再晚一点的话",
				audio: require("../audio/宫廷乐师2406.mp3"),
			},
			{
				talker: "mermaid",
				talkerCHN: "人鱼女王",
				content:
					"当初你的故事打动了我，我这次来帮助你算我履行了当年的承诺。不过这黑衣人很强大。还记得我告诉过你的人鱼族海神魔法吗？我需要你们的协助。",
				audio: require("../audio/人鱼女王-2.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/黑衣人和生命音符.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "0",
					left: "0",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/碎掉的剑.png"),
				style: {
					position: "absolute",
					width: "8vw",
					bottom: "20vh",
					right: "30vw",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/人鱼女王和战士@2x.png"),
				style: {
					position: "absolute",
					height: "42vh",
					bottom: "0",
					right: "1vw",
					zIndex: 10,
				},
			},
		],
	},
	8: {
		pageBg: require("../images/人鱼女王吟唱时候的背景@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/game-1",
		roles: [],
		audio: {
			src: require("../audio/旁白2408.mp3"),
			autoplay: true,
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/人鱼女王@2x.png"),
				style: {
					position: "absolute",
					height: "75vh",
					bottom: "0",
					left: "44vw",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/歌声@2x.png"),
				style: {
					position: "absolute",
					width: "40vw",
					top: "11vh",
					left: "14vw",
					zIndex: 10,
				},
			},
		],
	},
	9: {
		pageBg: require("../images/bg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=10",
		roles: roles_deep(1),
		audio: {
			src: require("../audio/旁白2409.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "blackman",
				talkerCHN: "黑衣人",
				content:
					"人鱼族的魔法竟然这么厉害。那就别怪我尽全力了，如果我得不到神秘音符，我就毁掉他。",
				audio: require("../audio/黑衣人2404.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/黑衣人和生命音符.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "0",
					left: "-4vw",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/人鱼女王和战士@2x.png"),
				style: {
					position: "absolute",
					height: "42vh",
					bottom: "0",
					right: "1vw",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/水球@2x.png"),
				style: {
					position: "absolute",
					width: "30vw",
					bottom: "4vh",
					left: "34vw",
					zIndex: 10,
				},
			},
		],
	},
	10: {
		pageBg: require("../images/一爆照整图@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=11",
		roles: [],
		audio: {
			src: require("../audio/旁白2410.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				audio: require("../audio/旁白2411.mp3"),
			},
		],
	},
	11: {
		pageBg: require("../images/bg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=12",
		roles: roles_deep(1),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "天哪，这两种魔法的力量太强大了。生命音符居然碎成了两片。",
				audio: require("../audio/宫廷乐师2407.mp3"),
			},
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "我去抢下来。",
				audio: require("../audio/骑士2403.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "我帮你。",
				audio: require("../audio/魔法师2402.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/黑衣人_blur.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "0",
					left: "-4vw",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/人鱼女王和战士@2x.png"),
				style: {
					position: "absolute",
					height: "42vh",
					bottom: "0",
					right: "1vw",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/碎掉的生命音符@2x.png"),
				style: {
					position: "absolute",
					width: "25vw",
					bottom: "7vh",
					left: "34vw",
					zIndex: 10,
				},
			},
		],
	},
	12: {
		pageBg: require("../images/bg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=1211",
		roles: roles_deep(1, [2], {
			2: {
				roleClass: "knight",
				imgSrc: require("../images/铠甲碎掉的骑士@2x.png"),
				style: {
					width: "auto",
					left: "62vw",
					bottom: "23vh",
					zIndex: 9,
				},
				roleStyle: {
					height: `${30}vh`,
					transform: "rotateY(180deg)",
				},
			},
		}),
		audio: {
			src: require("../audio/旁白2411.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "blackman",
				talkerCHN: "黑衣人",
				content: "我小看了你们的本事，不过这一半的生命音符我就拿走了。",
				audio: require("../audio/黑衣人2405.mp3"),
			},
			{
				audio: require("../audio/旁白2412.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/冲出神殿的黑衣人@2x.png"),
				style: {
					position: "absolute",
					width: "16vw",
					bottom: "30vh",
					left: "14vw",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/人鱼女王和战士@2x.png"),
				style: {
					position: "absolute",
					height: "42vh",
					bottom: "0",
					right: "1vw",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/碎掉的生命音符_cri.png"),
				style: {
					position: "absolute",
					width: "3vw",
					bottom: "10vh",
					left: "56vw",
					zIndex: 10,
				},
			},
		],
	},
	1211: {
		pageBg: require("../images/bg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=13",
		roles: roles_deep(1, [2], {
			2: {
				roleClass: "knight",
				imgSrc: require("../images/铠甲碎掉的骑士@2x.png"),
				style: {
					width: "auto",
					left: "62vw",
					bottom: "23vh",
					zIndex: 9,
				},
				roleStyle: {
					height: `${30}vh`,
					transform: "rotateY(180deg)",
				},
			},
		}),
		talkList: [
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "我们去追他！",
				audio: require("../audio/魔法师2403.mp3"),
			},
			{
				talker: "mermaid",
				talkerCHN: "人鱼女王",
				content:
					"不要去了，你们都受了重伤。何况即使追杀他，就算有本王帮你，也不一定是他的对手。看来这世界又要开始动荡了。",
				audio: require("../audio/人鱼女王-3.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "无论如何，还是要多谢女王陛下的帮助。",
				audio: require("../audio/宫廷乐师2408.mp3"),
			},
			{
				talker: "mermaid",
				talkerCHN: "人鱼女王",
				content:
					"哎，这算是履行我对你的承诺吧。更何况几天就我也收到了你们国王的来信，提醒我们会有事情发生。于公于私，我都没有理由不帮你。我让士兵送你们回到海面，你们快回去吧。",
				audio: require("../audio/人鱼女王-4.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/人鱼女王和战士@2x.png"),
				style: {
					position: "absolute",
					height: "42vh",
					bottom: "0",
					right: "1vw",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/碎掉的生命音符_cri.png"),
				style: {
					position: "absolute",
					width: "3vw",
					bottom: "10vh",
					left: "56vw",
					zIndex: 10,
				},
			},
		],
	},
	13: {
		pageBg: require("../images/有人鱼女王背景和老鹰@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=14",
		roles: roles_water(1.5),
		audio: {
			src: require("../audio/旁白2413.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "我们找了这么久，可惜就只拿到了一半的生命音符。",
				audio: require("../audio/骑士2404.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "是很可惜，可是我们引出了神秘的黑衣人，恐怕背后有更大的阴谋。",
				audio: require("../audio/宫廷乐师2409.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "你们看，我们好像有老朋友来了。",
				audio: require("../audio/魔法师2404.mp3"),
			},
			{
				audio: require("../audio/旁白2414.mp3"),
			},
		],
	},
	14: {
		pageBg: require("../images/有人鱼女王背景@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=15",
		roles: roles_water(1.5),
		talkList: [
			{
				talker: "eagle",
				talkerCHN: "雄鹰",
				content: "你看，我就说我们还会见面吧。",
				audio: require("../audio/鹰2401.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你们怎么来这里了？这里距离雪山可是很远啊。",
				audio: require("../audio/宫廷乐师2410.mp3"),
			},
			{
				talker: "eagle",
				talkerCHN: "雄鹰",
				content:
					"我们是从王都飞过来的，我们是国王的信使，给你们带来了国王的信。",
				audio: require("../audio/鹰2402.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/叼着信件的老鹰@2x.png"),
				style: {
					position: "absolute",
					height: "50vh",
					bottom: "26.7vh",
					left: "45vw",
					zIndex: 10,
				},
			},
		],
	},
	15: {
		pageBg: require("../images/有人鱼女王背景@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=16",
		roles: roles_water(1.5),
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/没有叼着信件的老鹰@2x.png"),
				style: {
					position: "absolute",
					height: "50vh",
					bottom: "26.7vh",
					left: "45vw",
					zIndex: 10,
				},
			},
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.3)",
					zIndex: 20,
				},
			},
			{
				type: "img",
				src: require("../images/bg_beat_and_music_close@2x.png"),
				style: {
					position: "absolute",
					height: "80vh",
					bottom: "10vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 21,
					cursor: "pointer",
				},
				click(vm) {
					vm.$router.push("/lesson24/page?page=16");
				},
			},
		],
	},
	16: {
		pageBg: require("../images/有人鱼女王背景@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=17",
		roles: roles_water(1.5),
		talkList: [],
		audio: {
			src: require("../audio/旁白2415.mp3"),
			autoplay: true,
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/没有叼着信件的老鹰@2x.png"),
				style: {
					position: "absolute",
					height: "50vh",
					bottom: "26.7vh",
					left: "45vw",
					zIndex: 10,
				},
			},
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.3)",
					zIndex: 20,
				},
			},
			{
				type: "img",
				src: require("../images/国王的来信_展开@2x.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "5vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 21,
					cursor: "pointer",
				},
			},
		],
	},
	17: {
		pageBg: require("../images/有人鱼女王背景@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson24/page?page=18",
		roles: roles_water(1.5),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"国王陛下说，小公主的生日宴会不日就要召开。到时候所有的贵族和诸侯都会到达王都参加。陛下要我们无论是否找到生命音符都要火速回到王都。看来不只是生日宴会这么简单吧。",
				audio: require("../audio/宫廷乐师2411.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"好吧，那我们就把这半个音符先带回去吧。果然被人鱼女王料中了，世界开始不平静了。",
				audio: require("../audio/宫廷乐师2412.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "那我们就快些启程吧。",
				audio: require("../audio/魔法师2405.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"非常感谢大家一路的相互扶持与帮助，我们才完成了这样一次精彩的旅程。虽然我们只拿到了半个生命音符。但是我们一路上的受到的祝福，学到的知识，还有大家之间的友谊都是世界上最珍贵的宝物。",
				audio: require("../audio/宫廷乐师2413.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/没有叼着信件的老鹰@2x.png"),
				style: {
					position: "absolute",
					height: "50vh",
					bottom: "26.7vh",
					left: "45vw",
					zIndex: 10,
				},
			},
		],
	},
	18: {
		pageBg: require("../images/玫瑰色的天空与海面@2x.png"),
		pageNextBtnImage,
		isEnd: true,
		nextPageRoute: "/lesson25/page",
		audio: {
			src: require("../audio/旁白2416.mp3"),
			autoplay: true,
		},
	},
};
