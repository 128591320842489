<template>
	<div class="common-page">
		<img
			class="next-btn"
			src="@/assets/image/next.png"
			@click.stop="gonext()"
		/>
		<div class="con1">
			<div style="display: flex">
				<div class="btn">
					<img
						src="@/assets/image/hand2.png"
						class="imgg1"
					/>
					<img
						class="img2 musical"
						data-audio="m"
						src="@/assets/image/m.png"
					/>
					<div
						draggable="false"
						class="bbtn1"
					></div>
				</div>
				<div
					class="btn1"
					@click="startm"
				>
					<img
						src="@/assets/image/hand2.png"
						class="imgg1"
					/>
					<img
						class="img2 musical"
						data-audio="m"
						src="@/assets/image/m.png"
					/>
					<div
						draggable="false"
						class="bbtn2"
					></div>
				</div>
				<div
					class="btn2"
					@click="starts"
				>
					<img
						src="@/assets/image/hand1.png"
						class="imgg1"
					/>
					<img
						class="img2 musical"
						data-audio="s"
						src="@/assets/image/s.png"
					/>
					<div
						draggable="false"
						class="bbtn3"
					></div>
				</div>
				<div
					class="btn2"
					@click="starts"
				>
					<img
						src="@/assets/image/hand1.png"
						class="imgg1"
					/>
					<img
						class="img2 musical"
						data-audio="s"
						src="@/assets/image/s.png"
					/>
					<div
						draggable="false"
						class="bbtn4"
					></div>
				</div>
				<div
					class="btn1"
					@click="startm"
				>
					<img
						src="@/assets/image/hand2.png"
						class="imgg1"
					/>
					<img
						class="img1 musical"
						data-audio="m"
						src="@/assets/image/lm.png"
					/>
					<div
						draggable="false"
						class="bbtn5"
					></div>
				</div>
				<div
					class="btn1"
					@click="startm"
				>
					<img
						src="@/assets/image/hand1.png"
						class="imgg1"
					/>
					<img
						class="img1 musical"
						data-audio="s"
						src="@/assets/image/ls.png"
					/>
					<div
						draggable="false"
						class="bbtn6"
					></div>
				</div>
			</div>
			<div class="contbotoom">
				<div
					class="custom-btn"
					@click="back()"
				>
					撤回
				</div>
				<div
					class="custom-btn"
					@click="clear()"
				>
					清屏
				</div>
				<div
					class="custom-btn"
					@click="checkright()"
				>
					查看答案
				</div>
			</div>
			<canvas
				id="canvas"
				@mousedown="mousedown1"
				@mouseup="mouseup1"
				style="position: absolute; width: 100vw; height: 100vh"
			>
			</canvas>
		</div>
		<audio
			ref="btnClickAudio"
			autoplay
			:src="btnClickAudioSrc"
			style="display: none"
		></audio>
	</div>
</template>

<script>
import { getElementRect } from "@/utils";
import { DrawLine } from "@/DrawLine";
export default {
	data() {
		return {
			drawLine: new DrawLine(),
			x1: 0,
			x2: 0,
			y1: 0,
			y2: 0,
			cliarr: [],
			btnClickAudioSrc: "",
			isClickMusical: null,
			// state:false
		};
	},
	mounted() {
		this.isClickMusical = getElementRect("musical", true);
		this.drawLine.resize.on(this.getRect);
		this.drawLine.on(this.handler);
		const els = document.querySelectorAll("img[data-audio]");
		els.forEach((node) => {
			node.addEventListener("click", this.playAudio);
		});
	},
	beforeDestroy() {
		this.drawLine.destroy();
		this.isClickMusical = null;
		const els = document.querySelectorAll("img[data-audio]");
		els.forEach((node) => {
			node.removeEventListener("click", this.playAudio);
		});
	},
	methods: {
		playAudio(e) {
			this.btnClickAudioSrc = require(`@/assets/music/${e.target.dataset.audio}.mp3`);
			this.$refs.btnClickAudio.load();
			this.$nextTick(() => {
				this.$refs.btnClickAudio.play();
			});
		},
		handler(x, y) {
			this.isClickMusical(x, y, ({ el }) => {
				this.btnClickAudioSrc = require(`@/assets/music/${el.dataset.audio}.mp3`);
				this.$refs.btnClickAudio.load();
				this.$nextTick(() => {
					this.$refs.btnClickAudio.play();
				});
			});
		},
		getRect() {
			const result = getElementRect("musical", true);
			if (result) {
				this.isClickMusical = result;
			}
		},
		back() {
			this.drawLine.back();
			if (this.cliarr.length == 1) {
				this.x1 = 0;
				this.y1 = 0;
			}
			this.cliarr.length = this.cliarr.length - 1;

			var canvas = document.getElementById("canvas");

			canvas.width = document.documentElement.clientWidth;
			canvas.height = document.documentElement.clientHeight;
			if (canvas.getContext("2d")) {
				var context = canvas.getContext("2d");
				// 应用context绘制
				// 从坐标(100,100)为终点开始
				context.moveTo(this.x1, this.y1);
				// 到坐标(700,700)为起点画一条直线
				for (let i = 0; i < this.cliarr.length; i++) {
					context.lineTo(this.cliarr[i][0], this.cliarr[i][1]);
				}

				// context.lineTo(100, 700)
				// 线宽为5
				context.lineWidth = 5;
				// 线的款式色彩
				context.strokeStyle = "#fff";
				// 执行划直线这个操作
				context.stroke();
			} else {
				alert("当前浏览器不支持canvas，请更换浏览器后重试");
			}
		},
		clear() {
			this.drawLine.clean();
			this.x1 = 0;
			this.y1 = 0;
			this.cliarr = [];
			var canvas = document.getElementById("canvas");
			canvas.width = document.documentElement.clientWidth;
			canvas.height = document.documentElement.clientHeight;
			if (canvas.getContext("2d")) {
				var context = canvas.getContext("2d");
				// 应用context绘制
				// 从坐标(100,100)为终点开始
				context.moveTo(this.x1, this.y1);
				// 到坐标(700,700)为起点画一条直线
				for (let i = 0; i < this.cliarr.length; i++) {
					context.lineTo(this.cliarr[i][0], this.cliarr[i][1]);
				}

				// context.lineTo(100, 700)
				// 线宽为5
				context.lineWidth = 5;
				// 线的款式色彩
				context.strokeStyle = "#fff";
				// 执行划直线这个操作
				context.stroke();
			} else {
				alert("当前浏览器不支持canvas，请更换浏览器后重试");
			}
		},
		checkright() {
			this.drawLine.clean();
			this.cliarr = [];
			let btn1 = document.getElementsByClassName("bbtn1");
			let btn2 = document.getElementsByClassName("bbtn2");
			let btn3 = document.getElementsByClassName("bbtn3");
			let btn4 = document.getElementsByClassName("bbtn4");
			let btn5 = document.getElementsByClassName("bbtn5");
			let btn6 = document.getElementsByClassName("bbtn6");

			let btnn1x =
				btn1[0].getBoundingClientRect().x +
				btn1[0].getBoundingClientRect().width / 2;
			let btnn2x =
				btn2[0].getBoundingClientRect().x +
				btn2[0].getBoundingClientRect().width / 2;
			let btnn3x =
				btn3[0].getBoundingClientRect().x +
				btn3[0].getBoundingClientRect().width / 2;
			let btnn4x =
				btn4[0].getBoundingClientRect().x +
				btn4[0].getBoundingClientRect().width / 2;
			let btnn5x =
				btn5[0].getBoundingClientRect().x +
				btn5[0].getBoundingClientRect().width / 2;
			let btnn6x =
				btn6[0].getBoundingClientRect().x +
				btn6[0].getBoundingClientRect().width / 2;
			let btnn1y =
				btn1[0].getBoundingClientRect().y +
				btn1[0].getBoundingClientRect().height / 2;
			let btnn2y =
				btn2[0].getBoundingClientRect().y +
				btn2[0].getBoundingClientRect().height / 2;
			let btnn3y =
				btn3[0].getBoundingClientRect().y +
				btn3[0].getBoundingClientRect().height / 2;
			let btnn4y =
				btn4[0].getBoundingClientRect().y +
				btn4[0].getBoundingClientRect().height / 2;
			let btnn5y =
				btn5[0].getBoundingClientRect().y +
				btn5[0].getBoundingClientRect().height / 2;
			let btnn6y =
				btn6[0].getBoundingClientRect().y +
				btn6[0].getBoundingClientRect().height / 2;
			this.x1 = btnn1x;
			this.y1 = btnn1y;
			this.cliarr.push([btnn2x, btnn2y]);
			this.cliarr.push([btnn3x, btnn3y]);
			this.cliarr.push([btnn4x, btnn4y]);
			this.cliarr.push([btnn5x, btnn5y]);
			this.cliarr.push([btnn6x, btnn6y]);
			var canvas = document.getElementById("canvas");

			canvas.width = document.documentElement.clientWidth;
			canvas.height = document.documentElement.clientHeight;
			if (canvas.getContext("2d")) {
				var context = canvas.getContext("2d");
				// 应用context绘制
				// 从坐标(100,100)为终点开始
				context.moveTo(this.x1, this.y1);
				// 到坐标(700,700)为起点画一条直线
				for (let i = 0; i < this.cliarr.length; i++) {
					context.lineTo(this.cliarr[i][0], this.cliarr[i][1]);
				}

				// context.lineTo(100, 700)
				// 线宽为5
				context.lineWidth = 5;
				// 线的款式色彩
				context.strokeStyle = "#fff";
				// 执行划直线这个操作
				context.stroke();
			} else {
				alert("当前浏览器不支持canvas，请更换浏览器后重试");
			}
		},
		gonext() {
			this.$router.push("/lesson3/page-3");
		},
		mousedown1(e) {
			// var cas = document.getElementById('cas');
			// var context = cas.getContext('2d');
			// context.lineTo(100,100)
			if (this.x1 == 0) {
				this.x1 = e.clientX;
				this.y1 = e.clientY;
			}
			// let img = document.getElementsByClassName('img');
			// console.log('img', img.getClientRects);
		},
		mouseup1(e) {
			// let x1 = this.x1;
			// let y1 = this.y1;
			// let x2 = e.clientX;
			// let y2 = e.clientY;
			this.cliarr.push([e.clientX, e.clientY]);
			var canvas = document.getElementById("canvas");

			canvas.width = document.documentElement.clientWidth;
			canvas.height = document.documentElement.clientHeight;
			if (canvas.getContext("2d")) {
				var context = canvas.getContext("2d");
				// 应用context绘制
				// 从坐标(100,100)为终点开始
				context.moveTo(this.x1, this.y1);
				// 到坐标(700,700)为起点画一条直线
				for (let i = 0; i < this.cliarr.length; i++) {
					context.lineTo(this.cliarr[i][0], this.cliarr[i][1]);
				}

				// context.lineTo(100, 700)
				// 线宽为5
				context.lineWidth = 5;
				// 线的款式色彩
				context.strokeStyle = "#fff";
				// 执行划直线这个操作
				context.stroke();
			} else {
				alert("当前浏览器不支持canvas，请更换浏览器后重试");
			}
		},
		starts() {
			// console.log('1');
			// let src = require("@/assets/music/s.mp3")
			// this.audio.src = src
			// this.$refs.audioTip.load();
			// this.$refs.audioTip.play();
		},
		startm() {
			// console.log('1');
			// let src = require("@/assets/music/m.mp3")
			// this.audio.src = src
			// this.$refs.audioTip.load();
			// this.$refs.audioTip.play();
		},
	},
};
</script>

<style scoped>
.imgg1 {
	width: 167px;
	height: 89px;
	margin-bottom: 20px;
}

.contbotoom {
	position: absolute;
	bottom: 100px;
	/* margin-top: 100px; */
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9;
}

.custom-btn {
	background-image: url("~@/assets/image/custom-btn01.png");
	background-repeat: no-repeat;
	background-size: 100%;
	width: 145px;
	height: 49px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-size: 23px;
}

.custom-btn:nth-child(2) {
	margin: 0 70px;
}

.bbtn6 {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: #fff;
	margin-top: 10px;
}

.bbtn5 {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: #fff;
	margin-top: 120px;
}

.bbtn4 {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: #fff;
	margin-top: 10px;
}

.bbtn3 {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: #fff;
	margin-top: 10px;
}

.bbtn2 {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: #fff;
	margin-top: 120px;
}

.bbtn1 {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: #fff;
	margin-top: 120px;
}

.canvas {
	width: 1000px;
	height: 400px;
}

.img2 {
	width: 83px;
	height: 167px;
}

.img1 {
	width: 167px;
	height: 167px;
}

.img3 {
	width: 167px;
	height: 167px;
}

.common-page {
	background-image: url("~@/assets/image/back04.png");
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
}

.con1 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	position: relative;
}

.btn {
	margin-right: 45px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.btn2 {
	position: relative;
	margin-right: 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.btn1 {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 45px;
	justify-content: flex-start;
}

.btn img:nth-child(2),
.btn1 img:nth-child(2),
.btn2 img:nth-child(2) {
	position: relative;
	z-index: 20;
	cursor: pointer;
}
</style>
