<template>
	<div class="music-web">
        <!-- 顶部logo -->
        <div class="login-logo">
            <img src="@/assets/login/logo.png" alt="">
        </div>
        <div class="login-box">
            <div class="login-box-bg">
                <img src="@/assets/login/login_box_bg.png" alt="">
                <input class="login-name" v-model="name" type="text" placeholder="账号">
                <input class="login-password" v-model="password" 
                onkeyup="this.value=this.value.replace(/[^a-zA-Z0-9_]/g,'')" 
                type="password" placeholder="密码">
            </div>
            <img class="login-btn" @click="handleLogin" src="@/assets/login/login_btn.png" alt="">
        </div>
	</div>
</template>
<script>
import {musicAuthLoginAPI} from "@/api/login"
import { setUser,getUser } from "@/utils/auth.js";
export default {
	data() {
		return {
            name:'',
            password:'',
		};
	},
    created(){
        if(getUser().token){
            this.$router.push('/lesson1/page-home')
        }else{
            console.log(this.$route.query);
            if(this.$route.query && this.$route.query.type === '1'){
                this.$message({
					message: '用户未登录或登录已失效',
					type: 'error'
				});
            }
            
        }
    },
	methods: {
        handleLogin(){
            musicAuthLoginAPI({
                name:this.name,
                passwd:this.password
            }).then(res => {
                if(res.data.code === '200'){
                    setUser(
                        res.data.data.user.id,
                        res.data.data.token,
                        res.data.data.user.password,
                        res.data.data.user.name,
                    )
                    this.$router.push('/lesson1/page-home')
                }else{
                    this.$message({
                    message: res.data.msg,
                    type: 'error'
                    });
                }

                console.log(res.data);
            })
            
        },
	},
};
</script>
<style lang="scss">
.music-web{
    width: 100%;
    height: 100vh;
    background: url(../assets/login/bg.png) no-repeat;
    background-size:100%;
    .login-logo{
        height: 88px;
        background-color: rgba($color: #fff, $alpha: 0.4);
        position: relative;
        img{
            margin-left: 30px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .login-box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        .login-box-bg{
            position: relative;
            img{
                width: 733px;
                height: 661px;
            }
            .login-name,.login-password{
                position: absolute;
                left:190px;
                width: 350px !important;
                height: 60px !important;
                border: none;
                background-color: transparent;
                font-size: 24px;
                &[type=text]{
                    color: #fff;
                }
                &[type=password]{
                    color: #fff;
                }
                &::-webkit-input-placeholder { 
                    /* WebKit browsers，webkit内核浏览器 */
                    color: #fff; 
                } 
                &:focus{
                    // outline:1px solid #fff;
                    outline:none;
                    color: #fff;
                }
            }
            .login-name{
                top: 295px;
            }
            .login-password{
                top: 395px;
            }
        }
        .login-btn{
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            width: 308px;
            height: 115px;
        }
        
    }
}
</style>
