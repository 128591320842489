const pageBg = require("@/views/lesson15/images/bg.png");
const startPageBg = require("@/views/lesson15/images/bg_blur.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "28vw",
				bottom: "12vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${52.778 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "10vw",
				bottom: "20vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${35 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "5vw",
				bottom: "7vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${60 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

export default {
	lessonStart: {
		// 首页
		pageBg: startPageBg, // 首页背景
		pageTitleImage: require("@/views/lesson15/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson15/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson15/game-1",
		roles: roles(1.1, [3], {
			3: {
				roleClass: "worm",
				imgSrc: require("../images/树枝@2x.png"),
				style: {
					width: "auto",
					right: "0",
					bottom: "50vh",
					zIndex: 9,
				},
				roleStyle: {
					height: `${24 * 1.1}vh`,
				},
			},
		}),
		audio: {
			src: require("../audio/旁白01.mp3"),
			autoplay: true,
		},
		talkList: [
			{ audio: require("../audio/旁白02-第四天早上.mp3") },
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "咦，快起来！蚕蛹不见了！",
				audio: require("../audio/宫1.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "去哪里了？都怪我，居然睡着了。",
				audio: require("../audio/魔法师01.mp3"),
			},
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "你们听，有人在唱歌。",
				audio: require("../audio/骑士01.mp3"),
			},
			{ audio: require("../audio/旁白03-大家也跟着唱起来.mp3") },
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson15/page?page=3",
		audio: {
			src: require("../audio/旁白04-一只漂亮的蝴蝶唱着歌向大家飞来.mp3"),
			autoplay: true,
		},
		audioPlayer: {
			src: require("../audio/蝴蝶夫人-晴朗的一天.mp3"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "7vh",
			},
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/butterfly_lady@2x.png"),
				style: {
					position: "absolute",
					width: "56vw",
					bottom: "6vh",
					left: "22vw",
				},
			},
		],
	},
	3: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson16/page",
		roles: roles(1.1),
		isEnd: true,
		talkList: [
			{
				talker: "butterfly",
				talkerCHN: "蝴蝶夫人",
				content:
					"你们睡醒了？谢谢你们守护我这么久。我就是你们要找的魔法学院的院长蝴蝶夫人。每过一段时间我都要经历一次这样的蜕变。你们已经证明了自己是诚实善良信守诺言的人。我为你们献上祝福，",
				audio: require("../audio/蝴蝶1-01.mp3"),
			},
			{
				isBreak: true,
				callback: (vm) => {
					vm.animalDiamond = require("../images/祝福-蝴蝶夫人.png");
					vm.showMagicBook = true;
				},
			},
			{
				talker: "butterfly",
				talkerCHN: "蝴蝶夫人",
				content:
					"记住这蜕变的经历，在不起眼的生物都有可能绽放出璀璨的力量。你们和我们来吧，我邀请你们一起去魔法学院学习高级的魔法。",
				audio: require("../audio/蝴蝶1-02.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/butterfly_lady@2x.png"),
				style: {
					position: "absolute",
					width: "44vw",
					bottom: "30vh",
					right: "0",
				},
			},
		],
	},
};
