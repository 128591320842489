const pageBg = require("@/views/lesson11/images/bg.png");
const startPageBg = require("@/views/lesson11/images/bg_blur.png");
const pageNextBtnImage = require("@/assets/image/next.png");
const roles = (scale) => [
	{
		roleClass: "musician",
		imgSrc: require("@/assets/image/role/musician.png"),
		style: {
			width: "auto",
			left: "50vw",
			bottom: "10vh",
			zIndex: 8,
		},
		roleStyle: {
			height: `${52.778 * scale}vh`,
			transform: "rotateY(180deg)",
		},
	},
	{
		roleClass: "magician",
		imgSrc: require("@/assets/image/role/magician.png"),
		style: {
			width: "auto",
			left: "27vw",
			bottom: "18vh",
			zIndex: 7,
		},
		roleStyle: {
			height: `${35 * scale}vh`,
		},
	},
	{
		roleClass: "knight",
		imgSrc: require("@/assets/image/role/knight.png"),
		style: {
			width: "auto",
			left: "20.5vw",
			bottom: "1vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${60 * scale}vh`,
			transform: "rotateY(180deg)",
		},
	},
	{
		roleClass: "goose",
		imgSrc: require("@/assets/image/role/goose.png"),
		style: {
			width: "auto",
			left: "70vw",
			bottom: "2vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${35 * scale}vh`,
		},
	},
];
export default {
	lessonStart: {
		// 首页
		pageBg: startPageBg, // 首页背景
		pageTitleImage: require("@/views/lesson11/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson11/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg: require("../images/bg_train.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson11/page?page=2",
		audio: {
			src: require("../audio/旁白1.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"这是怎么回事啊？火车怎么突然停了下来。我们过去看看。好像有人破坏了火车的轮子。",
				audio: require("../audio/宫廷1.mp3"),
			},
			{
				audio: require("../audio/独白3.mp3"),
			},
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "我可是名侦探大鹅。最擅长的就是推理。",
				audio: require("../audio/大鹅1.mp3"),
			},
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "我给你们自我介绍一下",
				audio: require("../audio/大鹅2.mp3"),
			},
		],
	},
	2: {
		pageBg: startPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson11/page?page=3",
		otherElement: [
			{
				type: "img",
				src: require("../images/goose.png"),
				style: {
					position: "absolute",
					height: "100vh",
					bottom: "0",
					left: "0",
				},
			},
			{
				type: "html",
				style: {
					fontWeight: "bold",
					color: "#A64907",
					position: "absolute",
					width: "50vw",
					bottom: "1vh",
					left: "38vw",
				},
				html: `
					<img src="${require("../images/paper.png")}" style="width: 100%">
					<div style="position:absolute;left:50%;top:50%;font-size:3vw;line-height:4.2vw;transform:translate(-46%, -65%);white-space: nowrap;letter-spacing:5px">
						<div>名<span style="opacity: 0">一 </span>侦<span style="opacity: 0">一 </span>探大 鹅，</div>
						<div>智勇 无双 鹅<span style="opacity: 0">一二 </span>。</div>
						<div>白羽 红掌 智商 高，</div>
						<div>逻辑 推理 最擅 长。</div>
						<div>明枪 暗箭 都能 挡，</div>
						<div>一身 才华 无处 放啊，</div>
						<div>无 处 放 ～</div>
					</div>
				`,
			},
		],
	},
	3: {
		pageBg: startPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson11/page?page=4",
		otherElement: [
			{
				type: "img",
				src: require("../images/goose.png"),
				style: {
					position: "absolute",
					height: "100vh",
					bottom: "0",
					left: "0",
				},
			},
			{
				type: "img",
				src: require("../images/paper.png"),
				style: {
					position: "absolute",
					width: "50vw",
					bottom: "1vh",
					left: "38vw",
				},
			},
			{
				type: "img",
				src: require("../images/notes.png"),
				style: {
					position: "absolute",
					width: "25vw",
					bottom: "24vh",
					left: "48.5vw",
				},
			},
			{
				type: "img",
				src: require("../images/hammer.png"),
				style: {
					position: "absolute",
					width: "13.4vw",
					bottom: "60vh",
					left: "75vw",
				},
			},
			{
				type: "img",
				src: require("../images/drum.png"),
				style: {
					position: "absolute",
					width: "12.3vw",
					bottom: "8vh",
					left: "77vw",
				},
			},
		],
	},
	4: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson11/game-1",
		roles: roles(1.1),
		talkList: [
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "让我来推理出谁是破坏火车的嫌疑人",
				audio: require("../audio/大鹅3.mp3"),
			},
			{
				audio: require("../audio/独白4.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你不是名侦探大鹅吗？有推理出凶手了吗？",
				audio: require("../audio/宫廷2.mp3"),
			},
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content:
					"嗯……我虽然是名侦探大鹅。但是凶手用魔法掩盖了线索。没有线索，我也无法推理出答案呀。你们能帮我找到线索吗？",
				audio: require("../audio/大鹅4.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"好吧好吧。我试试看。我这里有一些魔法铅笔。只要在恰当的位置画下正确的魔法符号，便可以让线索显形。",
				audio: require("../audio/宫廷3.mp3"),
			},
		],
	},
	5: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson11/game-3",
		roles: roles(1.1),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "魔咒啊，魔咒。告诉我，在车轮旁边最后看到的人是谁。",
				audio: require("../audio/宫廷4.mp3"),
			},
		],
	},
	6: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson12/page",
		roles: roles(1.1),
		isEnd: true,
		talkList: [
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content:
					"果然是只臭鼬干的。他就和我在一个车厢，我早就怀疑他了。谢谢你们帮我找到了嫌疑人。",
				audio: require("../audio/大鹅5.mp3"),
			},
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "我把祝福送给你们，当做我的谢礼。",
				audio: require("../audio/大鹅6.mp3"),
			},
			{
				isBreak: true,
				callback: (vm) => {
					vm.animalDiamond = require("../images/大鹅的祝福.png");
					vm.showMagicBook = true;
				},
			},
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "现在火车坏了，我们不如一同下车去追这只臭鼬。",
				audio: require("../audio/大鹅7.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "好吧。我们就一同前去。我也担心你一个人应付不来。",
				audio: require("../audio/宫廷5.mp3"),
			},
		],
	},
};
