<template>
	<div
		class="window-container"
		:style="{ backgroundImage: `url(${door})` }"
	>
		<div
			class="door"
			@click="openMusicScore"
		></div>
		<template v-if="show">
			<div
				class="window-container note"
				:style="{ backgroundImage: `url(${image})` }"
			></div>
			<div
				class="window-close"
				@click="close"
			></div>
			<div class="custom-audio">
				<audio
					ref="audioPlayer"
					:src="audio"
					autoplay
					controls
				></audio>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		index: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			show: false,
			audio: "",
			image: "",
			door: "",
		};
	},
	watch: {
		index: {
			handler() {
				this.door = require("../images/door.gif") + "?" + Math.random();
			},
			immediate: true,
		},
	},
	methods: {
		openMusicScore() {
			this.image = require(`@/views/lesson16/images/notes/${this.index}.png`);
			this.audio = require(`@/views/lesson16/audio/notes/${this.index}.mp3`);
			this.show = true;
		},
		close() {
			this.$emit("close");
		},
	},
};
</script>

<style lang="scss" scoped>
.window-container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba($color: #000000, $alpha: 0.5);
	background-repeat: no-repeat;
	background-size: 100vw 100vh;
	background-position: center center;
	.door {
		position: absolute;
		width: 20vw;
		height: 50vh;
		margin-left: 100px;
		cursor: pointer;
	}
	.note {
		background-color: rgba($color: #000000, $alpha: 0.5);
		background-image: unset;
		background-size: 70vw auto;
		background-position: center 10vh;
		z-index: 13;
	}
	.custom-audio {
		position: absolute;
		left: 50%;
		bottom: 50px;
		transform: translateX(-50%);
		z-index: 14;
	}
	.window-close {
		position: absolute;
		opacity: 0.7;
		width: 44px;
		height: 44px;
		right: 19.5vw;
		top: 15.5vh;
		z-index: 14;
		background: url("~@/views/lesson16/images/notes/close.png") no-repeat;
		background-size: 44px auto;
		cursor: pointer;
	}
}
</style>
