import Cookies from 'js-cookie'
const Token = 'User‐Token'
const Passwd = 'User‐passwd'
const Id = 'User‐Id'
const Name = 'User‐Name'

export function setUser(id, token, passwd, name) {
    Cookies.set(Id, id, { expires: 1 / 6 })
    Cookies.set(Token, token, { expires: 1 / 6 })
    Cookies.set(Passwd, passwd, { expires: 1 / 6 })
    Cookies.set(Name, name, { expires: 1 / 6 })

}


export function getUser() {
    return {
        id: Cookies.get(Id),
        token: Cookies.get(Token),
        passwd: Cookies.get(Passwd),
        name: Cookies.get(Name),

    }
}

export function removeUser() {
    Cookies.remove(Id)
    Cookies.remove(Token)
    Cookies.remove(Passwd)
    Cookies.remove(Name)

}