<template>
	<div
		class="common-page"
		@mousemove="move"
		@mouseup="end"
	>
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson23/page?page=5')"
			class="next-btn"
		/>

		<div class="staff-box">
			<img
				class="high"
				src="./images/notes/高音谱号@2x.png"
			/>
			<img
				class="staff"
				src="./images/notes/五线谱@2x.png"
			/>
			<div class="wrap">
				<div
					class="item"
					v-for="(item, index) in list"
					:key="`ex_${index}_${item.key}}`"
					:style="{ marginTop: item.exMarginTop }"
				>
					<div class="top note-box">
						<img
							class="note"
							style="opacity: 0.08"
							:src="item.note"
							:data-type="`note_${item.key}`"
						/>
					</div>
					<div class="bottom letter-box">
						<img
							class="letter"
							style="opacity: 0"
							:src="item.letter"
							:data-type="`letter_${item.key}`"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="lenged">
			<div
				class="item"
				v-for="(item, index) in list"
				:key="`lenged_${index}_${item.key}`"
			>
				<div class="wrap">
					<div class="wrap_top">
						<img
							class="rock"
							:src="item.stone"
						/>
						<img
							class="note"
							:src="item.note"
							:data-type="`note_${item.key}`"
							@mousedown="start"
						/>
					</div>
					<div class="wrap_bottom">
						<img
							class="letter"
							:src="item.letter"
							:data-type="`letter_${item.key}`"
							@mousedown="start"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { gsap } from "gsap";
class CreateNode {
	constructor(key, index) {
		this.key = key;
		this.note = require(`./images/notes/note_${key}@2x.png`);
		this.letter = require(`./images/notes/letter_${key}@2x.png`);
		this.exMarginTop = (index < 8 ? 7 - index : index - 7) * 1.96 + "vh";
		this.stone = require(`./images/notes/${
			index % 2 === 0 ? "stoneandline" : "stone"
		}@2x.png`);
	}
}
export default {
	data() {
		return {
			list: [],
			rect: [],
			isMove: false,
			node: null,
			offset: {},
		};
	},
	created() {
		const notes = [
			"c",
			"d",
			"e",
			"f",
			"g",
			"a",
			"b",
			"c",
			"b",
			"a",
			"g",
			"f",
			"e",
			"d",
			"c",
		];
		for (const index in notes) {
			this.list.push(new CreateNode(notes[index], index));
		}
	},
	methods: {
		start(e) {
			this.rect = [];
			this.isMove = true;
			const [type, key] = e.target.dataset.type.split("_");
			this.cloneNode(e, type);
			const list = document.querySelectorAll(`.staff-box .${type}`);
			const hash = [];
			for (const node of list) {
				const result = node.getAttribute("data-type");
				if (result === `${type}_${key}`) {
					hash.push(node);
				}
			}
			hash.forEach((node) => {
				const { top, left, right, bottom } = node.getBoundingClientRect();
				this.rect.push({
					top,
					left,
					right,
					bottom,
					node,
					type,
				});
			});
		},
		cloneNode(e, type) {
			const { offsetX, offsetY, clientX, clientY } = e;
			this.offset = {
				x: offsetX,
				y: offsetY,
			};
			this.node = e.target;
			if (!this.node.getAttribute("x")) {
				const result = this.node.getBoundingClientRect();
				this.node.setAttribute("x", result.left);
				this.node.setAttribute("y", result.top);
			}
			this.node.classList.add(`clone-${type}`);
			this.node.onmousedown = (event) => {
				this.isMove = true;
				this.node = event.target;
				this.offset = {
					x: event.offsetX,
					y: event.offsetY,
				};
			};
			this.node.style.cssText = `left: ${clientX - offsetX}px;top: ${
				clientY - offsetY
			}px`;
			const father = document.querySelector(".common-page");
			father.appendChild(this.node);
		},
		move(e) {
			if (this.isMove) {
				const { clientX, clientY } = e;
				this.node.style.cssText = `left: ${clientX - this.offset.x}px;top: ${
					clientY - this.offset.y
				}px`;
			}
		},
		end() {
			this.isMove = false;
			const { x, y, width, height } = this.node.getBoundingClientRect();
			const ox = x + width / 2;
			const oy = y + height / 2;
			for (const { top, right, bottom, left } of this.rect) {
				if (left <= ox && right >= ox && top <= oy && bottom >= oy) {
					this.node.style.cssText = `left: ${left}px;top: ${top}px`;
					return;
				}
			}
			gsap.to(this.node, {
				left: this.node.getAttribute("x"),
				top: this.node.getAttribute("y"),
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson23/images/notes/bg@2x.png");
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.clone-note {
		height: 5vh;
		position: absolute;
		z-index: 15;
		cursor: pointer;
	}

	.clone-letter {
		height: 5.5vh;
		position: absolute;
		z-index: 15;
		cursor: pointer;
	}

	.staff-box {
		position: absolute;
		top: 20vh;
		margin-left: 2vw;
		.high {
			position: absolute;
			height: 30vh;
			top: -9vh;
			left: 1vw;
		}
		.staff {
			width: 79vw;
			height: 20vh;
		}
		.wrap {
			position: absolute;
			top: 3.5vh;
			left: 8.5vw;
			right: 2.8vw;
			display: flex;
			justify-content: space-between;
			.item {
				width: calc(100% / 15);
				height: 20vh;
				.top {
					width: 100%;
					height: 5vh;
					display: flex;
					align-items: flex-start;
					justify-content: center;
					.note {
						height: 5vh;
						position: absolute;
						cursor: pointer;
					}
				}
				.bottom {
					width: 100%;
					height: 6vh;
					display: flex;
					align-items: center;
					justify-content: center;
					.letter {
						height: 5.5vh;
						position: absolute;
						cursor: pointer;
					}
				}
			}
		}
	}
	.lenged {
		position: absolute;
		bottom: 7vh;
		width: 70vw;
		display: flex;
		flex-wrap: wrap;

		.item {
			width: calc(100% / 8);
			padding: 1vw 1vh;
			padding-top: 0.5vh;
			flex-shrink: 0;
			box-sizing: border-box;
			.wrap {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				&_top {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					.rock {
						width: 5vw;
					}
					.note {
						// width: 3.7vw;
						height: 5vh;
						position: absolute;
						cursor: pointer;
					}
				}
				&_bottom {
					width: 100%;
					height: 7vh;
					display: flex;
					align-items: center;
					justify-content: center;
					.letter {
						height: 5.5vh;
						position: absolute;
						cursor: pointer;
					}
				}
			}
		}
	}
}
</style>
