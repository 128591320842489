<template>
	<div
		class="common-page"
		id="game-page-wrapper"
	>
		<svg
			:viewbox="[0, 0, screenWidth, screenHeight].join(' ')"
			class="drawer-pannel"
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
		>
			<polyline
				:points="transformSvgRatio1.join(' ')"
				style="fill: none; stroke: #fff; stroke-width: 8"
			/>
		</svg>
		<svg
			:viewbox="[0, 0, screenWidth, screenHeight].join(' ')"
			class="drawer-pannel"
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
		>
			<polyline
				:points="transformSvgRatio2.join(' ')"
				style="fill: none; stroke: #fff; stroke-width: 8"
			/>
		</svg>
		<svg
			:viewbox="[0, 0, screenWidth, screenHeight].join(' ')"
			class="drawer-pannel"
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
		>
			<polyline
				:points="transformSvgRatio3.join(' ')"
				style="fill: none; stroke: #fff; stroke-width: 8"
			/>
		</svg>
		<img
			class="next-btn"
			src="@/assets/image/next.png"
			@click.stop="gonext()"
		/>
		<div
			class="center-img game1"
			@click.stop="(e) => svgClicked(e, 0)"
		>
			<img
				class="game-bg"
				src="./images/L7_stave_clawprint_small_bg.png"
			/>
			<img
				src="./images/L7_stave_clawprint_small_1.png"
				alt=""
				srcset=""
			/>
		</div>

		<div
			class="center-img game2"
			@click.stop="(e) => svgClicked(e, 1)"
		>
			<img
				class="game-bg"
				src="./images/L7_stave_clawprint_small_bg.png"
			/>
			<img
				src="./images/L7_stave_clawprint_small_2.png"
				alt=""
				srcset=""
			/>
		</div>

		<div
			class="center-img game3"
			@click.stop="(e) => svgClicked(e, 2)"
		>
			<img
				class="game-bg"
				src="./images/L7_stave_clawprint_small_bg.png"
			/>
			<img
				src="./images/L7_stave_clawprint_small_3.png"
				alt=""
				srcset=""
			/>
		</div>
		<div class="bottom-btns">
			<div
				class="custom-btn"
				@click="redo()"
			>
				撤回
			</div>
			<div
				class="custom-btn"
				@click="doRestore()"
			>
				清屏
			</div>
			<div
				class="custom-btn"
				@click="showAnswer()"
			>
				查看答案
			</div>
		</div>
	</div>
</template>

<script>
import { DrawLine } from "@/DrawLine";
export default {
	components: {},
	data() {
		return {
			drawLine: new DrawLine(),
			svgPaths: [[], [], []],
			historySvgPaths: [],
			isStartRedo: false,
			screenWidth: 0,
			screenHeight: 0,
			answer: [
				[
					[0.27697262479871176, 0.24332977588046958],
					[0.3408480944712829, 0.16435432230522945],
					[0.39989264626945786, 0.10779082177161152],
					[0.4643048845947397, 0.2486659551760939],
					[0.5281803542673108, 0.17502668089647813],
					[0.5861513687600645, 0.11632870864461047],
					[0.6521739130434783, 0.26040554962646745],
				],
				[
					[0.2683843263553409, 0.423692636072572],
					[0.3247450348899624, 0.48452508004268946],
					[0.3784219001610306, 0.5709711846318036],
					[0.43531937734836285, 0.4791889007470651],
					[0.4895330112721417, 0.4279615795090715],
					[0.5475040257648953, 0.4759871931696905],
					[0.6038647342995169, 0.5624332977588047],
					[0.6602254428341385, 0.48025613660619],
				],
				[
					[0.26516371443907677, 0.7961579509071505],
					[0.333870101986044, 0.7353255069370331],
					[0.3988191089640365, 0.8783351120597652],
					[0.4669887278582931, 0.7950907150480256],
					[0.5292538915727322, 0.7406616862326574],
					[0.5942028985507246, 0.7950907150480256],
					[0.6602254428341385, 0.7897545357524013],
				],
			],
		};
	},
	computed: {
		transformSvgRatio1() {
			let { screenHeight, screenWidth } = this;
			let pointsArr = this.svgPaths[0].map((point) => {
				let [xRatio, yRatio] = point;
				// 按比例缩计算实际坐标，实现缩放屏幕后连线准确
				return [xRatio * screenWidth, yRatio * screenHeight].join(",");
			});
			return pointsArr;
		},
		transformSvgRatio2() {
			let { screenHeight, screenWidth } = this;
			let pointsArr = this.svgPaths[1].map((point) => {
				let [xRatio, yRatio] = point;
				// 按比例缩计算实际坐标，实现缩放屏幕后连线准确
				return [xRatio * screenWidth, yRatio * screenHeight].join(",");
			});
			return pointsArr;
		},
		transformSvgRatio3() {
			let { screenHeight, screenWidth } = this;
			let pointsArr = this.svgPaths[2].map((point) => {
				let [xRatio, yRatio] = point;
				// 按比例缩计算实际坐标，实现缩放屏幕后连线准确
				return [xRatio * screenWidth, yRatio * screenHeight].join(",");
			});
			return pointsArr;
		},
	},
	mounted() {
		let _this = this;
		let dom = document.querySelector("#game-page-wrapper");
		// eslint-disable-next-line no-unused-vars
		const resizeObserver = new ResizeObserver((entries) => {
			let width = getComputedStyle(dom).getPropertyValue("width");
			let height = getComputedStyle(dom).getPropertyValue("height");
			if (width === _this.screenWidth && height === _this.screenHeight) return;

			_this.screenWidth = parseInt(width);
			_this.screenHeight = parseInt(height);
		});

		resizeObserver.observe(dom);
		this.$once("hook:destroy", () => {
			// 取消监听
			resizeObserver.disconnect();
		});
	},
	beforeDestroy() {
		this.drawLine.destroy();
		if (this.observer) {
			this.observer.disconnect();
			this.observer.takeRecords();
			this.observer = null;
		}
	},
	methods: {
		gonext() {
			this.$router.push("/lesson7/page?page=6");
		},
		svgClicked(e, index) {
			this.isStartRedo = false;
			let { clientX: x, clientY: y } = e;
			let xRatio = x / this.screenWidth;
			let yRatio = y / this.screenHeight;
			// 只保存比例，便于在屏幕缩放后重绘
			this.svgPaths[index].push([xRatio, yRatio]);
			this.historySvgPaths.push(JSON.stringify(this.svgPaths));
		},
		doRestore() {
			this.drawLine.clean();
			this.isStartRedo = false;
			this.svgPaths = [[], [], []];
		},
		redo() {
			this.drawLine.back();
			if (!this.historySvgPaths.length) {
				this.doRestore();
			} else {
				if (!this.isStartRedo) {
					// 为true 表示不是第一次点击
					// 需要手动将数组末尾的数据清除掉，再执行后续否则操作
					// 否则第一次点击撤销会不生效
					this.isStartRedo = true;
					this.historySvgPaths.pop();
				}
				let pre = this.historySvgPaths.pop();
				this.svgPaths = JSON.parse(pre);
			}
		},
		showAnswer() {
			this.drawLine.clean();
			this.svgPaths = JSON.parse(JSON.stringify(this.answer));
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("./images/bg_L7_start.jpg");
}

.center-img {
	position: absolute;
	left: 50%;
	top: 34px;
	width: 65vw;
	height: 29.63vh;
	transform: translateX(-50%);
	z-index: 10;
	padding: 20px 40px;
	box-sizing: border-box;
	overflow: hidden;

	.game-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		pointer-events: none;
	}

	img {
		height: 100%;
		pointer-events: none;
	}

	&.game2 {
		top: 34.63vh;
	}

	&.game3 {
		top: 66.111vh;
	}
}

.drawer-pannel {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
}

.bottom-btns {
	position: absolute;
	right: 40px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;

	.custom-btn {
		& + .custom-btn {
			margin-top: 100px;
		}
	}
}
</style>
