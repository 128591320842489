const pageBg = require("@/views/lesson14/images/bg.png");
const startPageBg = require("@/views/lesson14/images/bg_blur.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "28vw",
				bottom: "12vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${52.778 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "10vw",
				bottom: "20vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${35 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "5vw",
				bottom: "7vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${60 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "worm",
			imgSrc: require("@/assets/image/role/worm.png"),
			style: {
				width: "auto",
				right: "0",
				bottom: "15vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

export default {
	lessonStart: {
		// 首页
		pageBg: startPageBg, // 首页背景
		pageTitleImage: require("@/views/lesson14/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson14/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson14/game-1",
		roles: roles(1.1),
		audio: {
			src: require("../audio/旁1.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"你们看前面有一只奇怪的大虫子。这么大的虫子，我还是第一次见到。",
				audio: require("../audio/宫1.mp3"),
			},
			{
				talker: "worm",
				talkerCHN: "虫子",
				content:
					"你们好，很奇怪吧，这里会有这么大一只虫子。我是来这里采集花蜜的。",
				audio: require("../audio/虫1.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "我确实没见过你这么大的虫子，虫子也需要吃花蜜吗？",
				audio: require("../audio/宫2.mp3"),
			},
			{
				talker: "worm",
				talkerCHN: "虫子",
				content:
					"我可不是一般的虫子，这里的花蜜充满了魔法的精华，所以我才可以长的这么大。你们可以帮我采集一些花蜜吗？",
				audio: require("../audio/虫2.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"好吧，我们一路远道而来。这里风景优美，逛逛也不错，就顺便帮你采集一些花蜜吧。",
				audio: require("../audio/宫3.mp3"),
			},
			{
				talker: "worm",
				talkerCHN: "虫子",
				content:
					"谢谢你们，不过只有特定的花蜜才有魔法的能量，你们可不要采错了。",
				audio: require("../audio/虫3.mp3"),
			},
			{
				audio: require("../audio/旁2.mp3"),
			},
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson14/game-3",
		roles: roles(1.1),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "采好了，你看看这是正确的花蜜吗？",
				audio: require("../audio/宫4.mp3"),
			},
			{
				talker: "worm",
				talkerCHN: "虫子",
				content:
					"谢谢你们，这正是我要的。看来你们很有魔法天赋，一般人是很难分辨出来的。",
				audio: require("../audio/虫4.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"很高兴可以帮助你，不过我们也要赶路了，我们要去前面的魔法学院。",
				audio: require("../audio/宫5.mp3"),
			},
			{
				talker: "worm",
				talkerCHN: "虫子",
				content:
					"哦？你们要去魔法学院吗？那你们还要帮我一个忙，我一会儿会变成一个蚕蛹，可能会睡上一段时间，你们要保护我。如果你们帮我的话，我就替你们引荐魔法学院的院长。",
				audio: require("../audio/虫5.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "那太好了，你安心睡吧，这段时间我们来保护你。",
				audio: require("../audio/宫6.mp3"),
			},
			{
				audio: require("../audio/旁3.mp3"),
			},
		],
	},
	3: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson15/page",
		roles: roles(1.1, [3], {
			3: {
				roleClass: "worm",
				imgSrc: require("../images/碟蛹@2x.png"),
				style: {
					width: "auto",
					right: "0",
					bottom: "16vh",
					zIndex: 9,
				},
				roleStyle: {
					height: `${60 * 1.1}vh`,
				},
			},
		}),
		isEnd: true,
		audio: {
			src: require("../audio/旁4.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "看来我们要在这里等上一段时间了。",
				audio: require("../audio/宫7.mp3"),
			},
		],
	},
};
