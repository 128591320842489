<template>
  <div class="common-page"  @click="changetalk(1)">
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />
    <Magic v-if="openmagic"></Magic>
    <audio
      ref="audioTip"
      :src="audio.src"
      @ended="isTalking = false"
      style="display: none"
    ></audio>
    <div class="musician-box">
      <img src="@/assets/image/role/musician.png" class="musician" />
    </div>

    <div class="magician-box">
      <img src="@/assets/image/role/magician.png" class="magician" />
    </div>

    <div class="knight-box">
      <img src="@/assets/image/role/knight.png" class="knight" />
    </div>
    <div class="shimengmo-box">
      <img src="@/assets/image/role/shimengmo.png" class="shimengmo" />
    </div>
    <ChatDialog
      :talkList="talkList"
      :talkIndex="talkIndex"
      @audioStateChange="(state) => (isTalking = state)"
    ></ChatDialog>
  </div>
</template>

<script>
const Magic = () =>
  import(/* webpackChunkName:"magic-animation" */ "@/components/Magic/index.vue");
export default {
  data() {
    return {
      talkList: [
        {
          talker: "shimengmo",
          talkerCHN: "食梦貘",
          content:
            "看来不行的。你们人类有两条腿，而我有四条腿，这种跑步对我来说简直就是家常便饭。",
          audio: require("@/assets/audio/lesson4/page3-shimengmo-voice1.mp3"),
        },
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content: "我有一个好办法，可以试一下，不如我们为他演奏一下摇篮曲。",
          audio: require("@/assets/audio/lesson4/page3-musician-voice1.mp3"),
        },
      ],
      talkIndex: -1,
      isTalking: true,
      openmagic: false,
      audio: {
        // 消息通知
        src: require("@/assets/audio/lesson4/page3-aside-voice1.mp3"),
      },
    };
  },
  components: {
    Magic,
  },
  mounted() {
    this.$refs.audioTip.play();
  },
  methods: {
    gonext() {
      this.$router.push("/lesson4/game-6");
    },
    changetalk(val) {
      if (this.isTalking) {
        // 正在读旁白或者正在播放对话内容
        return;
      }
      this.talkIndex += val;
      this.isTalking = true;
    },
  },
};
</script>

<style scoped>
.common-page {
  background-image: url("~@/assets/image/back04.png");
}
.magician-box {
  left: 620px;
  bottom: 260px;
  z-index: 7;
}
.knight-box {
  left: 900px;
  bottom: 120px;
  z-index: 8;
}
.musician-box {
  left: 220px;
  bottom: 140px;
  z-index: 9;
}
.shimengmo-box {
  left: 560px;
  bottom: 120px;
}
</style>