<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson19/page?page=3')"
			class="next-btn"
		/>
		<div
			class="item"
			:class="{ scale: item.scale }"
			v-for="(item, index) in list"
			:key="index"
			:style="item.style"
			@click="toggle(index)"
		>
			<img
				class="item-fish"
				src="./images/fish/fish.png"
			/>
			<img
				v-if="item.node"
				class="item-node"
				:src="item.node"
				:style="item.nodeStyle"
			/>
		</div>
		<audio
			ref="audioPlayer"
			:src="audioSrc"
			style="display: none"
		></audio>
	</div>
</template>

<script>
class CreateFish {
	constructor(type, x, y) {
		const isFlip = Math.random() > 0.5; // 是否翻转
		const isZoom = Math.random() > 0.8; // 是否缩放
		const zoomRatio = isZoom ? 0.8 : 1; // 缩放倍率
		this.y = isFlip ? 180 : 0;
		this.scale = false;
		this.node = type === 0 ? "" : require(`./images/fish/${type}.png`);
		this.audioSrc = type === 0 ? "" : require(`./images/fish/${type}.mp3`);
		this.style = {
			bottom: `${y}vh`,
			left: `${x}vw`,
			transform: `scale(${zoomRatio}) rotateY(${this.y}deg)`,
			"--y": this.y + "deg",
		};
		this.nodeStyle = {
			transform: `rotateY(${this.y}deg)`,
		};
	}
}
export default {
	data() {
		return {
			audioSrc: "",
			list: [
				new CreateFish(1, 28, 50),
				new CreateFish(1, 84, 1),
				new CreateFish(3, 33, 14),
				new CreateFish(3, 60, 33),
				new CreateFish(5, 15, 70),
				new CreateFish(5, 45, 40),
				new CreateFish(4, 43, 73),
				new CreateFish(4, 78, 31),
				new CreateFish(2, 1, 10),
				new CreateFish(2, 2, 50),
				new CreateFish(0, 16, 29),
				new CreateFish(0, 18, 0),
				new CreateFish(0, 54, 4),
				new CreateFish(0, 66, 67),
				new CreateFish(0, 85, 60),
			],
		};
	},
	methods: {
		playMusic() {
			this.$nextTick(() => {
				console.log(this.audioSrc);
				this.$refs.audioPlayer.play();
			});
		},
		toggle(index) {
			const result = this.list[index];
			result.scale = !result.scale;
			if (result.audioSrc) {
				this.audioSrc = result.audioSrc;
				this.playMusic();
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson19/images/bg_blur.png");
	.item {
		position: absolute;
		width: 270px;
		cursor: pointer;
		transform: rotateY(var(--y));
		transition: transform 0.3s;
		&-fish {
			width: 100%;
		}
		&-node {
			position: absolute;
			width: 50%;
			left: 35%;
			bottom: 40px;
		}
		&.scale {
			transform: scale(1.2) rotateY(var(--y)) !important;
		}
	}
}
</style>
