<template>
  <div class="common-page"  @click="changetalk(1)">
    <MagicBook
      @close="showMagicBook = false"
      v-if="showMagicBook"
      :animalSrc="animalSrc"
    />
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
      
      
    />
    <audio
      ref="audioTip"
      autoplay
      :src="audio.src"
      style="display: none"
      @ended="isTalking = false"
    ></audio>
    <div class="musician-box">
      <img src="@/assets/image/role/musician.png" class="musician" />
    </div>
    <div class="magician-box">
      <img src="@/assets/image/role/magician.png" class="magician" />
    </div>

    <div class="knight-box">
      <img src="@/assets/image/role/knight.png" class="knight" />
    </div>
    <div class="shimengmo-box">
      <img src="@/assets/image/role/shimengmo.png" class="shimengmo" />
    </div>
    <ChatDialog
      :talkList="talkList"
      :talkIndex="talkIndex"
      @audioStateChange="(state) => (isTalking = state)"
    ></ChatDialog>
  </div>
</template>

<script>
import MagicBook from "@/components/MagicBook/index.vue";
export default {
  components: {
    MagicBook,
  },
  data() {
    return {
      showMagicBook: false,
      animalSrc: require("@/components/MagicBook/images/shimengmo.png"),
      talkList: [
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content: "哈哈。果然，这次没有做恶梦，看来我们的摇篮曲有效果啦。",
          audio: require("@/assets/audio/lesson4/page4-musician-voice1.mp3"),
        },
        {
          talker: "shimengmo",
          talkerCHN: "食梦貘",
          content:
            "太好了，我又可以入睡了，这样我就可以吞噬别人的噩梦，为大家守护夜晚了。请收下我的祝福吧，希望这世上的每一个人都能有一个香甜的睡眠。",
          audio: require("@/assets/audio/lesson4/page4-shimengmo-voice1.mp3"),
        },
      ],
      talkIndex: -1,
      isTalking: true,

      audio: {
        // 消息通知
        src: require("@/assets/audio/lesson4/page4-aside-voice1.mp3"),
      },
    };
  },
  mounted() {
    this.$refs.audioTip.play();
  },
  methods: {
    gonext() {
      this.$router.push("/lesson4/page-5");
    },
    changetalk(val) {
      if (this.isTalking) {
        // 正在读旁白或者正在播放对话内容
        return;
      }
      if (this.talkIndex == 1) {
        this.showMagicBook = true;
      }
      this.talkIndex += val;
    },
  },
};
</script>

<style scoped>
.common-page {
  background-image: url("~@/assets/image/back04.png");
}
.magician-box {
  left: 620px;
  bottom: 260px;
  z-index: 7;
}
.knight-box {
  left: 900px;
  bottom: 120px;
  z-index: 8;
}
.musician-box {
  left: 220px;
  bottom: 140px;
  z-index: 9;
}
.shimengmo-box {
  left: 560px;
  bottom: 120px;
}
</style>