<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson22/page?page=2')"
			class="next-btn"
		/>

		<img
			class="door-frame"
			src="./images/hammer/door.png"
		/>

		<div class="container">
			<template v-for="(item, index) in list">
				<img
					v-if="item[item.count]"
					class="wall"
					:src="item[item.count]"
					:key="'wall_' + index"
				/>
				<div
					class="hammer_box"
					:style="item.hammerStyle"
					:key="'hammer_' + index"
				>
					<template v-if="item.count < item.maxFit">
						<img
							class="hammer"
							:class="{ fit: item.fit }"
							:src="item.hammer"
							@click="fit(item)"
						/>
						<img
							class="effect"
							:class="{ fit: item.fit }"
							src="./images/hammer/boom.png"
						/>
					</template>
				</div>
			</template>
		</div>
		<audio
			ref="audioPlayer"
			src="./audio/锤子击打声.mp3"
			style="display: none"
		></audio>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: [],
		};
	},
	created() {
		// 持续时间 ms
		const DURATION = 250;
		// 最大打击次数
		const MAX_FIT = 5;
		// 图片击打序号
		const IMG_CUR = {
			0: 0, // 默认图片
			1: 1,
			2: 1,
			3: 2,
			4: 2,
			5: false, // 最后一次击打 -> 击碎，图片为空
		};
		const createWall = ({ left, bottom }, current) => {
			current += 1;
			const obj = {
				count: 0,
				audio: "",
				maxFit: MAX_FIT,
				hammer: require(`./images/hammer/hammer_${current}.png`),
				hammerStyle: {
					left: left + "vw",
					bottom: bottom + "vh",
					"--d": DURATION + "ms",
				},
				fit: false,
				duration: DURATION,
			};
			for (let index = 0; index <= MAX_FIT; index++) {
				obj[String(index)] =
					IMG_CUR[String(index)] === false
						? ""
						: require(`./images/hammer/${current}_${IMG_CUR[index]}.png`);
			}
			this.list.push(obj);
		};
		const _list = [
			{
				left: 20,
				bottom: 50,
			},
			{
				left: 60,
				bottom: 50,
			},
			{
				left: 20,
				bottom: 3,
			},
			{
				left: 60,
				bottom: 3,
			},
		];
		_list.forEach(createWall);
	},
	methods: {
		fit(node) {
			node.count++;
			node.fit = true;
			this.$refs.audioPlayer.play();
			setTimeout(() => {
				node.fit = false;
			}, node.duration);
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson22/images/bg_blowup.png");
	.door-frame {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100vw;
		height: 98vh;
	}
	.container {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		height: 98vh;
		font-size: 0;
		.wall {
			width: 96.5vw;
			height: 98vh;
			position: absolute;
			bottom: 0;
			left: 1px;
		}
		.hammer_box {
			position: absolute;
			z-index: 2;
			.hammer {
				width: 300px;
				&.fit {
					animation: fit-hammer var(--d) cubic-bezier(0.88, 0.07, 0.32, 0.96);
				}
			}
			.effect {
				position: absolute;
				width: 168px;
				left: -30px;
				top: 130px;
				z-index: -1;
				opacity: 0;
				&.fit {
					animation: zoom-show var(--d) cubic-bezier(0.88, 0.07, 0.32, 0.96);
				}
			}
		}
	}
	@keyframes fit-hammer {
		0% {
			transform: rotate(0);
		}
		70% {
			transform: rotate(-30deg);
		}
		100% {
			transform: rotate(0);
		}
	}
	@keyframes zoom-show {
		30% {
			transform: scale(0);
			opacity: 0;
		}
		80% {
			transform: scale(1.5);
			opacity: 1;
		}
		100% {
			transform: scale(0);
			opacity: 0;
		}
	}
}
</style>
