<template>
	<div
		class="common-page"
		@click="changetalk()"
	>
		<img
			src="@/assets/image/next.png"
			@click.stop="gonext()"
			class="next-btn"
		/>
		<div class="magician-box">
			<img
				src="@/assets/image/role/magician.png"
				class="magician"
			/>
		</div>
		<div class="knight-box">
			<img
				src="@/assets/image/role/knight.png"
				class="knight"
			/>
		</div>
		<div class="musician-box">
			<img
				src="@/assets/image/role/musician.png"
				class="musician"
			/>
		</div>
		<ChatDialog
			:talkList="talkList"
			:talkIndex="talkIndex"
			@audioStateChange="(state) => (isTalking = state)"
		></ChatDialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			talkList: [
				{
					talker: "magician",
					talkerCHN: "魔法师",
					content:
						"你们看到了吗？这边有星星点点的金色在地上。仔细看，它像融化了的黄金一样，在幽光中闪闪发光。",
					audio: require("@/assets/audio/lesson1/page3-magician-voice1.mp3"),
				},
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"如果我没有猜错，这应该是独角兽的血液，独角兽就是我们要找的12神兽之一。可是这么多的血液在这里，",
					audio: require("@/assets/audio/lesson1/page3-musician-voice1.mp3"),
				},
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"他应该是受伤了，我们要按照金色血液的痕迹。去找到这头受伤的独角兽。快，我们要快一点。从这血迹看来他受伤应该不轻。",
					audio: require("@/assets/audio/lesson1/page3-musician-voice2.mp3"),
				},
				{
					talker: "knight",
					talkerCHN: "骑士",
					content: "这血迹看上去杂乱无章。我们怎么才能找到独角兽呢？",
					audio: require("@/assets/audio/lesson1/page3-knight-voice1.mp3"),
				},
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"独角兽是12神兽之一，它的体内有着魔法的力量，你仔细看着血迹，它暗藏着一种神秘的律动。让我们来试试看，破解这里面的玄机。",
					audio: require("@/assets/audio/lesson1/page3-musician-voice3.mp3"),
				},
			],
			talkIndex: -1,
			isTalking: false,
		};
	},
	mounted() {},
	methods: {
		changetalk() {
			if (this.talkIndex == 5) {
				// this.$router.push("/Btnmagic");
				// 原业务代码不自动跳转
			}
			if (this.isTalking) {
				// 正在读旁白或者正在播放对话内容
				return;
			}
			this.isTalking = true;
			this.talkIndex += 1;
		},
		gonext() {
			// eslint-disable-next-line no-unreachable
			this.$router.push("/lesson1/game-2");
		},
	},
};
</script>

<style scoped lang="scss">
.common-page {
	background-image: url("~@/assets/image/独角兽金色的血液.jpg");
}
.magician-box,
.knight-box,
.musician-box {
	position: absolute;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: flex-end;
	width: 300px;
}
.magician-box {
	left: 660px;
	bottom: 140px;
}
.knight-box {
	left: 760px;
	bottom: 200px;
}
.musician-box {
	left: 960px;
	bottom: 120px;
}
</style>
