import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import "@/assets/scss/reset.scss";
import "@/assets/scss/common.scss";
import "@/assets/scss/cover-page.scss";
const LessonEnd = () => import("@/components/LessonEnd/index.vue");
const ChatDialog = () => import("@/components/ChatDialog/index.vue");
Vue.component("LessonEnd",LessonEnd);
Vue.component("ChatDialog",ChatDialog);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
