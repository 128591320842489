<template>
  <div class="cover-page" @click.once="autoplayMusic">
    <img src="@/assets/image/title02.png" class="page-cover"  />
    <img
      src="@/assets/image/startbtn.png"
      class="start-btn"
      
      @click="start1"
    />
    <div class="custom-audio">
      <audio ref="audioTip" autoplay :src="audio.src" controls></audio>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audio: {
        // 消息通知
        src: require("@/assets/music/start.mp3"),
      },
    };
  },
  mounted() {
    this.autoplayMusic();
  },
  methods: {
    start1() {
      this.$router.push("/lesson2/page-1");
    },
    autoplayMusic() {
      this.$refs.audioTip.play();
    },
  },
};
</script>

<style scoped>
.img2 {
  width: 225px;
  height: 86px;
  margin-top: 40px;
}

.img1 {
  width: 647px;
  height: 452px;
}
</style>
