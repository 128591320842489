<template>
	<div
		class="common-page"
		@click="changetalk(1)"
	>
		<MagicBook
			@close="showMagicBook = false"
			v-if="showMagicBook"
			:animalSrc="animalSrc"
		/>
		<img
			src="@/assets/image/next.png"
			@click.stop="gonext()"
			class="next-btn"
		/>
		<audio
			ref="audioTip"
			style="display: none"
		>
			<source :src="audio.src" />
		</audio>
		<div class="horse-box">
			<img
				src="@/assets/image/role/dujiaoshou.png"
				class="horse"
			/>
		</div>
		<div class="musician-box">
			<img
				src="@/assets/image/role/musician.png"
				class="musician"
			/>
		</div>
		<div class="bird-box">
			<img
				src="@/assets/image/role/fengniao.png"
				class="bird"
			/>
		</div>
		<ChatDialog
			:talkList="talkList"
			:talkIndex="talkIndex"
			@audioStateChange="(state) => (isTalking = state)"
		></ChatDialog>
		<video
			muted
			loop
			autoplay
			ref="lightRayBg"
			class="light-ray-bg"
			src="@/assets/video/light-ray-bg.mp4"
		></video>
	</div>
</template>

<script>
import MagicBook from "@/components/MagicBook/index.vue";
export default {
	components: { MagicBook },
	data() {
		return {
			showMagicBook: false,
			animalSrc: require("@/components/MagicBook/images/horse.png"),
			talkList: [
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"幽光落在了独角兽的身上。你看你看，他身上的伤口渐渐的愈合了。",
					audio: require("@/assets/audio/lesson2/page5-musician-voice1.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content: "谢谢你们，也谢谢你蜂鸟，多亏了你们我才能恢复。",
					audio: require("@/assets/audio/lesson2/page5-horse-voice1.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content:
						"我是这世间神兽之一，今天你们和蜂鸟帮助了我，就像曾经帮助其他的动物一样。",
					audio: require("@/assets/audio/lesson2/page5-horse-voice2.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content:
						"我把祝福送给你们，祝你们在遇到困难的时候，永远有人帮助你，就像你帮助其他人们度过困难一样。",
					audio: require("@/assets/audio/lesson2/page5-horse-voice3.mp3"),
				},
				// 此处出现魔法书和宝石
				{
					// 魔法书后开始旁白
					audio: require("@/assets/audio/lesson2/page5-aside-voice1.mp3"),
				},
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"谢谢你的祝福。你是我们收集到的第一只神兽的祝福，我们还有很远的路要走。",
					audio: require("@/assets/audio/lesson2/page5-musician-voice2.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content:
						"就我所知，这个森林中的神兽不止我一个，你们往森林深处走吧，应该会遇到它们。",
					audio: require("@/assets/audio/lesson2/page5-horse-voice4.mp3"),
				},
			],
			talkIndex: -1,
			isTalking: false,
			openmagic: false,
			audio: {
				// 消息通知
				src: require("@/assets/music/pangbai4.mp3"),
			},
		};
	},
	mounted() {
		// this.$refs.audioTip.play();
	},
	methods: {
		gonext() {
			this.$router.push("/lesson2/page-6");
		},
		changetalk(val) {
			if (this.isTalking || this.showMagicBook) {
				// 显示魔法书时不可点击
				// 正在读旁白或者正在播放对话内容
				return;
			}
			this.isTalking = true;
			this.talkIndex += val;
			if (this.talkIndex == 4) {
				//  出现魔法书
				this.showMagicBook = true;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.common-page {
	background-image: url("~@/assets/image/back02.png");
	.horse-box {
		height: 524px;
		left: 280px;
		bottom: 200px;
		align-items: flex-start;
	}
	.musician-box {
		left: 800px;
		bottom: 240px;
		width: 300px;
	}
	.bird-box {
		left: 1160px;
		bottom: 640px;
	}
	.light-ray-bg {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		opacity: 0.5;
	}
}
</style>
