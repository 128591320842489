<template>
	<div
		class="common-page"
		@click="changetalk(1)"
	>
		<img
			class="next-btn"
			src="@/assets/image/next.png"
			@click.stop="gonext()"
		/>
		<div class="horse-box">
			<img
				class="horse"
				src="@/assets/image/role/dujiaoshou.png"
			/>
		</div>
		<div class="musician-box">
			<img
				class="musician"
				src="@/assets/image/role/musician.png"
			/>
		</div>
		<ChatDialog
			:talkList="talkList"
			:talkIndex="talkIndex"
			@audioStateChange="(state) => (isTalking = state)"
		></ChatDialog>
		<!-- <video
			muted
			loop
			autoplay
			ref="lightRayBg"
			class="light-ray-bg"
			src="@/assets/video/light-ray-bg.mp4"
		></video> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			talkList: [
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"看来我们找到他了。前面的白色的身影应该就是独角兽。他漂亮金色的长角，雪白的皮毛，看上去如此的神圣。",
					audio: require("@/assets/audio/lesson1/page5-musician-voice1.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content:
						"你们是什么人？请不要靠近这片森林。前面的森林中，长满了荆棘，我就是被着些荆棘刺伤的。",
					audio: require("@/assets/audio/lesson1/page5-horse-voice1.mp3"),
				},
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"请不要担心。我们是国王的使者。我们来到这里是帮助你的。我看你伤得很重，你需要治疗。",
					audio: require("@/assets/audio/lesson1/page5-musician-voice2.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content:
						"我的力量可以使这森林中枯萎的花朵重新绽放。我可以使干枯的树木萌发新芽。我可以使病倒的松鼠恢复健康。",
					audio: require("@/assets/audio/lesson1/page5-horse-voice2.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content:
						"我的力量可以治愈这片森林中所有的生命的伤病与枯萎。但是我唯独不能治疗自己的伤口。",
					audio: require("@/assets/audio/lesson1/page5-horse-voice3.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content:
						"独角兽的力量看似强大，可以帮助他人，但是我们却帮不了自己。如果要治愈我的伤口，只能求助于蜂鸟。",
					audio: require("@/assets/audio/lesson1/page5-horse-voice4.mp3"),
				},
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"你有很强的能力去帮助别人，但是你也需要别人的帮助。这就是不同生灵之间的相处之道吧。你伤得很重。",
					audio: require("@/assets/audio/lesson1/page5-musician-voice3.mp3"),
				},
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content: "我们去帮你找到蜂鸟吧，你告诉我在哪里可以找到他。",
					audio: require("@/assets/audio/lesson1/page5-musician-voice4.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content:
						"蜂鸟可是个急脾气的家伙。他总是快速地在树木之间飞行。他在飞行的时候会唱歌。",
					audio: require("@/assets/audio/lesson1/page5-horse-voice5.mp3"),
				},
			],
			talkIndex: -1,
			isTalking: false,
			isEnd: false,
		};
	},
	mounted() {},
	methods: {
		gonext() {
			// this.isEnd = true;
			this.$router.push("/lesson1/page-4");
		},
		changetalk(val) {
			if (this.isTalking) {
				// 正在读旁白或者正在播放对话内容
				return;
			}
			if (this.talkIndex !== 13) {
				this.talkIndex += val;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.common-page {
	background-image: url("~@/assets/image/独角兽金色的血液2.jpg");
	.musician-box,
	.horse-box {
		position: absolute;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		align-items: flex-end;
	}
	.horse-box {
		height: 524px;
		left: 280px;
		bottom: 200px;
		align-items: flex-start;
	}
	.musician-box {
		left: 800px;
		bottom: 240px;
		width: 300px;
	}
}
</style>
