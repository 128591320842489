<template>
  <div class="common-page" @click="changetalk(1)">
    <Magic v-if="openmagic" style="position: absolute"></Magic>
    <audio
      ref="audioTip"
      @ended="isTalking = false"
      :src="audio.src"
      style="display: none"
    ></audio>
    <div class="musician-box">
      <img src="@/assets/image/role/musician.png" class="musician" />
    </div>

    <div class="magician-box">
      <img src="@/assets/image/role/magician.png" class="magician" />
    </div>

    <div class="knight-box">
      <img src="@/assets/image/role/knight.png" class="knight" />
    </div>
    <div class="shimengmo-box">
      <img src="@/assets/image/role/shimengmo.png" class="shimengmo" />
    </div>
    <ChatDialog
      :talkList="talkList"
      :talkIndex="talkIndex"
      @audioStateChange="(state) => (isTalking = state)"
    ></ChatDialog>
  </div>
</template>

<script>
const Magic = () =>
  import(
    /* webpackChunkName:"magic-animation" */ "@/components/Magic/index.vue"
  );
export default {
  data() {
    return {
      talkList: [
        // "",
        // "",
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content: "来，大家想一想都有什么办法可以为食梦貘解决失眠的问题。",
          audio: require("@/assets/audio/lesson4/page1-musician-voice1.mp3"),
        },
        {
          talker: "magician",
          talkerCHN: "魔法师",
          content: "试试我们的催眠魔法吧",
          audio: require("@/assets/audio/lesson4/page1-magician-voice1.mp3"),
        },
      ],
      talkIndex: -1,
      isTalking: true,
      openmagic: false,
      audio: {
        // 消息通知
        src: require("@/assets/audio/lesson4//page1-aside-voice1.mp3"),
      },
    };
  },
  components: {
    Magic,
  },
  mounted() {
    this.$refs.audioTip.play();
  },
  methods: {
    gonext() {
      this.$router.push("/Fourcloud");
    },
    changetalk(val) {
      if (this.isTalking) {
        // 正在读旁白或者正在播放对话内容
        return;
      }
      if (!(this.talkIndex < this.talkList.length - 1)) {
        this.talkIndex += 1;
        this.$refs.audioTip.pause();
        this.openmagic = true;
        setTimeout(() => {
          // this.$refs.audioTip1.pause();
          this.openmagic = false;
          this.$router.push("/lesson4/game-1");
        }, 3000);
      } else {
        this.talkIndex += val;
      }
    },
  },
};
</script>

<style scoped>
.common-page {
  background-image: url("~@/assets/image/back04.png");
}
.magician-box {
  left: 620px;
  bottom: 260px;
  z-index: 7;
}
.knight-box {
  left: 900px;
  bottom: 120px;
  z-index: 8;
}
.musician-box {
  left: 220px;
  bottom: 140px;
  z-index: 9;
}
.shimengmo-box {
  left: 560px;
  bottom: 120px;
}
</style>