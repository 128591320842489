<template>
  <div class="common-page" @click="changetalk()">
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />
    <div class="musician-box">
      <img src="@/assets/image/role/musician.png" class="musician" />
    </div>

    <div class="magician-box">
      <img src="@/assets/image/role/magician.png" class="magician" />
    </div>
    <div class="knight-box">
      <img src="@/assets/image/role/knight.png" class="knight" />
    </div>
    <ChatDialog
      :talkList="talkList"
      :talkIndex="talkIndex"
      @audioStateChange="(state) => (isTalking = state)"
    ></ChatDialog>
    <ChatDialog
      v-if="talkIndex === 2"
      :talkList="talkList"
      :talkIndex="3"
      @audioStateChange="(state) => (isTalking = state)"
    ></ChatDialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      talkList: [
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content:
            "突然做了一个恶梦。一个巨大的黑色音符，在一直追着我。把我逼到了一个悬崖旁边。",
          audio: require("@/assets/audio/lesson3/page3-musician-voice1.mp3"),
        },
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content:
            "我径直的掉了下去。我已经好多年没有做过这样可怕的梦了。其他人有和我一样做噩梦吗？",
          audio: require("@/assets/audio/lesson3/page3-musician-voice2.mp3"),
        },
        {
          talker: "magician",
          talkerCHN: "魔法师",
          content: "我们都做了噩梦",
          audio: require("@/assets/audio/lesson3/page3-magician-voice1.mp3"),
        },
        {
          talker: "knight",
          talkerCHN: "骑士",
          content: "我们都做了噩梦",
          audio: require("@/assets/audio/lesson3/page3-knight-voice1.mp3"),
        },
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content: "这可真是奇怪呀！我们居然在同一时间都做了噩梦。",
          audio: require("@/assets/audio/lesson3/page3-musician-voice3.mp3"),
        },
      ],
      talkIndex: -1,
      isTalking: false,
    };
  },
  mounted() {},
  methods: {
    gonext() {
      this.$router.push("/lesson3/page-4");
    },
    changetalk() {
      if (this.isTalking) {
        // 正在读旁白或者正在播放对话内容
        return;
      }
      if (this.talkIndex === 2) {
        this.talkIndex += 2;
        return;
      }
      if (this.talkIndex < this.talkList.length - 1) {
        this.talkIndex += 1;
        this.isTalking = true;
      }
    },
  },
};
</script>

<style scoped>
.common-page {
  background-image: url("~@/assets/image/back04.png");
}
.magician-box {
  left: 620px;
  bottom: 260px;
  z-index: 7;
}
.knight-box {
  left: 900px;
  bottom: 120px;
  z-index: 8;
}
.musician-box {
  left: 220px;
  bottom: 140px;
  z-index: 9;
}
</style>