<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson16/page?page=4')"
			class="next-btn"
		/>
		<div class="container">
			<div
				class="item"
				:style="style"
				v-for="(style, index) in list"
				:key="index"
				@click="openDoor(index)"
			></div>
		</div>
		<WindowCom
			v-if="show"
			:index="current"
			@close="closePopup"
		></WindowCom>
	</div>
</template>

<script>
import WindowCom from "./components/window.vue";
class CreateDoor {
	constructor(x, y) {
		this.left = x + "vw";
		this.bottom = y + "vh";
	}
}
export default {
	components: {
		// eslint-disable-next-line vue/no-unused-components
		WindowCom,
	},
	data() {
		return {
			current: 0,
			show: false,
			list: [
				new CreateDoor(22.2, 7.3),
				new CreateDoor(42.7, 7.3),
				new CreateDoor(63.2, 7.3),
				new CreateDoor(83.7, 7.3),
				new CreateDoor(22.2, 57),
				new CreateDoor(42.7, 57),
				new CreateDoor(63.2, 57),
				new CreateDoor(83.7, 57),
			],
		};
	},
	methods: {
		openDoor(index) {
			this.current = index + 1;
			this.show = true;
		},
		closePopup() {
			this.show = false;
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson16/images/buildbg.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: url("~@/views/lesson16/images/handrail.png") no-repeat;
	background-size: 100vw auto;
	background-position: left bottom;
	.item {
		position: absolute;
		width: 264px;
		height: 37.6vh;
		border-radius: 480px 400px 0 0;
		cursor: pointer;
	}
}
</style>
