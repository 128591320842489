const pageBg = require("@/views/lesson7/images/bg_L7_normal.jpg");
const startPageBg = require("@/views/lesson7/images/bg_L7_start.jpg");
const pageNextBtnImage = require("@/assets/image/next.png");
const roles = (scale) => [
	{
		roleClass: "magician",
		imgSrc: require("@/assets/image/role/magician.png"),
		style: {
			width: "auto",
			left: "9.646vw",
			bottom: "4.352vh",
			zIndex: 7,
		},
		roleStyle: {
			height: `${43.333 * scale}vh`,
		},
	},
	{
		roleClass: "musician",
		imgSrc: require("@/assets/image/role/musician.png"),
		style: {
			width: "auto",
			left: "36vw",
			bottom: "3.759vh",
			zIndex: 8,
		},
		roleStyle: {
			height: `${52.778 * scale}vh`,
		},
	},
	{
		roleClass: "eagle",
		imgSrc: require("@/views/lesson7/images/L7_老鹰们.png"),
		style: {
			width: "auto",
			left: "53vw",
			bottom: "18vh",
			zIndex: 6,
		},
		roleStyle: {
			height: `${47 * scale}vh`,
		},
	},
	{
		roleClass: "egm",
		imgSrc: require("@/views/lesson8/images/宝石.png"),
		style: {
			width: "auto",
			left: "56.9vw",
			bottom: "24.5vh",
			zIndex: 5,
		},
		roleStyle: {
			height: `${4 * scale}vh`,
		},
	},
	{
		roleClass: "knight",
		imgSrc: require("@/assets/image/role/knight.png"),
		style: {
			width: "auto",
			left: "76.719vw",
			bottom: "2.685vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${50.092 * scale}vh`,
		},
	},
];
export default {
	lessonStart: {
		// 首页
		pageBg: startPageBg, // 首页背景
		pageTitleImage: require("@/views/lesson8/images/L8_title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson8/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson8/page?page=2",
		roles: roles(1),
		audio: {
			src: require("../audio/旁白1.mp3"),
			loop: false,
			controls: false,
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "两只雄鹰在为什么争吵？",
				audio: require("../audio/宫廷1.mp3"),
			},
			{
				talker: "eagle",
				talkerCHN: "雄鹰a",
				content: "看到脚底下的宝石了吗？这是我先看到的，他应该归我。",
				audio: require("../audio/a1.mp3"),
			},
			{
				talker: "eagle",
				talkerCHN: "雄鹰b",
				content:
					"可是，是我先捡到的。难道不应该归我吗？我们两个人已经在这里争吵了一个月了，都没有得到答案。",
				audio: require("../audio/b1.mp3"),
				style: {
					backgroundImage: `url(${require("@/views/lesson7/images/dialog_box_bg_老鹰2.png")}`,
				},
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"这确实是个难题。无论是谁先看到还是谁先捡到都有理由得到这块宝石。但是雄鹰本身是追求自由的动物，难道不该翱翔在天际吗？",
				audio: require("../audio/宫廷2.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"难道为了一块宝石就困在地上吗？你们看，这天上变换莫测的白云，难道不美丽吗？",
				audio: require("../audio/宫廷3.mp3"),
			},
			{
				audio: require("../audio/旁白2.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "看你们这两个小馋猫。我给你们讲一个关于巧克力豆的故事吧。",
				audio: require("../audio/宫廷4.mp3"),
			},
		],
	},
	3: {
		pageBg: startPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson8/page?page=4",
		// roles: roles(1),
		// audio: {},
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/L8_notes_fries.png"),
				style: {
					position: "absolute",
					height: "33.33vh",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
		],
	},
	2: {
		pageBg: startPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson8/page?page=3",
		// roles: roles(1),
		// audio: {},
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/L8_bg_fries.png"),
				style: {
					position: "absolute",
					height: "100vh",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/L8_notes.png"),
				style: {
					position: "absolute",
					height: "33.33vh",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
		],
	},
	4: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson8/page?page=5",
		roles: roles(1),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"你们看，自然给了你们这么变幻莫测的白云，和这蔚蓝的天空，还有翱翔在天际的能力，你们为什么要为宝石困在地上呢？难道自由的飞翔不比宝石来的珍贵吗？",
				audio: require("../audio/宫廷5.mp3"),
			},
			{
				talker: "eagle",
				talkerCHN: "雄鹰a",
				content:
					"是啊，谢谢你。这世界上没有比自由更宝贵的财富了，比起任何宝石，自由，都是无价之宝。",
				audio: require("../audio/a2.mp3"),
			},
		],
	},
	5: {
		pageBg: startPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson8/page?page=6",
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/L8_bg_fries.png"),
				style: {
					position: "absolute",
					height: "100vh",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
			{
				type: "text-lines",
				style: {
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					fontSize: "7.407vh",
					fontFamily: "Nowar Warcraft Rounded CN",
					fontWeight: "bold",
					color: "#643D00",
					letterSpacing: "0.1em",
					lineHeight: "100px",
					zIndex: 11,
				},
				textLines: [
					{
						text: "老鹰老鹰真听话，",
					},
					{
						text: "看清事实不打架，",
					},
					{
						text: "不必争抢手中宝，",
					},
					{
						text: "唯有自由是无价。",
					},
				],
			},
		],
	},
	6: {
		pageBg: startPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson8/page?page=7",
		talkList: [],
		audioPlayer: {
			src: require("../audio/老鹰打架-薯条.mp3"),
			autoplay: true,
			controls: true,
			style: {
				bottom: "5vh",
			},
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/L8_bg_fries.png"),
				style: {
					position: "absolute",
					height: "100vh",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/L8_stave.png"),
				style: {
					position: "absolute",
					height: "49vh",
					top: "22vh",
					left: "50%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
		],
	},
	7: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson9/page",
		roles: roles(1),
		isEnd: true,
		talkList: [
			{
				talker: "eagle",
				talkerCHN: "雄鹰b",
				content:
					"谢谢你让我们明白为了执着这地上的宝藏，而放弃了自由天空是多么愚蠢的事情。",
				audio: require("../audio/b2.mp3"),
				style: {
					backgroundImage: `url(${require("@/views/lesson7/images/dialog_box_bg_老鹰2.png")}`,
				},
			},
			{
				talker: "eagle",
				talkerCHN: "雄鹰a",
				content:
					"我们把自己的祝福送给你，希望你们所有人都能记得自由是多么的可贵，再见了我的朋友们，我们要去天空中飞翔了，也许有一天我们还会再见面。",
				audio: require("../audio/a3.mp3"),
			},
			{
				isBreak: true,
				callback: (vm) => {
					vm.animalDiamond = require("../images/祝福-雄鹰.png");
					vm.showMagicBook = true;
				},
			},
		],
	},
};
