<template>
  <div class="cover-page" >
    <img class="page-cover" src="@/assets/image/title03.png" />
    <img class="start-btn" src="@/assets/image/startbtn.png" @click="start1" />
    <div class="custom-audio">
      <audio ref="audioTip" autoplay :src="audio.src" controls></audio>
    </div>
 </div>
</template>

<script>
export default {
  data() {
    return {
      audio: {
        // 消息通知
        src: require("@/assets/music/wen-hou-ge3.mp3"),
        // width:0,
      },
      titlestate: false,
    };
  },
  mounted() {
  },
  methods: {
    start1() {
      this.$router.push("/lesson3/page-1");
    },
  },
};
</script>

<style scoped>
.cover-page {
  background-image: url("~@/assets/image/back04.png");
}
</style>
