const pageBg = require("../images/bg_L5_normal.jpg");
export const roles = (scale) => [
	{
		roleClass: "magician",
		imgSrc: require("@/assets/image/role/magician.png"),
		style: {
			width: "auto",
			left: "8.645vw",
			bottom: "12.314vh",
			zIndex: 7,
		},
		roleStyle: {
			height: `${49.537 * scale}vh`,
		},
	},
	{
		roleClass: "musician",
		imgSrc: require("@/assets/image/role/musician.png"),
		style: {
			width: "auto",
			left: "34.989vw",
			bottom: "10.555vh",
			zIndex: 8,
		},
		roleStyle: {
			height: `${60.463 * scale}vh`,
		},
	},
	{
		roleClass: "cattle",
		imgSrc: require("../images/cattle.png"),
		style: {
			width: "auto",
			left: "46.375vw",
			bottom: "9.907vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${40.741 * scale}vh`,
		},
	},
	{
		roleClass: "knight",
		imgSrc: require("@/assets/image/role/knight.png"),
		style: {
			width: "auto",
			left: "75.416vw",
			bottom: "11.203vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${57.5 * scale}vh`,
		},
	},
];
export default {
	lessonStart: {
		// 首页
		pageBg: require("../images/bg_L5_start.jpg"), // 首页背景
		pageTitleImage: require("../images/L5_title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson5/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage: require("@/assets/image/next.png"),
		nextPageRoute: "/lesson5/game-1",
		roles: roles(1),
		audio: {
			src: require("../audio/aside1.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "牦牛，你为什么要在这里绕着湖走来走去呢？",
				audio: require("../audio/musician1.mp3"),
			},
			{
				talker: "cattle",
				talkerCHN: "牦牛",
				content: "你们看这远处的雪山，如此的高大，神秘，里面是否会住着神仙呢？",
				audio: require("../audio/cattle1.mp3"),
				style: {
					backgroundImage: `url(${require("../images/cattle-chat-box.png")}`,
				},
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "那我们就看看这雪山他会不会回答我们。",
				audio: require("../audio/musician2.mp3"),
			},
			{
				audio: require("../audio/aside2.mp3"),
			},
		],
	},
	3: {
		pageBg,
		pageNextBtnImage: require("@/assets/image/next.png"),
		nextPageRoute: "/lesson5/game-2",
		roles: roles(1),
		audio: {
			src: require("../audio/aside3.mp3"),
			autoplay: true,
		},
		talkList: [],
	},
	5: {
		pageBg,
		pageNextBtnImage: require("@/assets/image/next.png"),
		nextPageRoute: "/lesson5/game-3",
		roles: roles(1),
		audio: {
			src: require("../audio/aside4.mp3"),
			autoplay: true,
		},
		talkList: [],
	},
	// 6: {
	//     pageBg,
	//     pageNextBtnImage: require("@/assets/image/next.png"),
	//     nextPageRoute: "/lesson5/page?page=7",
	//     // roles: roles(.862),
	//     talkList: [],
	//     otherElement: [
	//         {
	//             type: 'img',
	//             src: require("../images/L5_认识五线谱_bg.png"),
	//             style: {
	//                 position: "absolute",
	//                 width: "90.781vw",
	//                 top: "19.815vh",
	//                 left: "4.583vw",
	//                 cursor: 'pointer',
	//                 zIndex: 10
	//             }
	//         },
	//         {
	//             type: 'img',
	//             src: require("../images/L5_认识五线谱.png"),
	//             style: {
	//                 position: "absolute",
	//                 width: "71.875vw",
	//                 top: "23.762vh",
	//                 left: "14.0625vw",
	//                 cursor: 'pointer',
	//                 zIndex: 11
	//             }
	//         },
	//     ]
	// },
	7: {
		pageBg,
		pageNextBtnImage: require("@/assets/image/next.png"),
		nextPageRoute: "/lesson6/page",
		roles: roles(1),
		isEnd: true,
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "看来在我们问雪山的时候，他会用他的方式来回答我们。",
				audio: require("../audio/musician3.mp3"),
			},
		],
	},
};
