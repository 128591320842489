const pageBg = require("@/views/lesson20/images/bg.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "34vw",
				bottom: "12vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "12vw",
				bottom: "12vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${31 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "8vw",
				bottom: "10vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${44 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "seadog",
			imgSrc: require("@/assets/image/role/seadog.png"),
			style: {
				width: "auto",
				right: "4vw",
				bottom: "10vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${24 * scale}vh`,
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};
console.log(roles);

export default {
	lessonStart: {
		// 首页
		pageBg, // 首页背景
		pageTitleImage: require("@/views/lesson20/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson20/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson20/page?page=1-1",
		roles: roles(1.5),
		audio: {
			src: require("../audio/旁白2001.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "seadog",
				talkerCHN: "海豹",
				content:
					"前几天我遇到了一个人鱼，他嘲笑我不会音乐，于是我和她吵了起来，结果他开始一边弹竖琴一边唱歌骂我，所以我也想学习一种乐器",
				audio: require("../audio/海豹2001.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "来，让我看看",
				audio: require("../audio/宫廷乐师2001.mp3"),
			},
			{
				audio: require("../audio/旁白2002.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你这手，基本上就告别竖琴这种乐器了，要不然你学打鼓吧",
				audio: require("../audio/宫廷乐师2002.mp3"),
			},
			{
				audio: require("../audio/旁白2003-海豹很开心，一边拍着肚皮一边说.mp3"),
			},
			{
				talker: "seadog",
				talkerCHN: "海豹",
				content: "好呀，好呀，是个乐器就可以，我就是要赢人鱼",
				audio: require("../audio/海豹2002.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "那你认真听我讲",
				audio: require("../audio/宫廷乐师2003.mp3"),
			},
		],
	},
	"1-1": {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson20/page?page=2",
		audioPlayer: {
			src: require("../audio/小海豹4.mp3"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "7vh",
			},
		},
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/bg_deep.png"),
				style: {
					position: "absolute",
					width: "60vw",
					top: "50vh",
					left: "20vw",
					cursor: "pointer",
					transform: "translateY(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/五线谱_小海疼.png"),
				style: {
					position: "absolute",
					width: "50vw",
					top: "50vh",
					left: "50vw",
					transform: "translate(-50%, -50%)",
					zIndex: 10,
				},
			},
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson20/page?page=3",
		roles: roles(1.5),
		talkList: [
			{
				talker: "seadog",
				talkerCHN: "海豹",
				content:
					"哈哈哈真开心。我终于学会了。我把祝福和卷轴给你们，希望你们无论在多么困难的情况下都不会放弃希望。",
				audio: require("../audio/海豹2003.mp3"),
			},
			{
				isBreak: true,
				callback: (vm) => {
					vm.animalDiamond = require("../images/祝福-海豹.png");
					vm.showMagicBook = true;
				},
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "谢谢你。这些鱼应该够我们吃一段时间了",
				audio: require("../audio/宫廷乐师2004.mp3"),
			},
		],
	},
	3: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson20/page?page=4",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/paper_close.png"),
				style: {
					position: "absolute",
					height: "80vh",
					bottom: "10vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
					cursor: "pointer",
				},
				click(vm) {
					vm.$router.push("/lesson20/page?page=4");
				},
			},
		],
	},
	4: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson20/page?page=5",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/oldpaper.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/do_wuxianpu@2x.png"),
				style: {
					position: "absolute",
					width: "32vw",
					top: "22vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/Fa@2x.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "60vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	5: {
		pageBg,
		pageNextBtnImage,
		isEnd: true,
		nextPageRoute: "/lesson21/page",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/oldpaper.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/F@2x.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "50vh",
					left: "50vw",
					transform: "translate(-50%, -50%)",
					zIndex: 10,
				},
			},
		],
	},
};
