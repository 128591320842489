<template>
  <div class="common-page" >
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />
    <audio
      ref="audioTip"
      autoplay
      :src="audio.src"
      style="display: none"
    ></audio>
    <div class="backimg"></div>
    <div class="content">
      <div class="role">
        <img class="zzz" src="@/assets/image/sleep-zzz-animate.gif" />
        <img class="circle-header" src="@/assets/image/iconhead01.png" />
      </div>
      <div class="role">
        <img class="zzz" src="@/assets/image/sleep-zzz-animate.gif" />
        <img class="circle-header" src="@/assets/image/iconhead02.png" />
      </div>
      <div class="role">
        <img class="zzz" src="@/assets/image/sleep-zzz-animate.gif" />
        <img class="circle-header" src="@/assets/image/iconhead03.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audio: {
        // 消息通知
        src: require("@/assets/music/10181.mp3"),
        // width:0,
      },
      titlestate: false,
    };
  },
  mounted() {},
  methods: {
    gonext() {
      this.$router.push("/lesson3/game-1");
    },
  },
};
</script>

<style scoped lang="scss">
.common-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
  .backimg {
    background-image: url("~@/assets/image/back05.png");
    background-size: 100%;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
  }
  .content {
    width: 100vw;
    height: 100vh;
    /* background-repeat: no-repeat; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
  }
}

.role {
  position: relative;
  & + .role {
    margin-left: 130px;
  }
  .zzz {
    position: absolute;
    top: -1px;
    width: 64.4px;
    height: 110px;
  }
  .circle-header {
    width: 168px;
    height: 168px;
  }
}
</style>
