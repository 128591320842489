<template>
	<div
		class="common-page"
		@click="changetalk()"
	>
		<!-- <audio ref="fengNiaoAudio" :src="fengNiaoAudio" style="display: none"></audio> -->
		<img
			src="@/assets/image/next.png"
			@click.stop="gonext()"
			class="next-btn"
		/>
		<div class="musician-box">
			<img
				src="@/assets/image/role/musician.png"
				class="musician"
			/>
		</div>
		<div class="magician-box">
			<img
				src="@/assets/image/role/magician.png"
				class="magician"
			/>
		</div>
		<div class="knight-box">
			<img
				src="@/assets/image/role/knight.png"
				class="knight"
			/>
		</div>
		<ChatDialog
			:talkList="talkList"
			:talkIndex="talkIndex"
			mode="fengniao"
			@audioStateChange="(state) => (isTalking = state)"
			@playFengNiao="play"
		>
		</ChatDialog>
		<div
			class="custom-audio"
			:class="{ show: showAudio }"
		>
			<audio
				ref="fengNiaoAudio"
				:src="fengNiaoAudio"
				controls
			></audio>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showAudio: false,
			fengNiaoAudio: require("@/assets/music/fengniao.mp3"),
			talkList: [
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"快！大家快点走，独角兽的伤势很严重，我们要赶快去找到蜂鸟，仔细听，仔细听。听听有没有蜂鸟的歌声。",
					audio: require("@/assets/audio/lesson2/page1-musician-voice1.mp3"),
				},
				{
					// 旁白只有音频
					audio: require("@/assets/audio/lesson2/page1-aside-voice1.mp3"),
				},
				{
					// 蜂鸟的歌声
					audio: require("@/assets/music/fengniao.mp3"),
				},
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"你们听到了吗？这就是蜂鸟的歌声。这韵律，婉转起伏而悠扬。似乎有着某种节奏，韵律在当中。我们遵循着这个韵律，也许就找到蜂鸟了。",
					audio: require("@/assets/audio/lesson2/page1-musician-voice2.mp3"),
				},
				// {
				//   talker: "musician",
				//   talkerCHN: "宫廷乐师",
				//   content:
				//     "",
				//   audio: ""
				// },
			],
			talkIndex: -1,
			isTalking: false,
		};
	},
	mounted() {},
	methods: {
		gonext() {
			this.$router.push("/lesson2/page-2");
		},
		changetalk() {
			if (this.talkIndex < this.talkList.length - 1) {
				if (this.isTalking) {
					// 正在读旁白或者正在播放对话内容
					return;
				}
				this.isTalking = true;
				this.talkIndex += 1;
				if (this.talkIndex === 1) {
					// this.$refs.fengNiaoAudio.play();
				}
			}
		},
		play() {
			this.showAudio = true;
			this.$refs.fengNiaoAudio.play();
		},
	},
};
</script>

<style scoped lang="scss">
.common-page {
	background-image: url("~@/assets/image/独角兽金色的血液.jpg");
}
.magician-box {
	left: 820px;
	bottom: 200px;
	z-index: 2;
}

.knight-box {
	left: 920px;
	bottom: 120px;
	z-index: 8;
}

.musician-box {
	left: 440px;
	bottom: 100px;
	z-index: 9;
}

.custom-audio {
	position: absolute;
	left: 50%;
	bottom: 2.604vw;
	transform: translateX(-50%);
	z-index: 11;
	display: none;
	opacity: 0;
	transition: all 0.2s;

	&.show {
		display: block;
		opacity: 1;
	}
}
</style>
