var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isPageStart)?_c('div',{staticClass:"cover-page common-lesson-page",style:({ backgroundImage: `url(${_vm.pageConfig.pageBg})` })},[_c('img',{staticClass:"page-cover",attrs:{"src":_vm.pageConfig.pageTitleImage}}),_c('img',{staticClass:"start-btn",attrs:{"src":_vm.pageConfig.pageBtnImage},on:{"click":function($event){return _vm.$router.push(_vm.pageConfig.nextPageRoute)}}}),(_vm.pageConfig.audio)?_c('div',{staticClass:"custom-audio",style:({
			display:
				_vm.pageConfig.audio && _vm.pageConfig.audio.controls ? 'block' : 'none',
		})},[_c('audio',{ref:"audioTip",attrs:{"src":_vm.pageConfig.audio && _vm.pageConfig.audio.src,"autoplay":_vm.pageConfig.audio && _vm.pageConfig.audio.autoplay,"loop":_vm.pageConfig.audio && _vm.pageConfig.audio.loop,"controls":_vm.pageConfig.audio && _vm.pageConfig.audio.controls}})]):_vm._e()]):_c('div',{staticClass:"common-page common-lesson-page",style:({ backgroundImage: `url(${_vm.pageConfig.pageBg})` }),on:{"click":function($event){return _vm.changetalk()}}},[_c('img',{staticClass:"next-btn",attrs:{"src":_vm.pageConfig.pageNextBtnImage || '@/assets/image/next.png'},on:{"click":function($event){$event.stopPropagation();return _vm.goNextPage(_vm.pageConfig)}}}),_vm._l((_vm.pageConfig.roles),function(role){return _c('div',{key:role.roleClass,staticClass:"role-box",class:role.roleClass + '-box',style:(role.style)},[_c('img',{class:role.roleClass,style:(role.roleStyle),attrs:{"src":role.imgSrc}})])}),(_vm.pageConfig.otherElement)?_vm._l((_vm.pageConfig.otherElement),function(element,elIndex){return _c('OtherElement',{key:elIndex,attrs:{"element":element}})}):_vm._e(),_c('ChatDialog',{attrs:{"talkList":_vm.pageConfig.talkList,"talkIndex":_vm.talkIndex},on:{"audioStateChange":(state) => (_vm.isTalking = state)}}),(_vm.pageConfig.audioPlayer)?_c('div',{staticClass:"custom-audio",style:({
			display: _vm.pageConfig.audioPlayer.controls ? 'block' : 'none',
			..._vm.pageConfig.audioPlayer.style,
		})},[_c('audio',{ref:"audioPlayer",style:({ display: _vm.pageConfig.audioPlayer.controls ? 'block' : 'none' }),attrs:{"src":_vm.pageConfig.audioPlayer.src,"autoplay":_vm.pageConfig.audioPlayer.autoplay,"loop":_vm.pageConfig.audioPlayer.loop,"controls":_vm.pageConfig.audioPlayer.controls},on:{"ended":_vm.audioPlayerEnded,"timeupdate":_vm.timeupdate}})]):_vm._e(),(_vm.pageConfig.audio)?_c('audio',{ref:"bgAudio",style:({ display: _vm.pageConfig.audio.controls ? 'block' : 'none' }),attrs:{"src":_vm.pageConfig.audio.src,"loop":_vm.pageConfig.audio.loop,"autoplay":_vm.pageConfig.audio.autoplay,"controls":_vm.pageConfig.audio.controls},on:{"ended":_vm.pageAudioEnded}}):_vm._e(),(_vm.isEnd)?_c('LessonEnd',{attrs:{"nextLessonPath":_vm.pageConfig.nextPageRoute}}):_vm._e(),(_vm.showMagicBook)?_c('MagicBook',{attrs:{"animalSrc":_vm.animalDiamond},on:{"close":function($event){_vm.showMagicBook = false}}}):_vm._e(),(_vm.showMagic)?_c('Magic'):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }