<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson12/page?page=6')"
			class="next-btn"
		/>
		<div class="container">
			<div class="content-box">
				<!-- <img
					class="bamboo"
					src="./images/bamboo@2x.png"
				/> -->
				<img
					class="bamboo-horizontal"
					src="./images/竹7@2x.png"
					alt=""
				/>
			</div>
			<div class="content">
				<div class="warp">
					<div>熊猫大师 功夫 好，</div>
					<div>飞檐走壁 本领 高。</div>
					<div>早晚修行 不怕 累，</div>
					<div>唯有臭屁 忍不 了。</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson12/images/bg_blur.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.content-box {
		position: relative;
		.bamboo {
			width: 300vw;
			transform: translateX(-33vw) rotateZ(-90deg);
		}
		.bamboo-horizontal {
			width: 100vw;
		}
	}
	.content {
		position: absolute;
		width: 100vw;
		height: 600px;
		margin-top: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		.warp {
			font-size: 80px;
			font-weight: bold;
			color: #a2ffb8;
			line-height: 120px;
		}
	}
}
</style>
