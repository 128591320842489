<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson13/page?page=6')"
			class="next-btn"
		/>
		<div class="container">
			<img
				class="animo"
				src="./images/臭鼬.png"
			/>
			<img
				class="fart"
				src="./images/fart.png"
			/>
		</div>
		<audio
			ref="audioPlayer"
			autoplay
			src="./audio/旁白6.mp3"
			style="display: none"
			@ended="changeAudioState"
		></audio>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	methods: {
		changeAudioState() {
			this.$router.push("page?page=6");
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson13/images/hole_bg@2x.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.animo {
		width: 30vw;
		opacity: 0;
		transform: translateX(-100vw);
		animation: move 3s;
	}
	.fart {
		position: absolute;
		width: 80vw;
		animation: fart 3s;
	}
}
@keyframes fart {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	60% {
		opacity: 0;
		transform: scale(0);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes move {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	70% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(0.8) translateX(-100vw);
	}
}
</style>
