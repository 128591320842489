<template>
	<div
		class="common-page"
		@click="changetalk()"
	>
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson9/game-2')"
			class="next-btn"
		/>
		<div class="container">
			<img
				src="./images/S.png"
				class="audio-s"
				@click="playAudio('s')"
			/>
			<img
				src="./images/M.png"
				class="audio-m"
				@click="playAudio('m')"
			/>
			<img
				src="./images/L.png"
				class="audio-l"
				@click="playAudio('l')"
			/>
		</div>
		<audio
			ref="audioPlayer"
			:src="audioSrc"
			@ended="pageAudioEnded"
		></audio>
		<ChatDialog
			:talkList="talkList"
			:talkIndex="talkIndex"
			@audioStateChange="(state) => (isTalking = state)"
		/>
	</div>
</template>

<script>
export default {
	data() {
		return {
			audioSrc: "",
			talkIndex: -1,
			isTalking: false,
			bgIndex: 0,
			talkList: [
				{
					talker: "zuoluo",
					talkerCHN: "佐罗",
					content:
						"悬崖之间很可能有一座隐形的桥。我们要按照声音的提示，找出正确的足迹，才能走过去，大家小心，跟我来。",
					audio: require("./audio/佐罗2.mp3"),
				},
			],
		};
	},
	mounted() {
		this.playBgAudio(require("./audio/旁白4.mp3"));
	},
	methods: {
		playAudio(key) {
			if (this.isTalking) return;
			this.audioSrc = require(`@/views/lesson9/audio/${key}.mp3`);
			this.$nextTick(() => {
				this.$refs.audioPlayer.play();
			});
		},
		changetalk() {
			if (this.isTalking) return;
			this.talkIndex += 1;
		},
		playBgAudio(src) {
			this.audioSrc = src;
			let audio = this.$refs.audioPlayer;
			if (audio) {
				audio.pause();
				audio.currentTime = 0;
				this.isTalking = true;
				this.$nextTick(() => {
					audio.currentTime = 0;
					audio.play();
				});
			}
		},
		pageAudioEnded() {
			this.bgIndex++;
			this.isTalking = false;
			if (this.bgIndex > 1) return;
			this.playBgAudio(require("./audio/旁白5.mp3"));
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson9/images/shrine_bg.png");
	audio {
		display: none;
	}
}
.container {
	position: fixed;
	bottom: -90px;
	left: 30vw;
	width: 767px;
	height: 486px;
	background: url("~@/views/lesson9/images/handle.png") no-repeat;
	background-size: cover;
	transform: scale(0.47);
	img {
		position: absolute;
		cursor: pointer;
	}
	.audio {
		&-s {
			bottom: 0;
			left: 60px;
		}
		&-m {
			bottom: 20vh;
			left: 15vw;
		}
		&-l {
			bottom: 34vh;
			left: 35vw;
			transform: scale(2);
		}
	}
}
</style>
