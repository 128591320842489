<template>
	<div
		class="common-page"
		@mouseup="isMouseDown = false"
		@mousemove="handleMouseMove"
		@click="changetalk()"
	>
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson6/page?page=6')"
			class="next-btn"
		/>

		<img
			class="stoneBg"
			src="./images/L6_stonebg.png"
			alt=""
		/>
		<img
			class="stave"
			src="./images/L6_stave_on_stone.png"
			alt=""
		/>
		<img
			class="notes"
			@mousedown.stop="handleMousedown"
			:style="{
				transform: `translateX(-50%) rotate(${rotateDeg}deg)`,
				transformOrigin: 'center center',
			}"
			src="./images/L6_notes_Big.png"
			alt=""
		/>
		<img
			class="btn"
			src="./images/L6_tips.png"
			alt=""
		/>
		<ChatDialog
			:talkList="talkList"
			:talkIndex="talkIndex"
			@audioStateChange="(state) => (isTalking = state)"
		/>
	</div>
</template>

<script>
export default {
	name: "",
	components: {},
	mixins: [],
	props: {},
	data() {
		return {
			isTalking: false,
			talkIndex: -1,
			isMouseDown: false, // 点击了旋转按钮，通过鼠标移动计算角度
			btnCenter: [0, 0],
			rotateDeg: 0,
			talkList: [
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"请大家看看这个是不是和刚才我们看到的音符不一样？找找与其他音符的不同呢。",
					audio: require("./audio/宫廷3.mp3"),
				},
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content: "虽然看似不同，但是请大家看看，他们其实是一样的。",
					audio: require("./audio/宫廷4.mp3"),
				},
			],
		};
	},
	computed: {},
	watch: {},
	filter: {},
	created() {},
	mounted() {},
	methods: {
		changetalk() {
			this.talkIndex += 1;
		},
		handleMouseMove(event) {
			if (this.isMouseDown) {
				let { clientX: newX, clientY: newY } = event;
				let angle = this.getAngle(...this.btnCenter, newX, newY);
				// angle = angle / 500;
				// let startAngle = this.rotateDeg;
				// let deg;

				// // 赋值的旋转角度
				// let rotate;

				// // 顺时针旋转
				// if (newX - this.btnCenter[0] > 0) {
				// 	deg = startAngle + angle;
				// 	rotate = deg > 360 ? deg - 360 : deg;
				// } else {
				// 	// 逆时针旋转
				// 	angle = 360 - angle;
				// 	deg = startAngle - angle;
				// 	rotate = deg < 0 ? deg + 360 : deg;
				// }
				// this.rotateDeg = rotate;
				console.log(this.rotateDeg, angle);
				this.rotateDeg = angle;
			}
		},
		// 旋转角度
		/**
		 * 获得旋转夹角
		 * @param {*} x1 旋转点1
		 * @param {*} y1
		 * @param {*} x2 旋转点2
		 * @param {*} y2
		 */
		getAngle(x1, y1, x2, y2) {
			// 获取组件的位置信息
			let rect = document.querySelector(".notes").getBoundingClientRect();
			let { x, y, width, height } = rect;

			// 中心点
			let cx = x + width / 2;
			let cy = y + height / 2;

			// 2个点之间的角度获取
			// let c1 = (Math.atan2(y1 - cy, x1 - cx) * 180) / Math.PI;
			// let c2 = (Math.atan2(y2 - cy, x2 - cx) * 180) / Math.PI;
			// let angle;
			// c1 = c1 <= -90 ? 360 + c1 : c1;
			// c2 = c2 <= -90 ? 360 + c2 : c2;

			// 夹角获取
			// angle = Math.floor(c2 - c1);
			// angle = angle < 0 ? angle + 360 : angle;
			// return angle;

			let angle = Math.atan2(x2 - cx, y2 - cy);
			// angle = angle <= -90 ? 360 + angle : angle;
			angle = angle * (180 / Math.PI) + 16;
			return -angle;
		},
		handleMousedown() {
			this.isMouseDown = true;
			// let { clientX: x, clientY: y } = e;
			// this.btnCenter = [x, y];
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson5/images/bg_L5_normal.jpg");
}

.stoneBg {
	position: absolute;
	height: 69.444vh;
	top: 21.944vh;
	left: 50%;
	transform: translateX(-50%);
	cursor: pointer;
	z-index: 10;
}

.stave {
	position: absolute;
	height: 31vh;
	top: 45vh;
	left: 45%;
	transform: translateX(-50%);
	cursor: pointer;
	z-index: 11;
	opacity: 0;
	animation: fadeOut 1.5s both;
}

.notes {
	position: absolute;
	height: 31vh;
	top: 26vh;
	left: 48%;
	transform: translateX(-50%);
	cursor: pointer;
	z-index: 11;
	opacity: 0;
	animation: fadeIn 2s both;
	animation-delay: 1.5s;
}

.btn {
	position: absolute;
	height: 8.852vh;
	bottom: 15vh;
	left: 72.604vw;
	cursor: pointer;
	z-index: 12;
}
</style>
