<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson14/game-2')"
			class="next-btn"
		/>
		<div class="container">
			<div
				v-for="(item, index) in list"
				:key="index"
				class="item"
				:class="{ open: item.disable === true }"
				:style="item.style"
				@click="toggle(index)"
			>
				<img
					class="item-bg bud"
					:src="item.disable === true ? bud : item.img"
				/>
				<img
					class="item-note"
					:src="item.note"
					:class="`note-${item.type}`"
				/>
			</div>
		</div>
		<audio
			ref="audioPlayer"
			:src="audioSrc"
			style="display: none"
		></audio>
	</div>
</template>

<script>
class CreateBud {
	constructor(type = 1, x = 0, y = 0) {
		const notes = {
			1: require("./images/note_4.png"),
			2: require("./images/note_42.png"),
			4: require("./images/note_16.png"),
		};
		function angle(min = -15, max = 35) {
			return Math.floor(Math.random() * (max - min + 1) + min);
		}
		this.img = require("./images/bud.png");
		this.note = notes[type];
		this.type = type;
		this.disable = type === 4 ? false : "none";
		this.style = {
			bottom: `${y}vh`,
			left: `${x}vw`,
			transform: `scale(${
				Math.random() > 0.8 ? 0.8 : 1
			}) rotate(${angle()}deg)`,
			zIndex: type,
		};
	}
}
export default {
	data() {
		return {
			audioSrc: "",
			bud: require("./images/flower_blooming_right.png"),
			successAudio: require("./audio/花瓣开花.mp3"),
			errorAudio: require("./audio/错误警告.mp3"),
			list: [
				new CreateBud(4, 8, 40),
				new CreateBud(2, 15, 7),
				new CreateBud(1, 27, 26),
				new CreateBud(4, 37, 54),
				new CreateBud(1, 40, 8),
				new CreateBud(2, 50, 43),
				new CreateBud(4, 57, 8),
				new CreateBud(1, 67, 35),
				new CreateBud(2, 76, 6),
				new CreateBud(4, 80, 40),
			],
		};
	},
	methods: {
		playMusic(source) {
			if (source) {
				this.audioSrc = source;
				this.$nextTick(() => {
					this.$refs.audioPlayer.play();
				});
			}
		},
		toggle(index) {
			const result = this.list[index];
			if (result.disable === "none") {
				this.playMusic(this.errorAudio);
				return;
			}
			result.disable = !result.disable;
			if (result.disable) {
				this.playMusic(this.successAudio);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson14/images/bg.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.item {
		font-size: 0;
		position: absolute;
		cursor: pointer;
		display: flex;
		justify-content: center;
		&-bg {
			width: 155px;
		}
		&.open {
			align-items: center;
			margin-left: -80px;
			.bud {
				width: 380px;
			}
			.item-note {
				top: unset;
				&.note-4 {
					left: unset;
					width: 180px;
				}
			}
		}
		&-note {
			position: absolute;
			top: 60px;
			&.note-1 {
				top: 40px;
				width: 56px;
			}
			&.note-2 {
				left: 20px;
				width: 88px;
			}
			&.note-4 {
				left: 0;
				width: 130px;
			}
		}
	}
}
</style>
