<template>
  <div class="magic-book" @click="$emit('close')">
    <img class="book" src="./images/book.png"  />
    <img class="animal" :src="animalSrc"  />
  </div>
</template>

<script>
export default {
  name: "magic-book",
  props: {
    animalSrc: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.magic-book {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999; // 魔法书层级需要盖过页面不允许点击事件
  display: flex;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: inherit;
    // filter: blur(10px);
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    backdrop-filter: blur(4px);
  }
  .book {
    position: absolute;
    width: 942px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    animation: 2s linear linear-to-show;
  }
  .animal {
    position: absolute;
    width: 332px;
    height: auto;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    opacity: 0;
    animation: 2s linear 1.5s bounce-and-shine infinite alternate;
  }
}
@keyframes linear-to-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes bounce-and-shine {
  from {
    opacity: 1;
    transform: translate(-50%, calc(-50% - 20px));
  }
  to {
    opacity: 0.7;
    transform: translate(-50%, calc(-50% + 20px));
  }
}
</style>