<template>
  <div class="common-page">
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
      
    />
    <div class="lines-box">
      <div class="line">独角兽，闯荆棘。</div>
      <div class="line">身负伤，命旦夕。</div>
      <div class="line">他能救人，不救己。</div>
      <div class="line">蜂鸟啊，唯有你。</div>
      <div class="line">用歌声，能治愈。</div>
      <div class="line">快快停下，随我去。</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gonext() {
      if (this.$route.query.id == 123) {
        this.$router.push("/lesson2/page-4");
      } else {
        this.$router.push("/lesson2/game-2");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.lines-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("~@/assets/image/back03.png");
  width: 1000px;
  height: 520px;
  background-size: 100%;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 38px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  box-sizing: border-box;
  .line + .line {
    margin-top: 10px;
  }
}
</style>
