<template>
  <div class="common-page" >
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />
    <div class="imgtalk" >
      <div style="display: flex">
        <div class="lysic" >
          <div v-show="lysic01 == false" @click="changelysic(1)">噩梦</div>
          <img
            v-show="lysic01 == true"
            @click="changelysic1(1)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>
        <div class="lysic" >
          <div v-show="lysic03 == false" @click="changelysic(3)">噩梦</div>
          <img
            v-show="lysic03 == true"
            @click="changelysic1(3)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>
        <div class="lysic" >
          <div>,</div>
        </div>
        <div class="lysic" >
          <div v-show="lysic05 == false" @click="changelysic(5)">入梦</div>
          <img
            v-show="lysic05 == true"
            @click="changelysic1(5)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>
        <div class="lysic" >
          <div>乡</div>

        </div>
        <div class="lysic" >
          <div>。</div>
        </div>
      </div>
      <div class="contentmargin" style="display: flex">
        <div class="lysic" >
          <div>食梦貘</div>

        </div>

        <div class="lysic" >
          <div v-show="lysic14 == false" @click="changelysic(14)">在你</div>
          <img
            v-show="lysic14 == true"
            @click="changelysic1(14)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>

        <div class="lysic" >
          <div v-show="lysic16 == false" @click="changelysic(16)">枕边</div>
          <img
            v-show="lysic16 == true"
            @click="changelysic1(16)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>

        <div class="lysic" >
          <div>上</div>

        </div>
        <div class="lysic" >
          <div>。</div>
        </div>
      </div>
      <div style="display: flex">
        <div class="lysic" >
          <div v-show="lysic21 == false" @click="changelysic(21)">黑白</div>
          <img
            v-show="lysic21 == true"
            @click="changelysic1(21)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>
        <div class="lysic" >
          <div v-show="lysic23 == false" @click="changelysic(23)">相间</div>
          <img
            v-show="lysic23 == true"
            @click="changelysic1(23)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>

        <div class="lysic" >
          <div>,</div>
        </div>
        <div class="lysic" >
          <div v-show="lysic25 == false" @click="changelysic(25)">鼻子</div>
          <img
            v-show="lysic25 == true"
            @click="changelysic1(25)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>

        <div class="lysic" >
          <div>长</div>
        </div>
        <div class="lysic" >
          <div>。</div>
        </div>
      </div>
      <div class="contentmargin" style="display: flex">
        <div class="lysic" >
          <div v-show="lysic31 == false" @click="changelysic(31)">守护</div>
          <img
            v-show="lysic31 == true"
            @click="changelysic1(31)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>
        <div class="lysic" >
          <div>你</div>
        </div>
        <div class="lysic" >
          <div>,</div>
        </div>
        <div class="lysic" >
          <div v-show="lysic34 == false" @click="changelysic(34)">睡的</div>
          <img
            v-show="lysic34 == true"
            @click="changelysic1(34)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>
        <div class="lysic" >
          <div>香</div>

        </div>
        <div class="lysic" >
          <div>。</div>
        </div>
      </div>
      <div style="display: flex">
        <div class="lysic" >
          <div v-show="lysic41 == false" @click="changelysic(41)">吞噩</div>
          <img
            v-show="lysic41 == true"
            @click="changelysic1(41)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>

        <div class="lysic" >
          <div>梦</div>

        </div>
        <div class="lysic" >
          <div>,</div>
        </div>
        <div class="lysic" >
          <div v-show="lysic44 == false" @click="changelysic(44)">良夜</div>
          <img
            v-show="lysic44 == true"
            @click="changelysic1(44)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>

        <div class="lysic" >
          <div>长</div>
        </div>
        <div class="lysic" >
          <div>,</div>
        </div>
      </div>
      <div class="contentmargin" style="display: flex">
        <div class="lysic" >
          <div v-show="lysic51 == false" @click="changelysic(51)">整夜</div>
          <img
            v-show="lysic51 == true"
            @click="changelysic1(51)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>
        <div class="lysic" >
          <div v-show="lysic53 == false" @click="changelysic(53)">好梦</div>
          <img
            v-show="lysic53 == true"
            @click="changelysic1(53)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>

        <div class="lysic" >
          <div v-show="lysic55 == false" @click="changelysic(55)">到天</div>
          <img
            v-show="lysic55 == true"
            @click="changelysic1(55)"
            src="@/assets/image/sleepnote01.png"
            class="img2"
          />
        </div>
        <div class="lysic" >
          <div>亮</div>
        </div>
        <div class="lysic" >
          <div>。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lysic01: false,
      lysic02: false,
      lysic03: false,
      lysic04: false,
      lysic05: false,
      lysic06: false,
      lysic07: false,
      lysic11: false,
      lysic12: false,
      lysic13: false,
      lysic14: false,
      lysic15: false,
      lysic16: false,
      lysic17: false,
      lysic18: false,
      lysic21: false,
      lysic22: false,
      lysic23: false,
      lysic24: false,
      lysic25: false,
      lysic26: false,
      lysic27: false,
      lysic31: false,
      lysic32: false,
      lysic33: false,
      lysic34: false,
      lysic35: false,
      lysic36: false,
      lysic41: false,
      lysic42: false,
      lysic43: false,
      lysic44: false,
      lysic45: false,
      lysic46: false,
      lysic51: false,
      lysic52: false,
      lysic53: false,
      lysic54: false,
      lysic55: false,
      lysic56: false,
      lysic57: false,
    };
  },
  mounted() {
  },
  methods: {
    changelysic(val) {
      if (val == 1) {
        this.lysic01 = true;
      } else if (val == 2) {
        this.lysic02 = true;
      } else if (val == 3) {
        this.lysic03 = true;
      } else if (val == 4) {
        this.lysic04 = true;
      } else if (val == 5) {
        this.lysic05 = true;
      } else if (val == 6) {
        this.lysic06 = true;
      } else if (val == 7) {
        this.lysic07 = true;
      } else if (val == 11) {
        this.lysic11 = true;
      } else if (val == 12) {
        this.lysic12 = true;
      } else if (val == 13) {
        this.lysic13 = true;
      } else if (val == 14) {
        this.lysic14 = true;
      } else if (val == 15) {
        this.lysic15 = true;
      } else if (val == 16) {
        this.lysic16 = true;
      } else if (val == 17) {
        this.lysic17 = true;
      } else if (val == 18) {
        this.lysic18 = true;
      } else if (val == 21) {
        this.lysic21 = true;
      } else if (val == 22) {
        this.lysic22 = true;
      } else if (val == 23) {
        this.lysic23 = true;
      } else if (val == 24) {
        this.lysic24 = true;
      } else if (val == 25) {
        this.lysic25 = true;
      } else if (val == 26) {
        this.lysic26 = true;
      } else if (val == 27) {
        this.lysic27 = true;
      } else if (val == 31) {
        this.lysic31 = true;
      } else if (val == 32) {
        this.lysic32 = true;
      } else if (val == 33) {
        this.lysic33 = true;
      } else if (val == 34) {
        this.lysic34 = true;
      } else if (val == 35) {
        this.lysic35 = true;
      } else if (val == 36) {
        this.lysic36 = true;
      } else if (val == 41) {
        this.lysic41 = true;
      } else if (val == 42) {
        this.lysic42 = true;
      } else if (val == 43) {
        this.lysic43 = true;
      } else if (val == 44) {
        this.lysic44 = true;
      } else if (val == 45) {
        this.lysic45 = true;
      } else if (val == 46) {
        this.lysic46 = true;
      } else if (val == 51) {
        this.lysic51 = true;
      } else if (val == 52) {
        this.lysic52 = true;
      } else if (val == 53) {
        this.lysic53 = true;
      } else if (val == 54) {
        this.lysic54 = true;
      } else if (val == 55) {
        this.lysic55 = true;
      } else if (val == 56) {
        this.lysic56 = true;
      } else if (val == 57) {
        this.lysic57 = true;
      }
    },
    changelysic1(val) {
      if (val == 1) {
        this.lysic01 = false;
      } else if (val == 2) {
        this.lysic02 = false;
      } else if (val == 3) {
        this.lysic03 = false;
      } else if (val == 4) {
        this.lysic04 = false;
      } else if (val == 5) {
        this.lysic05 = false;
      } else if (val == 6) {
        this.lysic06 = false;
      } else if (val == 7) {
        this.lysic07 = false;
      } else if (val == 11) {
        this.lysic11 = false;
      } else if (val == 12) {
        this.lysic12 = false;
      } else if (val == 13) {
        this.lysic13 = false;
      } else if (val == 14) {
        this.lysic14 = false;
      } else if (val == 15) {
        this.lysic15 = false;
      } else if (val == 16) {
        this.lysic16 = false;
      } else if (val == 17) {
        this.lysic17 = false;
      } else if (val == 18) {
        this.lysic18 = false;
      } else if (val == 21) {
        this.lysic21 = false;
      } else if (val == 22) {
        this.lysic22 = false;
      } else if (val == 23) {
        this.lysic23 = false;
      } else if (val == 24) {
        this.lysic24 = false;
      } else if (val == 25) {
        this.lysic25 = false;
      } else if (val == 26) {
        this.lysic26 = false;
      } else if (val == 27) {
        this.lysic27 = false;
      } else if (val == 31) {
        this.lysic31 = false;
      } else if (val == 32) {
        this.lysic32 = false;
      } else if (val == 33) {
        this.lysic33 = false;
      } else if (val == 34) {
        this.lysic34 = false;
      } else if (val == 35) {
        this.lysic35 = false;
      } else if (val == 36) {
        this.lysic36 = false;
      } else if (val == 41) {
        this.lysic41 = false;
      } else if (val == 42) {
        this.lysic42 = false;
      } else if (val == 43) {
        this.lysic43 = false;
      } else if (val == 44) {
        this.lysic44 = false;
      } else if (val == 45) {
        this.lysic45 = false;
      } else if (val == 46) {
        this.lysic46 = false;
      } else if (val == 51) {
        this.lysic51 = false;
      } else if (val == 52) {
        this.lysic52 = false;
      } else if (val == 53) {
        this.lysic53 = false;
      } else if (val == 54) {
        this.lysic54 = false;
      } else if (val == 55) {
        this.lysic55 = false;
      } else if (val == 56) {
        this.lysic56 = false;
      } else if (val == 57) {
        this.lysic57 = false;
      }
    },
    gonext() {
      this.$router.push("/lesson3/game-5");
    },
  },
};
</script>

<style scoped>
.contentmargin {
  margin: 15px 0;
}
.lysic {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.img2 {
  width: 35px;
  height: 37px;
}

.marginl1 {
  margin-top: 10px;
}

.marginl {
  margin: 10px 0;
}

.img1 {
  width: 148px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.common-page {
  background-image: url("~@/assets/image/back04.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
}

.imgtalk {
  background-image: url("~@/assets/image/back06.png");
  width: 1000px;
  height: 520px;
  background-size: 100%;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 38px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  box-sizing: border-box;
}
</style>
