import { Resize } from "@/utils/Resize";

export class DrawLine {
	constructor() {
		this.canvas = document.createElement("canvas");
		const style = {
			width: "100%",
			height: "100%",
			position: "fixed",
			top: 0,
			left: 0,
		};
		Object.assign(this.canvas.style, style);
		this.canvas.onmousedown = this._start.bind(this);
		this.canvas.onmousemove = this._move.bind(this);
		this.canvas.onmouseup = this._end.bind(this);
		document.body.appendChild(this.canvas);
		this.isDraw = false;
		this.history = [];
		this.cacheEvent = new Set();
		this.resize = new Resize();
		this.resize.on(this.setCanvas.bind(this));
		this.setCanvas();
	}

	_start({ pageX, pageY }) {
		this.isDraw = true;
		for (const event of this.cacheEvent) {
			typeof event === "function" && event(pageX, pageY);
		}
		const imageData = this.ctx.getImageData(
			0,
			0,
			this.canvas.width,
			this.canvas.height
		);
		this.history.push(imageData);
		this.ctx.beginPath();
	}

	_move({ pageX, pageY }) {
		if (this.isDraw) {
			this.draw(pageX, pageY);
		}
	}

	_end() {
		this.isDraw = false;
	}

	/**
	 * 设置canvas获取context上下文
	 */
	setCanvas() {
		const { width, height } = this.canvas.getBoundingClientRect();
		this.ctx = this.canvas.getContext("2d", { willReadFrequently: true });
		this.canvas.width = width;
		this.canvas.height = height;
		this.ctx.lineWidth = 5;
		this.ctx.lineCap = "round";
		this.ctx.strokeStyle = "#fff";
		this.history.length = 0;
	}

	/**
	 *
	 * 监听点击需要执行的方法
	 * @param {Function} event
	 */
	on(event) {
		this.cacheEvent.add(event);
	}

	/**
	 * 画线
	 * @param {number} x
	 * @param {number} y
	 */
	draw(x, y) {
		this.ctx.lineTo(x, y);
		this.ctx.stroke();
	}

	/**
	 * 撤回
	 */
	back() {
		const imageData = this.history.pop();
		if (imageData) {
			this.ctx.putImageData(imageData, 0, 0);
		}
	}

	/**
	 * 清空画布
	 */
	clean() {
		this.history.length = 0;
		this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
	}

	/**
	 * 销毁实例
	 */
	destroy() {
		this.cacheEvent.clear();
		this.history.length = 0;
		this.canvas.remove();
		this.resize.unListen();
	}
}
