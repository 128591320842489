<template>
	<div class="common-page">
		<img
			class="next-btn"
			src="@/assets/image/next.png"
			@click.stop="gonext()"
		/>
		<img
			class="content-bg"
			src="./images/L7_flute.png"
			alt=""
		/>
		<div class="content-box">
			<div
				class="notes-line"
				v-for="(line, lIndex) of contentLines"
				:key="lIndex"
			>
				<div
					class="notes-box"
					v-for="(src, sIndex) of line"
					:key="sIndex"
				>
					<img
						v-if="src"
						:style="{
							cursor: src?.type == 'change' ? 'pointer' : 'auto',
						}"
						:src="src?.type == 'change' ? src.src : src"
						@click="changeState(src)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
let highlightSolidNotes = require("./images/L7_note_02.png");
let highlightEmptyNotes = require("./images/L7_note_04.png");

let solidNotes = require("./images/L7_note_01.png");
let emptyNotes = require("./images/L7_note_03.png");

let line = require("./images/L7_note_05.png");

export default {
	components: {},
	data() {
		let sn = solidNotes;
		let en = emptyNotes;

		// let hsn = highlightSolidNotes;
		let hen = highlightEmptyNotes;
		return {
			highlightSolidNotes,
			highlightEmptyNotes,
			contentLines: [
				[
					sn,
					sn,
					sn,
					sn,
					line,
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					line,
				],
				[
					sn,
					sn,
					sn,
					sn,
					line,
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					line,
				],
				[
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					null,
					line,
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					line,
				],
				[
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					null,
					line,
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					line,
				],
				[
					sn,
					sn,
					sn,
					sn,
					line,
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					line,
				],
				[
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					null,
					line,
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					line,
				],
				[
					sn,
					sn,
					sn,
					sn,
					line,
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					line,
				],
				[
					sn,
					sn,
					sn,
					sn,
					line,
					sn,
					sn,
					{ src: en, activeSrc: hen, type: "change" },
					line,
				],
			],
		};
	},
	computed: {},
	mounted() {},
	methods: {
		gonext() {
			this.$router.push("/lesson7/page?page=4");
		},
		changeState(obj) {
			if (obj.type == "change") {
				let { src, activeSrc } = obj;
				// 交换状态
				obj.src = activeSrc;
				obj.activeSrc = src;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("./images/bg_L7_start.jpg");
}

.content-bg {
	position: absolute;
	height: 100vh;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10;
}

.content-box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 1em;
	border-radius: 1em;
	background: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(4px);
	box-shadow: 0px 0px 0.667em 0px rgba(7, 0, 2, 0.5);
	z-index: 10;
}

.notes-line {
	display: flex;
	align-items: center;
}

.notes-box {
	width: 3.125vw;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
