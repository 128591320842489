<template>
  <img
    v-if="element.type == 'img'"
    :src="element.src"
    :style="element.style"
  />
</template>

<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    element: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.wrapper {
}
</style>
