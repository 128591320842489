<template>
  <div class="common-page">
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />

    <div class="custom-audio">
      <audio
        ref="audioTip"
        :src="audio.src"
        controls
        autoplay
      ></audio>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audio: {
        // 消息通知
        src: require("@/assets/music/yaolanqu.mp3"),
      },
    };
  },

  mounted() {
    this.$refs.audioTip.play();
  },
  methods: {
    gonext() {
      this.$router.push("/lesson4/page-4");
    },
  },
};
</script>

<style scoped>
.common-page {
  background-image: url("~@/assets/image/back04.png");
  background-size: 100%;
  width: 100vw;
  height: 100vh;
  /* background-repeat: no-repeat; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.custom-audio {
  
 position: absolute;
 bottom: 140px;
}
</style>