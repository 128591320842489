const pageBg = require("@/views/lesson5/images/bg_L5_normal.jpg");
const startPageBg = require("@/views/lesson5/images/bg_L5_start.jpg");
const pageNextBtnImage = require("@/assets/image/next.png");
const roles = (scale) => [
	{
		roleClass: "magician",
		imgSrc: require("@/assets/image/role/magician.png"),
		style: {
			width: "auto",
			left: "8.645vw",
			bottom: "12.314vh",
			zIndex: 7,
		},
		roleStyle: {
			height: `${49.537 * scale}vh`,
		},
	},
	{
		roleClass: "musician",
		imgSrc: require("@/assets/image/role/musician.png"),
		style: {
			width: "auto",
			left: "34.989vw",
			bottom: "10.555vh",
			zIndex: 8,
		},
		roleStyle: {
			height: `${60.463 * scale}vh`,
		},
	},
	{
		roleClass: "cattle",
		imgSrc: require("@/views/lesson5/images/cattle.png"),
		style: {
			width: "auto",
			left: "46.375vw",
			bottom: "9.907vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${40.741 * scale}vh`,
		},
	},
	{
		roleClass: "knight",
		imgSrc: require("@/assets/image/role/knight.png"),
		style: {
			width: "auto",
			left: "75.416vw",
			bottom: "11.203vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${57.5 * scale}vh`,
		},
	},
];
export default {
	lessonStart: {
		// 首页
		pageBg: startPageBg, // 首页背景
		pageTitleImage: require("../images/L6_title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson6/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson6/page?page=2",
		roles: roles(1),
		audio: {
			src: require("../audio/旁白1.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"真有意思。看来雪山的回应有着很不一样的节奏，我们需要把它重新编排一下，才能理解他要和我们说什么。",
				audio: require("../audio/宫廷1.mp3"),
			},
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson6/game-1",
		// roles: roles(1),
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/L6_念谣_bg.png"),
				style: {
					position: "absolute",
					width: "35.787vw",
					top: "11.667vh",
					left: "30.468vw",
					cursor: "pointer",
					zIndex: 10,
				},
			},
			{
				type: "text-lines",
				style: {
					position: "absolute",
					left: "39vw",
					top: "35vh",
					fontSize: "2.604vw",
					fontFamily: "Alimama ShuHeiTi",
					fontWeight: "bold",
					color: "#9A5000",
					lineHeight: "1.36em",
					zIndex: 11,
				},
				textLines: [
					{
						style: {},
						text: "山中是否有神明？",
					},
					{
						style: {},
						text: "我问他答听不清。",
					},
					{
						style: {},
						text: "山谷回声声似我，",
					},
					{
						style: {},
						text: "湖中倒影是我形。",
					},
				],
			},
		],
	},
	3: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson6/page?page=4",
		audio: {
			src: require("../audio/旁白2.mp3"),
			autoplay: true,
			ended: (vm) => {
				let audio = vm.$refs.audioPlayer;
				vm.$nextTick(() => {
					audio.currentTime = 0;
					audio.play();
				});
			},
		},
		audioPlayer: {
			src: require("../audio/石头上谱子背景音乐.mp3"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "7vh",
			},
		},
		// roles: roles(1),
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/L6_stonebg.png"),
				style: {
					position: "absolute",
					height: "69.444vh",
					top: "21.944vh",
					left: "50%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/L6_stave_on_stone.png"),
				style: {
					position: "absolute",
					height: "31vh",
					top: "45vh",
					left: "45%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					zIndex: 11,
				},
			},
		],
	},
	4: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson6/page?page=5",
		roles: roles(1),
		audio: {
			src: require("../audio/旁白3.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"你们看，水底的种子在泥中生根发芽，长出了水面开出了小白花，这里面也有我们要找出的秘密，下面我来把它们变成我们认识的样子，请大家找找谁是种子谁是花呢？",
				// （宫廷乐师施展魔法，湖底、湖面和地面形成了一个五线谱的平面图
				// --刚才唱过的五线谱配上图案和颜色：湖底长着高高低低的种子
				// --就是刚才的五线谱，湖面--是三线，上面的白花--是“i”）",
				audio: require("../audio/宫廷2.mp3"),
			},
			{
				isBreak: true,
				callback: (vm) => {
					vm.showMagic = true;
					setTimeout(() => {
						vm.showMagic = false;
						vm.$router.push("/lesson6/page?page=5");
					}, 2800);
				},
			},
		],
	},
	5: {
		pageBg: startPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson6/game-2",
		audioPlayer: {
			src: require("../audio/石头上谱子背景音乐.mp3"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "7vh",
			},
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/paper_bg.png"),
				style: {
					position: "absolute",
					width: "86vw",
					top: "21.944vh",
					left: "50%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/music.png"),
				style: {
					position: "absolute",
					height: "35vh",
					top: "26vh",
					left: "50%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					zIndex: 11,
				},
			},
		],
	},
	6: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson6/page?page=7",
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/L6_念谣_bg_2.png"),
				style: {
					position: "absolute",
					width: "45.156vw",
					top: "54%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
			{
				type: "text-lines",
				style: {
					position: "absolute",
					top: "44%",
					left: "52%",
					transform: "translate(-50%,-50%)",
					fontSize: "2.604vw",
					fontFamily: "Alimama ShuHeiTi",
					fontWeight: "400",
					color: "#9A5000",
					lineHeight: "1.36em",
					zIndex: 11,
				},
				textLines: [
					{
						text: "湖面之下杆朝上，",
					},
					{
						text: "湖面之上杆朝下，",
					},
					{
						text: "湖面中央看旁边，",
					},
					{
						text: "大家一起选方向。",
					},
				],
			},
		],
	},
	7: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson6/page?page=8",
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/L6_stave_lines.png"),
				style: {
					position: "absolute",
					width: "57vw",
					bottom: "4vh",
					left: "50%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
		],
	},
	8: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson7/page",
		roles: roles(1),
		isEnd: true,
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"在水面上面的花和在水面下面的种子其实是有秘密的。有一个种子和花的秘诀要记住哦～",
				audio: require("../audio/宫廷5.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "而湖面就是我们五线谱的三线哦～",
				audio: require("../audio/宫廷6.mp3"),
			},
			{
				talker: "cattle",
				talkerCHN: "牦牛",
				content: "可这到底是什么意思呢？山中的神仙到底想要告诉我什么呢？",
				audio: require("../audio/牦牛1.mp3"),
				style: {
					backgroundImage: `url(${require("@/views/lesson5/images/cattle-chat-box.png")}`,
				},
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"你听这回音。你看这湖面上的花。其实你听到的是你自己的声音。湖面上漂浮的花朵也都是它自己的种子开出来的花。",
				audio: require("../audio/宫廷7.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "当我们向远山寻求神仙的时候。或许我们看到的正是我们自己。",
				audio: require("../audio/宫廷8.mp3"),
			},
			{
				audio: require("../audio/旁白4.mp3"),
			},
			{
				isBreak: true,
				callback() {
					// vm.$set(vm.pageConfig.roles[2], 'imgSrc', require('../images/牦牛_白色.png'));
					document.querySelector(".role-box.cattle-box").style =
						"width: auto;left: 44vw;bottom: 10vh;z-index: 90";
					document
						.querySelector(".role-box.cattle-box .cattle")
						.setAttribute("src", require("../images/牦牛_白色.png"));
					document.querySelector(".role-box.cattle-box .cattle").style =
						"transition-duration: 2s;height: 45vh";
				},
			},
			{
				talker: "cattle",
				talkerCHN: "牦牛",
				content:
					"谢谢你们，让我领悟到，有时我们寻找的神明就在我们自己的内心里面。我们更应该更好的认识自己。是你们让我恢复到了这神圣的白色。",
				audio: require("../audio/牦牛2.mp3"),
				style: {
					backgroundImage: `url(${require("../images/dialog_box_bg_白色牦牛.png")}`,
				},
			},
			{
				talker: "cattle",
				talkerCHN: "牦牛",
				content:
					"我把我的祝福送给你们。希望你们能将这种智慧传承下去。当我们疑惑的时候可以看看自己内心的选择。",
				audio: require("../audio/牦牛3.mp3"),
				style: {
					backgroundImage: `url(${require("../images/dialog_box_bg_白色牦牛.png")}`,
				},
			},
			{
				isBreak: true,
				callback: (vm) => {
					vm.animalDiamond = require("../images/祝福-牦牛.png");
					vm.showMagicBook = true;
				},
			},
		],
	},
};
