<template>
  <div class="common-game-page">
    <img
      src="@/assets/image/next.png"
      class="next-btn"
      @click.stop="gonext()"
    />
    <img class="game-title" src="@/assets/image/playtime-1.png" />
    <img class="tiaozao" src="@/assets/image/role/tiaozao.png" alt="" />
    <div class="custom-audio">
      <audio
        class="audio"
        ref="audioTip"
        :src="audio.src"
        controls
        autoplay
      ></audio>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audio: {
        // 消息通知
        src: require("@/assets/music/xiao-tiao-zao-yuanchang.mp3"),
      },
    };
  },
  mounted() {},
  methods: {
    gonext() {
      this.$router.push("/lesson4/game-3");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/game-page.scss";
.common-game-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-image: url("~@/assets/image/back04.png");
  .custom-audio {
    position: absolute;
    bottom: 100px;
  }
  .tiaozao {
    position: absolute;
    width: 800px;
    bottom: 200px;
    left: 280px;
  }
}
</style>