<template>
	<div>
		<!-- <router-link to="about">11</router-link> -->
		<div class="app-title" v-if="showLog">
			<div
				style="display: flex; align-items: center"
				@click="changetitlestate()"
			>
				<img
					src="@/assets/image/catalogue.png"
					class="catalogue"
				/>
			</div>
			<div
				class="sessiontitle"
				v-show="titlestate"
			>
				<div
					class="imgtitle"
					v-for="(menu, mindex) of menusmodel"
					:key="mindex"
					@click="toLessonPageHome(menu.path)"
				>
					{{ menu.name }}
				</div>
			</div>
		</div>
		<router-view />
	</div>
</template>
<script>
import { getUser } from "@/utils/auth.js";
import {musicAuthTokenAPI} from "@/api/login"
export default {
	data() {
		return {
			windowWidth: window.screen.height, // 实时屏幕宽度
			windowHeight: window.screen.height, // 实时屏幕高度
			titlestate: false,
			showLog:false,
			hasToken:false,
			timer:'',// 接收定时器
			menus: [
				{
					name: "独角兽(上)",
					path: "/lesson1/page-home",
				},
				{
					name: "独角兽(下)",
					path: "/lesson2/page-home",
				},
				{
					name: "食梦貘(上)",
					path: "/lesson3/page-home",
				},
				{
					name: "食梦貘(下)",
					path: "/lesson4/page-home",
				},
				{
					name: "牦牛(上)",
					path: "/lesson5/page",
				},
				{
					name: "牦牛(下)",
					path: "/lesson6/page",
				},
				{
					name: "雄鹰(上)",
					path: "/lesson7/page",
				},
				{
					name: "雄鹰(下)",
					path: "/lesson8/page",
				},
				{
					name: "山顶神殿遇佐罗(上)",
					path: "/lesson9/page",
				},
				{
					name: "山顶神殿遇佐罗(下)",
					path: "/lesson10/page",
				},
				{
					name: "魔法列车事件",
					path: "/lesson11/page",
				},
				{
					name: "熊猫的试炼(上)",
					path: "/lesson12/page",
				},
				{
					name: "熊猫的试炼(下)",
					path: "/lesson13/page",
				},
				{
					name: "破茧成蝶(上)",
					path: "/lesson14/page",
				},
				{
					name: "破茧成蝶(下)",
					path: "/lesson15/page",
				},
				{
					name: "魔法学院之旅",
					path: "/lesson16/page",
				},
				{
					name: "助人为乐的海豚(上)",
					path: "/lesson17/page",
				},
				{
					name: "助人为乐的海豚(下)",
					path: "/lesson18/page",
				},
				{
					name: "爱学习的海豹(上)",
					path: "/lesson19/page",
				},
				{
					name: "爱学习的海豹(下)",
					path: "/lesson20/page",
				},
				{
					name: "鲸鱼与神殿(上)",
					path: "/lesson21/page",
				},
				{
					name: "鲸鱼与神殿(下)",
					path: "/lesson22/page",
				},
				{
					name: "生命音符争夺战(上)",
					path: "/lesson23/page",
				},
				{
					name: "生命音符争夺战(下)",
					path: "/lesson24/page",
				},
			],
			menusmodel:[],
		};
	},
	created(){
		this.menusmodel = this.menus
		this.timer = setInterval(()=>{
			// musicAuthTokenAPI({
            //     token: getUser().token,
            //     userId: getUser().id,
            // }).then(res => {
            // })
			this.showLog = getUser().token?true:false
			if(getUser().token){
				if(getUser().name == 'test'){
					this.menusmodel = this.menus.slice(0,2)
				}
				clearInterval(this.timer);
			}else{
				this.$router.push({
					path:'/Login',
					query:{type:'1'}
				}
				)
				
			}
		},1500)
	},
	methods: {
		changetitlestate() {
			if (this.titlestate == true) {
				this.titlestate = false;
			} else {
				this.titlestate = true;
			}
		},
		toLessonPageHome(path) {
			this.$router.push(path);
			this.titlestate = false;
		},
	},
};
</script>
<style lang="scss">
.app-title {
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 999;
	cursor: pointer;
	.catalogue {
		width: 160px;
	}
}
.sessiontitle {
	transform: translateY(-10px);
	scroll-behavior: auto;
	height: 82vh;
	overflow-y: auto;
}
.imgtitle {
	width: 190px;
	height: 48px;
	background-image: url("~@/assets/image/btnlistbg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	font-size: 18px;
	font-weight: 700;
	display: flex;
	align-items: center;
	padding-left: 30px;
	box-sizing: border-box;
	margin-bottom: 5px;
	color: #fff;
	&:hover {
		background-image: url("~@/assets/image/btnlistbghover.png");
		display: flex;
		color: #864c00;
	}
}
</style>
