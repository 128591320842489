<template>
  <div class="common-page" >
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />
    <div class="imgtalk" >
      <img src="@/assets/image/sleepnote04.png" class="img2" />
      <div class="imgmargin">
        <img src="@/assets/image/sleepnote02.png" class="img3" />
        <img src="@/assets/image/sleepnote03.png" class="img4" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gonext() {
      this.$router.push("/lesson3/page-5");
    },
  },
};
</script>

<style scoped>
.imgmargin {
  margin-top: 90px;
}
.img4 {
  width: 130px;
  height: 135px;
}
.img3 {
  width: 130px;
  height: 135px;
  margin-right: 100px;
}
.img2 {
  width: 300px;
  height: 77px;
  margin-top: 50px;
}
.common-page {
  background-image: url("~@/assets/image/back04.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
}

.imgtalk {
  background-image: url("~@/assets/image/back06.png");
  width: 1000px;
  height: 520px;
  background-size: 100%;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 38px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  box-sizing: border-box;
}
</style>
