<template>
	<div
		class="common-page"
		id="game-page-wrapper"
	>
		<img
			class="next-btn"
			src="@/assets/image/next.png"
			@click.stop="gonext()"
		/>
		<div class="center-img">
			<img
				src="./images/L7_stave_clawprint.png"
				alt=""
				srcset=""
			/>
		</div>
		<svg
			id="svg-panel"
			:viewbox="[0, 0, screenWidth, screenHeight].join(' ')"
			class="drawer-pannel"
			@click="svgClicked"
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
		>
			<polyline
				:points="transformSvgRatio.join(' ')"
				style="fill: none; stroke: #fff; stroke-width: 8"
			/>
		</svg>
		<div class="bottom-btns">
			<div
				class="custom-btn"
				@click="redo()"
			>
				撤回
			</div>
			<div
				class="custom-btn"
				@click="cleanScreen"
			>
				清屏
			</div>
			<div
				class="custom-btn"
				@click="showAnswer()"
			>
				查看答案
			</div>
		</div>
		<div class="custom-audio">
			<audio
				ref="audioPlayer"
				src="./audio/1-5356535.mp3"
				controls
			></audio>
		</div>
	</div>
</template>

<script>
import { DrawLine } from "@/DrawLine";
export default {
	components: {},
	data() {
		return {
			drawLine: new DrawLine(),
			svgPath: [],
			screenWidth: 0,
			screenHeight: 0,
			answer: [
				[0.22920021470746108, 0.4503735325506937],
				[0.31186258722490606, 0.5997865528281751],
				[0.41599570585077833, 0.4599786552828175],
				[0.509393451422437, 0.3874066168623266],
				[0.6049382716049383, 0.4567769477054429],
				[0.6935050993022007, 0.6104589114194237],
				[0.7820719269994633, 0.47491995731056563],
			],
		};
	},
	computed: {
		transformSvgRatio() {
			let pointsArr = this.svgPath.map((point) => {
				let [xRatio, yRatio] = point;
				// 按比例缩计算实际坐标，实现缩放屏幕后连线准确
				return [xRatio * this.screenWidth, yRatio * this.screenHeight].join(
					","
				);
			});
			return pointsArr;
		},
	},
	mounted() {
		let _this = this;
		let dom = document.querySelector("#game-page-wrapper");
		// eslint-disable-next-line no-unused-vars
		const resizeObserver = new ResizeObserver((entries) => {
			let width = getComputedStyle(dom).getPropertyValue("width");
			let height = getComputedStyle(dom).getPropertyValue("height");
			if (width === _this.screenWidth && height === _this.screenHeight) return;

			_this.screenWidth = parseInt(width);
			_this.screenHeight = parseInt(height);
		});

		resizeObserver.observe(dom);
		this.$once("hook:destroy", () => {
			// 取消监听
			resizeObserver.disconnect();
		});
	},
	beforeDestroy() {
		this.drawLine.destroy();
		if (this.observer) {
			this.observer.disconnect();
			this.observer.takeRecords();
			this.observer = null;
		}
	},
	methods: {
		gonext() {
			this.$router.push("/lesson7/game-2");
		},
		svgClicked(e) {
			let { clientX: x, clientY: y } = e;
			let xRatio = x / this.screenWidth;
			let yRatio = y / this.screenHeight;
			// 只保存比例，便于在屏幕缩放后重绘
			this.svgPath.push([xRatio, yRatio]);
		},
		redo() {
			this.drawLine.back();
			this.svgPath.pop();
		},
		showAnswer() {
			this.drawLine.clean();
			this.svgPath = JSON.parse(JSON.stringify(this.answer));
		},
		cleanScreen() {
			this.svgPath = [];
			this.drawLine.clean();
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("./images/bg_L7_start.jpg");
}

.center-img {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 65vw;
	transform: translate(-50%, -50%);
	z-index: 10;
	pointer-events: none;

	img {
		width: 65vw;
		pointer-events: none;
	}
}

.drawer-pannel {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
}

.bottom-btns {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 220px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;

	.custom-btn {
		& + .custom-btn {
			margin-left: 100px;
		}
	}
}
.custom-audio {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}
</style>
