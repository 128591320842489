<template>
	<div
		class="common-page"
		@click="changetalk(1)"
	>
		<LessonEnd
			v-if="isEnd"
			nextLessonPath="/lesson2/page-home"
		/>
		<img
			class="next-btn"
			src="@/assets/image/next.png"
			@click.stop="gonext()"
		/>
		<div class="horse-box">
			<img
				class="horse"
				src="@/assets/image/role/dujiaoshou.png"
			/>
		</div>
		<div class="musician-box">
			<img
				class="musician"
				src="@/assets/image/role/musician.png"
			/>
		</div>
		<ChatDialog
			:talkList="talkList"
			:talkIndex="talkIndex"
			@audioStateChange="(state) => (isTalking = state)"
		></ChatDialog>
		<!-- <video
			muted
			loop
			autoplay
			ref="lightRayBg"
			class="light-ray-bg"
			src="@/assets/video/light-ray-bg.mp4"
		></video> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			talkList: [
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content: "所以，你绕过这片树林就会听到蜂鸟的歌声。",
					audio: require("@/assets/audio/lesson1/page5-horse-voice6.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content:
						"你们循着这歌声就可以找到蜂鸟的位置。把他带到这里来，他知道如何治愈我的伤口。",
					audio: require("@/assets/audio/lesson1/page5-horse-voice7.mp3"),
				},
				{
					talker: "musician",
					talkerCHN: "宫廷乐师",
					content:
						"你在这里休息吧，我们找到蜂鸟，就立刻把他带来，为你治疗伤口。",
					audio: require("@/assets/audio/lesson1/page5-musician-voice5.mp3"),
				},
				{
					talker: "horse",
					talkerCHN: "独角兽",
					content: "谢谢你们，希望你们旅途顺利。",
					audio: require("@/assets/audio/lesson1/page5-horse-voice8.mp3"),
				},
			],
			talkIndex: -1,
			isTalking: false,
			isEnd: false,
		};
	},
	mounted() {},
	methods: {
		gonext() {
			this.isEnd = true;
		},
		changetalk(val) {
			if (this.isTalking) {
				// 正在读旁白或者正在播放对话内容
				return;
			}
			if (this.talkIndex !== 13) {
				this.talkIndex += val;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.common-page {
	background-image: url("~@/assets/image/独角兽金色的血液2.jpg");
	.musician-box,
	.horse-box {
		position: absolute;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		align-items: flex-end;
	}
	.horse-box {
		height: 524px;
		left: 280px;
		bottom: 200px;
		align-items: flex-start;
	}
	.musician-box {
		left: 800px;
		bottom: 240px;
		width: 300px;
	}
}
</style>
