const pageBg = require("@/views/lesson16/images/bg.png");
// const startPageBg = require("@/views/lesson16/images/bg_blur.png");
const buildPageBg = require("@/views/lesson16/images/buildbg.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "42vw",
				bottom: "2vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "24vw",
				bottom: "3vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${31 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "19vw",
				bottom: "0",
				zIndex: 9,
			},
			roleStyle: {
				height: `${44 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

export default {
	lessonStart: {
		// 首页
		pageBg, // 首页背景
		pageTitleImage: require("@/views/lesson16/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson16/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson16/page?page=2",
		audio: {
			src: require("../audio/旁白01.mp3"),
			autoplay: true,
		},
	},
	2: {
		pageBg: buildPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson16/page?page=3",
		roles: roles(1.1),
		talkList: [
			{
				talker: "butterfly",
				talkerCHN: "蝴蝶夫人",
				content:
					"我就送你们到这里了。不过魔法学院入学都会有一个小小的考验，能不能通过要看你们的本事了。",
				audio: require("../audio/蝴蝶1.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "请问是什么样的考验呢？",
				audio: require("../audio/宫1.mp3"),
			},
			{
				talker: "butterfly",
				talkerCHN: "蝴蝶夫人",
				content:
					"每个人来到这里都要找到自己的房间入住，然后魔法学院的房间是有着魔法能量的，他很神秘，是第一人院长施展的隐形咒语。你们只有破解了这个魔法才能找到你们各自的房间。",
				audio: require("../audio/蝴蝶2-01.mp3"),
			},
			{
				talker: "butterfly",
				talkerCHN: "蝴蝶夫人",
				content: "你们先去吧，找到房间之后，我会来告诉你们下一个神殿的线索。",
				audio: require("../audio/蝴蝶2-02.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/handrail.png"),
				style: {
					position: "absolute",
					width: "100vw",
					bottom: 0,
					left: 0,
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/butterfly.png"),
				style: {
					position: "absolute",
					width: "36vw",
					bottom: "30vh",
					right: "0",
				},
			},
		],
	},
	3: {
		pageBg: buildPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson16/game-1",
		roles: roles(1.1, [0], {
			0: {
				roleClass: "musician",
				imgSrc: require("@/assets/image/role/musician.png"),
				style: {
					width: "auto",
					left: "74vw",
					bottom: "2vh",
					zIndex: 8,
				},
				roleStyle: {
					height: `${40 * 1.1}vh`,
				},
			},
		}),
		audio: {
			src: require("../audio/旁白02-说完，蝴蝶夫人就消失了.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "嗯。。。这个应该难不住我。",
				audio: require("../audio/魔法师01.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "先不要说大话，我们试试看吧。",
				audio: require("../audio/宫2.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "你们看这个走廊很长，隐藏的门就应该在这里。",
				audio: require("../audio/魔法师02.mp3"),
			},
			// TODO: 少一个法字
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "我们来试试这个新的显性魔法。",
				audio: require("../audio/宫3.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/handrail.png"),
				style: {
					position: "absolute",
					width: "100vw",
					bottom: 0,
					left: 0,
					zIndex: 10,
				},
			},
		],
	},
	4: {
		pageBg: buildPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson16/page?page=5",
		roles: roles(1.1, [0], {
			0: {
				roleClass: "musician",
				imgSrc: require("@/assets/image/role/musician.png"),
				style: {
					width: "auto",
					left: "74vw",
					bottom: "2vh",
					zIndex: 8,
				},
				roleStyle: {
					height: `${40 * 1.1}vh`,
				},
			},
		}),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "太好了，这样我们就成功的找到了各自的房间了。",
				audio: require("../audio/宫4.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/handrail.png"),
				style: {
					position: "absolute",
					width: "100vw",
					bottom: 0,
					left: 0,
					zIndex: 10,
				},
			},
		],
	},
	5: {
		pageBg: buildPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson16/page?page=6",
		roles: roles(1.1),
		audio: {
			src: require("../audio/旁白03-蝴蝶夫人突然出现.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "butterfly",
				talkerCHN: "蝴蝶夫人",
				content:
					"你们果然没有让我失望，其实一个月前你们的国王就已经给我写信告诉我你们会来到这里。我听说宫廷乐师不仅精通音律，更可以熟练使用魔法。所以才给了你们这样的一个考验。",
				audio: require("../audio/蝴蝶3.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"哈哈哈，在魔法学院院长面前，没有人敢说自己精通魔法。不过我们的任务很紧急，请您告诉我们最后一个神殿的线索。",
				audio: require("../audio/宫5.mp3"),
			},
			{
				audio: require("../audio/旁白04-蝴蝶夫人拿出了一个卷轴.mp3"),
			},
			{
				talker: "butterfly",
				talkerCHN: "蝴蝶夫人",
				content:
					"这个卷轴几千年来一直存放在学院的图书馆，上面写着关于生命音符神殿的线索。",
				audio: require("../audio/蝴蝶4.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/handrail.png"),
				style: {
					position: "absolute",
					width: "100vw",
					bottom: 0,
					left: 0,
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/butterfly.png"),
				style: {
					position: "absolute",
					width: "36vw",
					bottom: "30vh",
					right: "0",
				},
			},
		],
	},
	6: {
		pageBg: require("@/views/lesson16/images/buildHasHandrailBg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson16/page?page=7",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/paper_close.png"),
				style: {
					position: "absolute",
					height: "80vh",
					bottom: "10vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
					cursor: "pointer",
				},
				click(vm) {
					vm.$router.push("/lesson16/page?page=7");
				},
			},
		],
	},
	7: {
		pageBg: require("@/views/lesson16/images/buildHasHandrailBg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson16/page?page=8",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/paper.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/do_wuxianpu@2x.png"),
				style: {
					position: "absolute",
					width: "32vw",
					top: "22vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/do@2x.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "60vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	8: {
		pageBg: require("@/views/lesson16/images/buildHasHandrailBg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson16/page?page=9",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/paper.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/C@2x.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "50vh",
					left: "50vw",
					transform: "translate(-50%, -50%)",
					zIndex: 10,
				},
			},
		],
	},
	9: {
		pageBg: buildPageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson17/page",
		roles: roles(1.1),
		isEnd: true,
		talkList: [
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "向南走吗？再往南就是大海了。",
				audio: require("../audio/骑士01.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"第一个神殿在山顶，那么第二个神殿就很有可能在大海中，可是我们怎么该入海底呢？",
				audio: require("../audio/宫6.mp3"),
			},
			{
				talker: "butterfly",
				talkerCHN: "蝴蝶夫人",
				content:
					"几千年来只有你们打开了这个魔法卷轴，我相信你们是被魔法选中的人，一定会有进入海底神殿的办法。",
				audio: require("../audio/蝴蝶5.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/handrail.png"),
				style: {
					position: "absolute",
					width: "100vw",
					bottom: 0,
					left: 0,
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/butterfly.png"),
				style: {
					position: "absolute",
					width: "36vw",
					bottom: "30vh",
					right: "0",
				},
			},
		],
	},
};
