const pageBg = require("@/views/lesson22/images/bg.png");
const pageNextBtnImage = require("@/assets/image/next.png");

export default {
	lessonStart: {
		// 首页
		pageBg, // 首页背景
		pageTitleImage: require("@/views/lesson22/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson22/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg: require("../images/bg@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson22/game-1",
		audio: {
			src: require("../audio/旁白2201.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "这个神殿的大门似乎是一整块光滑的石头，该怎么打开呢。",
				audio: require("../audio/宫廷乐师2201.mp3"),
			},
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "让我用锤子砸碎他",
				audio: require("../audio/22-骑士-01.mp3"),
			},
		],
	},
	2: {
		pageBg: require("../images/bg@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson22/page?page=3",
		talkList: [
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "让我用魔法试试吧",
				audio: require("../audio/22-魔法师-01.mp3"),
			},
		],
	},
	3: {
		pageBg: require("../images/bg_music.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson22/page?page=4",
		audioPlayer: {
			src: require("../audio/鲸鱼—生命之歌.wav"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "3vh",
			},
		},
	},
	4: {
		pageBg: require("../images/bg@2x_zoom.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson22/page?page=5",
		talkList: [
			{
				talker: "whale",
				talkerCHN: "鲸鱼",
				content:
					"你们别费力气了，只有我才能打开这个大门。海底神殿中存放着生命的音符。而打开神殿的钥匙就是生命的秘密。这个秘密叫做死亡。我们鲸鱼族的成员在知道命不久矣的时候都会来着这里静静的等待死亡。",
				audio: require("../audio/鲸鱼2201.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "这么说你快要死了吗？可惜我们才刚刚认识。",
				audio: require("../audio/宫廷乐师2202.mp3"),
			},
			{
				talker: "whale",
				talkerCHN: "鲸鱼",
				content:
					"哈哈哈哈，不要为我悲伤。世间万物都有生死，一朵花，一株树，一只蚂蚁，一颗行星，甚至是时间。他们都在不断的诞生，也在不断的死亡。是死亡让我们尊重生命，认真生活。所以打开神殿大门的钥匙就是死亡。",
				audio: require("../audio/鲸鱼2202.mp3"),
			},
			{
				talker: "whale",
				talkerCHN: "鲸鱼",
				content:
					"不要为我悲伤，为我唱歌跳舞吧，我的循环结束了。我在海洋诞生，现在我要回归大海了。我把祝福送给你们，记住死亡只是生命的一部分，我们不需要畏惧。",
				audio: require("../audio/鲸鱼2203.mp3"),
			},
			{
				isBreak: true,
				callback: (vm) => {
					vm.animalDiamond = require("../images/祝福-鲸鱼.png");
					vm.showMagicBook = true;
				},
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "我们一起把生命之歌送给鲸鱼，送给他我们最深的祝福。",
				audio: require("../audio/宫廷乐师2203.mp3"),
			},
		],
	},
	5: {
		pageBg: require("../images/bg_music.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson22/game-2",
		audioPlayer: {
			src: require("../audio/鲸鱼—生命之歌.wav"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "3vh",
			},
		},
	},
	6: {
		pageBg: require("../images/bg@2x_blur.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson22/page?page=7",
		audio: {
			src: require("../audio/旁白2203.mp3"),
			autoplay: true,
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/bg_beat_and_music_close@2x.png"),
				style: {
					position: "absolute",
					height: "80vh",
					bottom: "10vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
					cursor: "pointer",
				},
				click(vm) {
					vm.$router.push("/lesson22/page?page=7");
				},
			},
		],
	},
	7: {
		pageBg: require("../images/bg@2x_blur.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson22/page?page=8",
		otherElement: [
			{
				type: "img",
				src: require("../images/bg_beat_and_music@2x.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/do_wuxianpu@2x.png"),
				style: {
					position: "absolute",
					width: "32vw",
					top: "22vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/La@2x.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "60vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	8: {
		pageBg: require("../images/bg@2x_blur.png"),
		pageNextBtnImage,
		isEnd: true,
		nextPageRoute: "/lesson23/page",
		otherElement: [
			{
				type: "img",
				src: require("../images/bg_beat_and_music@2x.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/G@2x.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "50vh",
					left: "50vw",
					transform: "translate(-50%, -50%)",
					zIndex: 10,
				},
			},
		],
		talkList: [
			{
				audio: require("../audio/旁白2204-在大家回过神以后，神殿的大门打开了.mp3"),
			},
		],
	},
};
