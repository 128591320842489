const pageBg = require("@/views/lesson7/images/bg_L7_normal.jpg");
const startPageBg = require("@/views/lesson7/images/bg_L7_start.jpg");
const pageNextBtnImage = require("@/assets/image/next.png");
const roles = (scale) => [
	{
		roleClass: "magician",
		imgSrc: require("@/assets/image/role/magician.png"),
		style: {
			width: "auto",
			left: "9.646vw",
			bottom: "4.352vh",
			zIndex: 7,
		},
		roleStyle: {
			height: `${43.333 * scale}vh`,
		},
	},
	{
		roleClass: "musician",
		imgSrc: require("@/assets/image/role/musician.png"),
		style: {
			width: "auto",
			left: "36vw",
			bottom: "3.759vh",
			zIndex: 8,
		},
		roleStyle: {
			height: `${52.778 * scale}vh`,
		},
	},
	{
		roleClass: "eagle",
		imgSrc: require("@/views/lesson7/images/L7_老鹰们.png"),
		style: {
			width: "auto",
			left: "53vw",
			bottom: "18vh",
			zIndex: 6,
		},
		roleStyle: {
			height: `${47 * scale}vh`,
		},
	},
	{
		roleClass: "knight",
		imgSrc: require("@/assets/image/role/knight.png"),
		style: {
			width: "auto",
			left: "76.719vw",
			bottom: "2.685vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${50.092 * scale}vh`,
		},
	},
];
export default {
	lessonStart: {
		// 首页
		pageBg: startPageBg, // 首页背景
		pageTitleImage: require("../images/L7_title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson7/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"), // 如果首页需要背景音乐
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson7/page?page=1-1",
		roles: roles(1),
		audio: {
			src: require("../audio/旁白1.mp3"),
			autopaly: true,
		},
	},
	"1-1": {
		pageBg: require("../images/老鹰打架背景图.jpg"),
		pageNextBtnImage,
		nextPageRoute: "/lesson7/page?page=1-2",
		otherElement: [
			{
				type: "img",
				src: require("../images/老鹰打架.gif"),
				style: {
					position: "absolute",
					left: "53vw",
					bottom: "25vh",
					zIndex: 6,
					height: `47vh`,
				},
			},
		],
	},
	"1-2": {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson7/game",
		roles: roles(1),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"别吵了，你们挡住了我们上山的路。这可怎么办，他们吵得这么凶，根本听不到我们说话。",
				audio: require("../audio/宫廷1.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "我的魔笛或许能让他们冷静下来。",
				audio: require("../audio/魔法师1.mp3"),
			},
			{
				// dialog_box_bg_老鹰.png
				// L7_flute.png
				// audio: require("../audio/第七课-谐虐曲-魔法师吹魔笛的音乐.mp3"),
			},
		],
	},
	// 3: {
	//     pageBg,
	//     pageNextBtnImage,
	//     nextPageRoute: "/lesson7/page?page=4",
	//     // roles: roles(1),
	//     talkList: [
	//     ],
	//     otherElement: [
	//         {
	//             type: 'img',
	//             src: require("../images/L7_flute.png"),
	//             style: {
	//                 position: "absolute",
	//                 height: "100vh",
	//                 top: "0",
	//                 left: "50%",
	//                 transform: "translateX(-50%)",
	//                 cursor: 'pointer',
	//                 zIndex: 10
	//             }
	//         },
	//         {
	//             type: 'html',
	//             style: {
	//                 position: "absolute",
	//                 top: "50%",
	//                 left: "50%",
	//                 transform: "translate(-50%, -50%)",
	//                 cursor: 'pointer',
	//                 padding: '0.5em',
	//                 fontSize: "3.125vw",
	//                 borderRadius: '1em',
	//                 background: "rgba(255,255,255,.2)",
	//                 backdropFilter: "blur(4px)",
	//                 boxShadow: `0px 0px 0.667em 0px rgba(7,0,2,0.5)`,
	//                 zIndex: 10
	//             },
	//             html: `<img style="height: 79.282vh" src="${require("../images/L7_notes.png")}"/>`
	//         },
	//     ]
	// },
	4: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson7/page?page=5",
		// roles: roles(1),
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/L7_flute.png"),
				style: {
					position: "absolute",
					height: "100vh",
					top: "0",
					left: "50%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/L7_note_tit.png"),
				style: {
					position: "absolute",
					height: "11.481vh",
					top: "13.24vh",
					left: "50%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
			{
				type: "html",
				style: {
					position: "absolute",
					top: "33.796vh",
					left: "50%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					padding: "7.407vh",
					fontSize: "3.125vw",
					borderRadius: "1em",
					background: "rgba(255,255,255,.2)",
					backdropFilter: "blur(4px)",
					boxShadow: `0px 0px 0.667em 0px rgba(7,0,2,0.5)`,
					zIndex: 10,
				},
				html: `<img style="height: 29.444vh" src="${require("../images/L7_notes_er.png")}"/>`,
			},
		],
	},
	5: {
		pageBg: require("@/views/lesson7/images/bg_L7_start.jpg"),
		pageNextBtnImage,
		nextPageRoute: "/lesson7/game-1",
		roles: [
			{
				roleClass: "eagle",
				imgSrc: require("@/views/lesson7/images/L7_老鹰们.png"),
				style: {
					width: "auto",
					left: "53vw",
					bottom: "18vh",
					zIndex: 6,
				},
				roleStyle: {
					height: `47vh`,
				},
			},
		],
		audio: {
			src: require("../audio/旁白2.mp3"),
			autopaly: true,
			ended: (vm) => {
				let audio = vm.$refs.audioPlayer;
				vm.$nextTick(() => {
					audio.currentTime = 0;
					audio.play();
				});
			},
		},
		audioPlayer: {
			src: require("../audio/第七课-谐虐曲-魔法师吹魔笛的音乐.mp3"),
			autopaly: false,
			controls: true,
		},
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/L7_flute_playing.png"),
				style: {
					position: "absolute",
					height: "100vh",
					top: "0",
					left: "0",
					cursor: "pointer",
					zIndex: 10,
				},
			},
		],
	},
	6: {
		pageBg: require("@/views/lesson7/images/bg_L7_start.jpg"),
		pageNextBtnImage,
		nextPageRoute: "/lesson7/page?page=7",
		roles: [],
		talkList: [],
		otherElement: [
			{
				type: "img",
				src: require("../images/L7_note_tit_la.png"),
				style: {
					position: "absolute",
					height: "11.481vh",
					top: "7.963vh",
					left: "50%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/L7_note_content_la.png"),
				style: {
					position: "absolute",
					height: "67.963vh",
					top: "22.963vh",
					left: "50%",
					transform: "translateX(-50%)",
					cursor: "pointer",
					zIndex: 10,
				},
			},
		],
	},
	7: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson8/page",
		isEnd: true,
		roles: roles(1),
		audio: {
			src: require("../audio/旁白3.mp3"),
			autopaly: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你们两个终于停了下来了。来让我问问你们为什么在这里争吵呢？",
				audio: require("../audio/宫廷2.mp3"),
			},
		],
	},
};
