const pageBg = require("@/views/lesson13/images/bg.png");
// const startPageBg = require("@/views/lesson12/images/bg_blur.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "24vw",
				bottom: "10vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${52.778 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "7vw",
				bottom: "18vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${35 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "3vw",
				bottom: "5vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${60 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "goose",
			imgSrc: require("@/assets/image/role/goose.png"),
			style: {
				width: "auto",
				left: "30vw",
				bottom: "7.5vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${35 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "panda",
			imgSrc: require("@/assets/image/role/panda.png"),
			style: {
				width: "auto",
				left: "73vw",
				bottom: "12vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

export default {
	lessonStart: {
		// 首页
		pageBg, // 首页背景
		pageTitleImage: require("@/views/lesson13/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson13/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson13/game-1",
		roles: roles(1.1),
		audio: {
			src: require("../audio/旁白1.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "你们起来了？你们要不要也试试这梅花桩呀？",
				audio: require("../audio/熊猫01.mp3"),
			},
			{
				audio: require("../audio/旁白2.mp3"),
			},
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "你们要在这不同的木桩之间跳跃，千万不要跳错了呀。",
				audio: require("../audio/熊猫02.mp3"),
			},
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson13/page?page=3",
		roles: roles(1.1),
		talkList: [
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content:
					"你们真是勤奋，一早就在这里练功。我们今天要快点去追臭鼬，破坏车轮的事情，我们还要和他算账呢。",
				audio: require("../audio/大鹅1.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "熊猫大师，你带路吧，我们一起去山洞找他。",
				audio: require("../audio/宫1.mp3"),
			},
			{
				audio: require("../audio/旁白3.mp3"),
			},
		],
	},
	3: {
		pageBg: require("../images/hole_bg@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson13/page?page=4",
		roles: roles(0.8, [4, 5], {
			4: {
				roleClass: "panda",
				imgSrc: require("@/assets/image/role/panda.png"),
				style: {
					width: "auto",
					left: "19vw",
					bottom: "37vh",
					zIndex: 0,
				},
				roleStyle: {
					height: `${40 * 0.9}vh`,
					transform: "rotateY(180deg)",
				},
			},
			5: {
				roleClass: "chouyou",
				imgSrc: require("@/assets/image/role/chouyou.png"),
				style: {
					width: "auto",
					left: "75vw",
					bottom: "12vh",
					zIndex: 0,
				},
				roleStyle: {
					height: `${40 * 0.8}vh`,
				},
			},
		}),
		talkList: [
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "看，就是这里。我们悄悄的进去。",
				audio: require("../audio/熊猫03.mp3"),
			},
			{
				talker: "chouyou",
				talkerCHN: "臭鼬",
				content:
					"真是倒霉，没想到破坏车轮的事情被他们发现了，还招惹上了一个厉害的熊猫。",
				audio: require("../audio/臭鼬01.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你果然在这里，你跑不了了。快和我们回去。",
				audio: require("../audio/宫2.mp3"),
			},
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "你把我的竹子也藏在这里了！我今天一定要抓到你。",
				audio: require("../audio/熊猫04.mp3"),
			},
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "我们一起上吧。",
				audio: require("../audio/大鹅2.mp3"),
			},
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "不用，我自己就可以打败他。",
				audio: require("../audio/熊猫05.mp3"),
			},
		],
	},
	4: {
		pageBg: require("../images/hole_bg@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson13/page?page=5",
		audio: {
			src: require("../audio/旁白4.mp3"),
			autoplay: true,
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/音乐课_13_熊猫下_04.gif"),
				style: {
					position: "absolute",
					width: "86vw",
					bottom: "0",
					left: "7vw",
				},
			},
		],
	},
	5: {
		pageBg: require("../images/hole_bg@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson13/game-2",
		audio: {
			src: require("../audio/旁白5.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "chouyou",
				talkerCHN: "臭鼬",
				content: "熊猫确实厉害，但是我知道你的弱点。",
				audio: require("../audio/臭鼬02.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/音乐课_13_熊猫下_04.gif"),
				style: {
					position: "absolute",
					width: "86vw",
					bottom: "0",
					left: "7vw",
				},
			},
		],
	},
	6: {
		pageBg: require("../images/hole_bg@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson13/page?page=7",
		roles: roles(1.1, [3], {
			3: {
				roleClass: "goose",
				imgSrc: require("@/assets/image/role/goose.png"),
				style: {
					width: "auto",
					left: "65vw",
					bottom: "5.5vh",
					zIndex: 11,
				},
				roleStyle: {
					height: `${35 * 1.1}vh`,
				},
			},
		}),
		talkList: [
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "太卑鄙了，总是用这一招！",
				audio: require("../audio/熊猫06.mp3"),
			},
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content: "早知道听我们的一起上就好了。",
				audio: require("../audio/大鹅3.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "现在没有办法了，看来我们只能先回去了。",
				audio: require("../audio/宫3.mp3"),
			},
			{
				talker: "goose",
				talkerCHN: "大鹅",
				content:
					"既然臭鼬已经逃跑了，那我也要踏上旅途了。再见，朋友们。我们也许还会再见面的。",
				audio: require("../audio/大鹅4.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "再见。",
				audio: require("../audio/宫4.mp3"),
			},
		],
	},
	7: {
		pageBg: require("../images/hole_bg@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson13/page?page=8",
		audio: {
			src: require("../audio/旁白7.mp3"),
			autoplay: true,
		},
		roles: roles(1.1, [3], {
			3: {},
		}),
		talkList: [
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content: "你们是要坐火车去魔法学院吧？",
				audio: require("../audio/熊猫07.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "是啊，但是现在火车坏了。我们只能步行前往了。",
				audio: require("../audio/宫5.mp3"),
			},
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content:
					"我来告诉你们道路，你们一路向北，穿过一片非常漂亮的花海，就会看到魔法学院了。",
				audio: require("../audio/熊猫08.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "谢谢你，我们会好好使用你教给我们的拳法。",
				audio: require("../audio/宫6.mp3"),
			},
			{
				talker: "panda",
				talkerCHN: "熊猫",
				content:
					"这次虽然没有抓到臭鼬，但是我的竹子已经找到了，我还是要谢谢你们。我把祝福给你，希望你们记得无论是武功还是学问，都需要勤学苦练才可以有所成就。",
				audio: require("../audio/熊猫09.mp3"),
			},
			{
				isBreak: true,
				callback: (vm) => {
					vm.animalDiamond = require("../images/熊猫的祝福.png");
					vm.showMagicBook = true;
				},
			},
		],
	},
	8: {
		pageBg: require("../images/hole_bg@2x.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson14/page",
		audio: {
			src: require("../audio/旁白8.mp3"),
			autoplay: true,
		},
		isEnd: true,
		roles: [
			{
				roleClass: "musician",
				imgSrc: require("@/assets/image/role/musician.png"),
				style: {
					width: "auto",
					left: "80vw",
					bottom: "10vh",
					zIndex: 8,
				},
				roleStyle: {
					height: `${52.778 * 1.1}vh`,
					transform: "rotateY(180deg)",
				},
			},
			{
				roleClass: "magician",
				imgSrc: require("@/assets/image/role/magician.png"),
				style: {
					width: "auto",
					left: "62vw",
					bottom: "18vh",
					zIndex: 7,
				},
				roleStyle: {
					height: `${35 * 1.1}vh`,
				},
			},
			{
				roleClass: "knight",
				imgSrc: require("@/assets/image/role/knight.png"),
				style: {
					width: "auto",
					left: "53vw",
					bottom: "5vh",
					zIndex: 9,
				},
				roleStyle: {
					height: `${60 * 1.1}vh`,
					transform: "rotateY(180deg)",
				},
			},
		],
		talkList: [
			{
				audio: require("../audio/旁白9.mp3"),
			},
			{
				talker: "chouyou",
				talkerCHN: "臭鼬",
				content:
					"大人，我已经按照你的吩咐破坏了火车，这样可以拖延他们三天，等到您赶到这里。",
				audio: require("../audio/臭鼬03.mp3"),
			},
			{
				talker: "blackman",
				talkerCHN: "黑衣人",
				content:
					"做的好，不过你不该招惹这个熊猫。他可比你想象的要厉害。你能逃脱，恐怕是他没尽全力。",
				audio: require("../audio/黑衣人1.mp3"),
			},
			{
				talker: "chouyou",
				talkerCHN: "臭鼬",
				content: "大人，您为什么要跟着这群人呢？",
				audio: require("../audio/臭鼬04.mp3"),
			},
			{
				talker: "blackman",
				talkerCHN: "黑衣人",
				content:
					"我倒要看看他们到底能不能找到这传说中的生命音符。我们对这个可是很感兴趣。",
				audio: require("../audio/黑衣人2.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/黑衣人和臭鼬@2x.png"),
				style: {
					position: "absolute",
					width: "6vw",
					bottom: "63.5vh",
					left: "28vw",
				},
			},
		],
	},
};
