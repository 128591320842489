<template>
    <div class="common-page">
        <img class="next-btn" src="@/assets/image/next.png" @click.stop="gonext()" />
        <img class="content-bg" src="./images/L7_flute.png" alt="">
        <div class="content-box">
            <div class="line" v-for="(line, lIndex) of  contentLines " :key="lIndex">
                <span :style="{
                        cursor: spanObj.type == 'change' ? 'pointer' : 'auto',
                        color: spanObj.isHighlight ? '#ffff00' : 'inherit'
                    }" @click="changeState(spanObj)" v-for="(spanObj, sIndex) of  line " :key="sIndex">{{ spanObj.text
    }}</span>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    components: {},
    data() {
        return {
            contentLines: [
                [
                    {
                        text: "魔笛吹响我心"
                    },
                    {
                        type: 'change',
                        text: "意",
                        isHighlight: false
                    }, {
                        text: "，"
                    }
                ],
                [
                    {
                        text: "两只雄鹰快来"
                    },
                    {
                        type: 'change',
                        text: "听",
                        isHighlight: false
                    }, {
                        text: "。"
                    }
                ],
                [
                    {
                        text: "别争"
                    },
                    {
                        type: 'change',
                        text: "吵",
                        isHighlight: false
                    }, {
                        text: "，不生"
                    }, {
                        type: 'change',
                        text: "气",
                        isHighlight: false
                    }, {
                        text: "。"
                    }
                ],
                [
                    {
                        text: "看天"
                    },
                    {
                        type: 'change',
                        text: "空",
                        isHighlight: false
                    }, {
                        text: "，多美"
                    }, {
                        type: 'change',
                        text: "丽",
                        isHighlight: false
                    }, {
                        text: "。"
                    }
                ],
                [
                    {
                        text: "适合翱翔在天"
                    },
                    {
                        type: 'change',
                        text: "际",
                        isHighlight: false
                    }, {
                        text: "。"
                    }
                ],
                [
                    {
                        text: "随我"
                    },
                    {
                        type: 'change',
                        text: "舞",
                        isHighlight: false
                    }, {
                        text: "，不生"
                    }, {
                        type: 'change',
                        text: "气",
                        isHighlight: false
                    }, {
                        text: "。"
                    }
                ], [
                    {
                        text: "两只雄鹰是兄"
                    },
                    {
                        type: 'change',
                        text: "弟",
                        isHighlight: false
                    }, {
                        text: "。"
                    }
                ], [
                    {
                        text: "让我为你讲道"
                    },
                    {
                        type: 'change',
                        text: "理",
                        isHighlight: false
                    }, {
                        text: "。"
                    }
                ],
            ]
        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        gonext() {
            this.$router.push("/lesson7/game-page-3");
        },
        changeState(obj) {
            if (obj.type == "change") {
                obj.isHighlight = !obj.isHighlight;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.common-page {
    background-image: url("./images/bg_L7_start.jpg");
}

.content-bg {
    position: absolute;
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 10
}

.content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    padding: 1em;
    border-radius: 1em;
    background: rgba(255, 255, 255, .2);
    backdrop-filter: blur(4px);
    box-shadow: 0px 0px 0.667em 0px rgba(7, 0, 2, 0.5);
    letter-spacing: 0.15em;
    font-size: 3.125vw;
    font-family: Nowar-Warcraft-Rounded-CN-Bold;
    font-weight: bold;
    color: #83CF4F;
    line-height: 1.167em;
    font-weight: bold;
    z-index: 10
}
</style>
  