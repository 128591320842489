const pageBg = require("@/views/lesson18/images/bg.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "34vw",
				bottom: "12vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "12vw",
				bottom: "12vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${31 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "8vw",
				bottom: "10vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${44 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

export default {
	lessonStart: {
		// 首页
		pageBg, // 首页背景
		pageTitleImage: require("@/views/lesson18/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson18/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg: require("@/views/lesson18/images/bg_dolphin.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson18/game-1",
		roles: roles(1.5),
		audio: {
			src: require("../audio/旁白1801.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"真的很感谢你们，现在起风了，我们可以升起船帆行驶。不过我们还需要你们来带路。",
				audio: require("../audio/宫廷乐师1801.mp3"),
			},
			{
				talker: "dolphin",
				talkerCHN: "海豚",
				content:
					"前面是一片暗礁，里面住着一只海怪，从来没有船只可以通过这里。我们在前面走，你们一定要好好的跟上我们。不要撞到暗礁，也不要被海怪抓到哦～请按照我们的方式向前行驶。",
				audio: require("../audio/海豚1801.mp3"),
			},
		],
	},
	"1-after": {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson18/page?page=2",
		audioPlayer: {
			src: require("../audio/海怪暗礁岛.mp3"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "7vh",
			},
		},
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/paper.png"),
				style: {
					position: "absolute",
					width: "60vw",
					top: "50vh",
					left: "20vw",
					cursor: "pointer",
					transform: "translateY(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/五线谱@2x.png"),
				style: {
					position: "absolute",
					width: "50vw",
					top: "50vh",
					left: "25vw",
					cursor: "pointer",
					transform: "translateY(-58%)",
					zIndex: 10,
				},
			},
		],
	},
	2: {
		pageBg: require("@/views/lesson18/images/bg_dolphin.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson18/game-2",
		roles: roles(1.5),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "太惊险了，看来我们已经穿过暗礁了。",
				audio: require("../audio/宫廷乐师1802.mp3"),
			},
			{
				talker: "dolphin",
				talkerCHN: "海豚",
				content: "还不可以放松警惕，你们看前面的海浪。",
				audio: require("../audio/海豚1802.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "这么大的海浪，我从没有见过这么高的浪。大家快扶好。",
				audio: require("../audio/魔法师1801.mp3"),
			},
			{ audio: require("../audio/旁白1802.mp3") },
		],
	},
	3: {
		pageBg: require("@/views/lesson18/images/bg_dolphin.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson18/page?page=4",
		roles: roles(1.5),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "终于风平浪静了，大海真的是变幻莫测。",
				audio: require("../audio/宫廷乐师1803.mp3"),
			},
			{
				talker: "dolphin",
				talkerCHN: "海豚",
				content:
					"你们真是勇敢的人类。我们把祝福和卷轴给你们，希望你们一直有勇气可以调整未知的困难。你们继续前行吧，前面不是我们的海域，我们不能继续陪伴你们了，祝你们好运。",
				audio: require("../audio/海豚1803.mp3"),
			},
			{
				isBreak: true,
				callback: (vm) => {
					vm.animalDiamond = require("../images/祝福-海豚.png");
					vm.showMagicBook = true;
				},
			},
		],
	},
	4: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson18/page?page=5",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("@/views/lesson16/images/paper_close.png"),
				style: {
					position: "absolute",
					height: "80vh",
					bottom: "10vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
					cursor: "pointer",
				},
				click(vm) {
					vm.$router.push("/lesson18/page?page=5");
				},
			},
		],
	},
	5: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson18/page?page=6",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/oldpaper.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/do_wuxianpu@2x.png"),
				style: {
					position: "absolute",
					width: "32vw",
					top: "22vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/Re@2x.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "60vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	6: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson18/page?page=7",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/oldpaper.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/C@2x.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "50vh",
					left: "50vw",
					transform: "translate(-50%, -50%)",
					zIndex: 10,
				},
			},
		],
	},
	7: {
		pageBg: require("@/views/lesson18/images/bg_dolphin.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson19/page",
		roles: roles(1.5),
		isEnd: true,
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "谢谢你们的帮助",
				audio: require("../audio/宫廷乐师1804.mp3"),
			},
			{ audio: require("../audio/旁白1803.mp3") },
		],
	},
};
