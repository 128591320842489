<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson9/page?page=3')"
			class="next-btn"
		/>
		<div class="container">
			<div
				class="item"
				v-for="item in 4"
				:key="item"
			>
				<img
					:src="require(`./images/music_${item}.png`)"
					class="note"
				/>
				<img
					src="./images/player_small.png"
					class="play"
					@click="playAudio(item)"
				/>
			</div>
		</div>
		<audio
			ref="audioPlayer"
			:src="audioSrc"
		></audio>
	</div>
</template>

<script>
export default {
	data() {
		return {
			audioSrc: "",
		};
	},
	methods: {
		playAudio(key) {
			const obj = {
				1: require(`./audio/1_5365.mp3`),
				2: require(`./audio/2_6535.mp3`),
				3: require(`./audio/3_53635.mp3`),
				4: require(`./audio/4_63565.mp3`),
			};
			this.audioSrc = obj[key];
			this.$nextTick(() => {
				this.$refs.audioPlayer.play();
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson9/images/shrine_bg_blur.png");
	audio {
		display: none;
	}
}
.container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 15vh 10vw;
	font-size: 0;
	.item {
		position: relative;
		width: 50%;
		padding: 10px;
		box-sizing: border-box;
		.note {
			width: 100%;
		}
		.play {
			position: absolute;
			right: 0;
			bottom: -1.7vh;
			width: 8vw;
			cursor: pointer;
		}
	}
}
</style>
