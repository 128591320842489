const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "34vw",
				bottom: "12vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "12vw",
				bottom: "12vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${31 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "8vw",
				bottom: "10vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${44 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

export default {
	lessonStart: {
		// 首页
		pageBg: require("@/views/lesson21/images/bg_1.png"), // 首页背景
		pageTitleImage: require("@/views/lesson21/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson21/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg: require("@/views/lesson21/images/bg_2.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson21/page?page=2",
		roles: roles(1.5),
		audio: {
			src: require("../audio/旁白2101.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你们看，前面好像有一个小岛？",
				audio: require("../audio/宫廷乐师2101.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "我们快上去看看吧。我们去补给一些水和食物。",
				audio: require("../audio/魔法师2101.mp3"),
			},
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "行驶了这么久终于看见土地了。",
				audio: require("../audio/骑士2101.mp3"),
			},
		],
	},
	2: {
		pageBg: require("@/views/lesson21/images/bg_3.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson21/page?page=3",
		roles: roles(1.5),
		audio: {
			src: require("../audio/旁白2102.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "大家小心，这个小岛怎么动起来了？",
				audio: require("../audio/宫廷乐师2102.mp3"),
			},
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "快，我们回到船上去。",
				audio: require("../audio/骑士2102.mp3"),
			},
		],
	},
	3: {
		pageBg: require("@/views/lesson21/images/bg_2.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson21/page?page=4",
		roles: roles(1.5),
		audio: {
			src: require("../audio/旁白2103.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "大家冷静，这不是小岛，这应该是一只鲸鱼。",
				audio: require("../audio/宫廷乐师2103.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "我只在书中看过，没想到这么大。",
				audio: require("../audio/魔法师2102.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "我们靠近看看。",
				audio: require("../audio/宫廷乐师2104.mp3"),
			},
		],
	},
	4: {
		pageBg: require("@/views/lesson21/images/bg_4.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson21/page?page=4-1",
		roles: roles(1.5),
		audio: {
			src: require("../audio/旁白2104.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你好，我们是国王的特使。是来寻找海洋神殿的。",
				audio: require("../audio/宫廷乐师2105.mp3"),
			},
			{
				talker: "whale",
				talkerCHN: "鲸鱼",
				content:
					"那我们的目标是一致的。我是鲸鱼部落的长老，我已经600岁了，这个年纪即使是在鲸鱼当中也是非常老了。来吧，和我一起。我带你们去海洋神殿",
				audio: require("../audio/鲸鱼2101.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "那多谢鲸鱼长老了。",
				audio: require("../audio/宫廷乐师2106.mp3"),
			},
		],
	},
	"4-1": {
		pageBg: require("@/views/lesson21/images/bg_4.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson21/game-1",
		roles: roles(1.5),
		talkList: [
			{
				talker: "whale",
				talkerCHN: "鲸鱼",
				content: "这里就是海底神殿的位置了。",
				audio: require("../audio/鲸鱼2102.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "可是我怎么没有看到神殿啊？",
				audio: require("../audio/魔法师2103.mp3"),
			},
			{
				talker: "whale",
				talkerCHN: "鲸鱼",
				content: "海底神殿，当然是在海底。所以你们来我背上，我带你们潜下去。",
				audio: require("../audio/鲸鱼2103.mp3"),
			},
			{
				talker: "whale",
				talkerCHN: "鲸鱼",
				content: "哎，可是我太老了。恐怕不能潜到那么深的海底。你们有办法吗？",
				audio: require("../audio/鲸鱼2104.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "让我来试一下吧。",
				audio: require("../audio/宫廷乐师2107.mp3"),
			},
		],
	},
	"4-2": {
		pageBg: require("@/views/lesson21/images/seabed_bg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson21/page?page=5",
		talkList: [],
		audioPlayer: {
			src: require("@/views/lesson21/audio/聖桑-動物狂歡節：水族館.mp3"),
			autoplay: true,
			controls: true,
			style: {
				bottom: "7vh",
				zIndex: 1,
			},
		},
	},
	5: {
		pageBg: require("../images/seabed_bg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson21/page?page=6",
		otherElement: [
			{
				type: "img",
				src: require("../images/paper_close.png"),
				style: {
					position: "absolute",
					height: "80vh",
					bottom: "10vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
					cursor: "pointer",
				},
				click(vm) {
					vm.$router.push("/lesson21/page?page=6");
				},
			},
		],
	},
	6: {
		pageBg: require("../images/seabed_bg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson21/page?page=7",
		otherElement: [
			{
				type: "img",
				src: require("../images/oldpaper.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/do_wuxianpu.png"),
				style: {
					position: "absolute",
					width: "32vw",
					top: "24vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/Sol.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "60vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	7: {
		pageBg: require("../images/seabed_bg.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson21/page?page=8",
		otherElement: [
			{
				type: "img",
				src: require("../images/oldpaper.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/G.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "50vh",
					left: "50vw",
					transform: "translate(-50%, -50%)",
					zIndex: 10,
				},
			},
		],
	},
	8: {
		pageBg: require("../images/bg@2x.png"),
		pageNextBtnImage,
		isEnd: true,
		nextPageRoute: "/lesson25/page",
		audio: {
			src: require("../audio/旁白2105.mp3"),
			autoplay: true,
		},
	},
};
