<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson23/page?page=4')"
			class="next-btn"
		/>
		<div class="container">
			<div
				class="item"
				:class="{ rotate: item.rotate }"
				v-for="(item, index) in list"
				:key="index"
			>
				<div class="mirrors">
					<img
						class="mirror blur"
						src="./images/mirror/mirror_blur.png"
					/>
					<img
						class="mirror bright"
						src="./images/mirror/mirror_bright.png"
					/>
				</div>
				<div
					class="note"
					@click="handler(item)"
				>
					<img :src="item.node" />
				</div>
			</div>
		</div>

		<audio
			ref="audioPlayer"
			src="./audio/魔镜翻转时候的音效.mp3"
			style="display: none"
		></audio>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: [
				{
					rotate: false,
					node: require(`./images/mirror/rhythm_1.png`),
				},
				{
					rotate: false,
					node: require(`./images/mirror/rhythm_2.png`),
				},
				{
					rotate: false,
					node: require(`./images/mirror/rhythm_3.png`),
				},
				{
					rotate: false,
					node: require(`./images/mirror/rhythm_4.png`),
				},
			],
		};
	},
	methods: {
		handler(node) {
			if (node.rotate) return;
			node.rotate = true;
			this.$refs.audioPlayer.play();
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson23/images/bg_mirror.png");
	display: flex;
	align-items: center;
	justify-content: center;
	.container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		.item {
			width: 25%;
			padding-top: 25%;
			position: relative;
			.mirrors {
				position: absolute;
				inset: 0;
				img {
					position: absolute;
					inset: 0;
					width: 100%;
					transition: all 3s;
					&.blur {
						opacity: 1;
					}
					&.bright {
						opacity: 0;
					}
				}
			}
			&.rotate {
				.mirrors {
					animation: fart 3s;
					img {
						&.blur {
							opacity: 0;
						}
						&.bright {
							opacity: 1;
						}
					}
				}
			}
			.note {
				position: absolute;
				inset: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 65%;
				}
			}
		}
	}
	@keyframes fart {
		0% {
			transform: rotateY(0);
		}
		100% {
			transform: rotateY(10turn);
		}
	}
}
</style>
