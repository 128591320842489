/**
 * 传入类名，返回元素检测是否点击到元素的函数
 * @param {string|array} className 类名
 * @param {boolean} [selectAll=false] 相同类名选择全部元素
 * @returns 返回坐标组
 */
export function getElementRect(className, selectAll = false) {
	const _cache = [];
	getRect();
	function getRect() {
		if (!className) return "参数错误";
		function createParam(el) {
			const result = el.getBoundingClientRect();
			const obj = {
				x: result.x,
				y: result.y,
				maxX: result.x + result.width,
				maxY: result.y + result.height,
				el,
			};
			_cache.push(obj);
		}
		if (selectAll) {
			const results = document.querySelectorAll(`.${className}`);
			results.forEach(createParam);
		} else {
			if (typeof className === "string") {
				className = [className];
			}
			for (const name of className) {
				const el = document.querySelector(`.${name}`);
				createParam(el);
			}
		}
	}
	/**
	 * 检查是否点击到元素
	 * @param x 当前点击x坐标
	 * @param y 当前点击y坐标
	 * @param fn 需要执行的操作
	 */
	return function (x, y, fn) {
		for (const item of _cache) {
			if (x >= item.x && x <= item.maxX && y >= item.y && y <= item.maxY) {
				typeof fn === "function" && fn(item);
				return item;
			}
		}
	};
}

/**
 * 防抖原理：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return {Function}
 */
export function debounce(func, wait = 500, immediate = false) {
	let timeout = null;
	return function (...args) {
		if (timeout !== null) clearTimeout(timeout);
		if (immediate) {
			const callNow = !timeout;
			timeout = setTimeout(() => {
				timeout = null;
			}, wait);
			if (callNow) typeof func === "function" && func.apply(this, args);
		} else {
			timeout = setTimeout(() => {
				typeof func === "function" && func.apply(this, args);
			}, wait);
		}
	};
}

/**
 * 节流
 * @param {*} func
 * @param {*} wait
 * @returns
 */
export function throttle(func, wait = 16) {
	var previous = 0;
	return function () {
		let now = Date.now();
		let context = this;
		let args = arguments;
		if (now - previous > wait) {
			func.apply(context, args);
			previous = now;
		}
	};
}
