const pageBg = require("@/views/lesson17/images/bg.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "34vw",
				bottom: "12vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "12vw",
				bottom: "12vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${31 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "8vw",
				bottom: "10vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${44 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};
console.log(roles);

export default {
	lessonStart: {
		// 首页
		pageBg, // 首页背景
		pageTitleImage: require("@/views/lesson17/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson17/page?page=1-1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	"1-1": {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson17/page?page=1",
		roles: roles(1.5),
		audio: {
			src: require("../audio/旁白1701.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "这大海真是辽阔，天空和海洋几乎连接在了一起。",
				audio: require("../audio/骑士1701.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "我也是第一次出海。奇怪，船怎么停了？",
				audio: require("../audio/魔法师1701.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"没有风了，我们的船是帆船，通过改变帆的方向，借助风力才能行驶。现在突然没有风力。我们现在只能等待了。",
				audio: require("../audio/宫廷乐师1701.mp3"),
			},
			{ audio: require("../audio/旁白1702.mp3") },
		],
	},
	1: {
		pageBg: require("@/views/lesson17/images/bg_dolphin.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson17/page?page=2",
		roles: roles(1.5),
		// audio: {
		// 	src: require("../audio/旁白1701.mp3"),
		// 	autoplay: true,
		// },
		talkList: [
			{
				talker: "dolphin",
				talkerCHN: "海豚",
				content:
					"我们在这片海域已经很久没有看到过人类了。你们怎么到了这么远的地方？",
				audio: require("../audio/海豚1701.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "我们是国王的特使，是来这里找海洋神殿的。",
				audio: require("../audio/宫廷乐师1702.mp3"),
			},
			{
				talker: "dolphin",
				talkerCHN: "海豚",
				content: "可是海洋神殿还很远啊。",
				audio: require("../audio/海豚1702.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你知道海洋神殿在哪里吗？",
				audio: require("../audio/宫廷乐师1703.mp3"),
			},
			{
				talker: "dolphin",
				talkerCHN: "海豚",
				content:
					"我们是海洋中的神兽，我们可以感受到海洋神殿魔法的力量。我们知道神殿的位置，我可以带你们去。",
				audio: require("../audio/海豚1703.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "那太好了。不过现在没有风了，我们不能跟着你们继续行驶。",
				audio: require("../audio/宫廷乐师1704.mp3"),
			},
			{
				talker: "dolphin",
				talkerCHN: "海豚",
				content:
					"哈哈哈，没有关系。我们可以在船的旁边游动，形成海浪，推着你们的小船行驶。",
				audio: require("../audio/海豚1704.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "那真是太感谢你们了。",
				audio: require("../audio/宫廷乐师1705.mp3"),
			},
			{ audio: require("../audio/旁白1703.mp3") },
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson17/page?page=3",
		talkList: [],
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.3)",
				},
			},
			{
				type: "img",
				src: require("../images/paper.png"),
				style: {
					position: "absolute",
					width: "60vw",
					top: "50vh",
					left: "20vw",
					cursor: "pointer",
					transform: "translateY(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "text-lines",
				style: {
					position: "absolute",
					left: "50vw",
					top: "35vh",
					fontSize: "2.604vw",
					fontFamily: "Alimama ShuHeiTi",
					fontWeight: "bold",
					color: "#9A5000",
					lineHeight: "1.36em",
					transform: "translateX(-50%)",
					wordSpacing: "50px",
					zIndex: 11,
				},
				textLines: [
					{
						style: {},
						text: "小海豚，游的快，",
					},
					{
						style: {},
						text: "乘风破浪多自在。",
					},
					{
						style: {},
						text: "歌声嘹亮推波浪，",
					},
					{
						style: {},
						text: "海风呼啸快点来。",
					},
				],
			},
		],
	},
	3: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson17/page?page=4",
		audioPlayer: {
			src: require("../audio/小海豚.mp3"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "7vh",
			},
		},
		talkList: [],
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.3)",
				},
			},
			{
				type: "img",
				src: require("../images/paper.png"),
				style: {
					position: "absolute",
					width: "60vw",
					top: "50vh",
					left: "20vw",
					cursor: "pointer",
					transform: "translateY(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/wuxianpu.png"),
				style: {
					position: "absolute",
					width: "50vw",
					top: "50vh",
					left: "25vw",
					cursor: "pointer",
					transform: "translateY(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	4: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson18/page",
		isEnd: true,
		talkList: [],
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.3)",
				},
			},
			{
				type: "img",
				src: require("../images/tit_Re@2x.png"),
				style: {
					position: "absolute",
					width: "25vw",
					top: "15vh",
					left: "37.5vw",
					cursor: "pointer",
					transform: "translateY(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/柯尔文手势_Re@2x.png"),
				style: {
					position: "absolute",
					width: "40vw",
					top: "26vh",
					left: "30vw",
					cursor: "pointer",
					zIndex: 10,
				},
			},
		],
	},
};
