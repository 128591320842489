const pageBg = require("@/views/lesson9/images/shrine_bg.png");
const startPageBg = require("@/views/lesson9/images/shrine_bg_blur.png");
const pageNextBtnImage = require("@/assets/image/next.png");
const roles = (scale) => [
	{
		roleClass: "musician",
		imgSrc: require("@/assets/image/role/musician.png"),
		style: {
			width: "auto",
			left: "36vw",
			bottom: "3.759vh",
			zIndex: 8,
		},
		roleStyle: {
			height: `${52.778 * scale}vh`,
		},
	},
	{
		roleClass: "magician",
		imgSrc: require("@/assets/image/role/magician.png"),
		style: {
			width: "auto",
			left: "42.3vw",
			bottom: "20vh",
			zIndex: 7,
		},
		roleStyle: {
			height: `${35 * scale}vh`,
		},
	},
	{
		roleClass: "knight",
		imgSrc: require("@/assets/image/role/knight.png"),
		style: {
			width: "auto",
			left: "53.7vw",
			bottom: "4vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${50.092 * scale}vh`,
		},
	},
	{
		roleClass: "zuoluo",
		imgSrc: require("@/assets/image/role/zuoluo.png"),
		style: {
			width: "auto",
			left: "66vw",
			bottom: "4vh",
			zIndex: 9,
		},
		roleStyle: {
			height: `${50.092 * scale}vh`,
		},
	},
];
export default {
	lessonStart: {
		// 首页
		pageBg: startPageBg, // 首页背景
		pageTitleImage: require("@/views/lesson9/images/start.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson9/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson9/page?page=2",
		roles: roles(1),
		audio: {
			src: require("../audio/旁白1.mp3"),
			autoplay: true,
			ended: (vm) => {
				const audio = document.querySelector(
					".common-page.common-lesson-page > audio"
				);
				const attr = audio.getAttribute("isChange");
				if (attr != "isChange") {
					vm.isTalking = true;
					audio.src = require("../audio/旁白2.mp3");
					audio.setAttribute("isChange", "isChange");
				}
			},
		},
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你是谁？你为什么也在神殿门口？来这里做什么呢？",
				audio: require("../audio/宫廷1.mp3"),
			},
			{
				talker: "zuoluo",
				talkerCHN: "佐罗",
				content:
					"我是正义的游侠佐罗。我听说神殿之中有着战无不胜的办法，所以我来寻找。",
				audio: require("../audio/佐罗1.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"我们是来寻找生命音符的线索的。或许我们找的是同一样东西。我们一起去神殿深处吧，我们可以互相帮助。",
				audio: require("../audio/宫廷2.mp3"),
			},
			{
				audio: require("../audio/旁白3.mp3"),
			},
			{
				talker: "shimen",
				talkerCHN: "石门",
				content: "我是一个有魔法的石门。你们只有通过了我的考验，才能进去。",
				audio: require("../audio/石门1.mp3"),
			},
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson9/game-1",
		audioPlayer: {
			src: require("../audio/Josef Suk - mp3.mp3"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "7vh",
				zIndex: 1,
			},
		},
	},
	3: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson10/page",
		isEnd: true,
		audio: {
			src: require("../audio/旁白6.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "shimen",
				talkerCHN: "石门",
				content:
					"你们果然是被神殿选中的人。你们通过吧。神殿深处有着非常深奥的知识等待着你们。",
				audio: require("../audio/石门2.mp3"),
			},
			{
				audio: require("../audio/旁白7.mp3"),
			},
		],
	},
};
