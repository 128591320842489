<template>
  <div class="common-page"  @click="changetalk()">
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />
    <audio ref="audioTip" style="display: none">
      <source :src="audio.src" />
    </audio>
    <div class="musician-box">
      <img src="@/assets/image/role/musician.png" class="musician" />
    </div>
    <div class="bird-box" >
      <img src="@/assets/image/role/fengniao.png" class="bird" />
    </div>
    <ChatDialog
      :talkList="talkList"
      :talkIndex="talkIndex"
      @audioStateChange="(state) => (isTalking = state)"
    ></ChatDialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      talkList: [
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content: "可算找到你了，快和我们走。",
          audio: require("@/assets/audio/lesson2/page2-musician-voice1.mp3"),
        },
        {
          talker: "bird",
          talkerCHN: "蜂鸟",
          content:
            "你们在干什么？不要打扰我唱歌，我是这个森林中唱歌最好的动物。",
          audio: require("@/assets/audio/lesson2/page2-bird-voice1.mp3"),
        },
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content:
            "看来，他已经完全沉浸在歌声中了。这样，我们和他比一比，看谁的歌声更好。",
          audio: require("@/assets/audio/lesson2/page2-musician-voice2.mp3"),
        },
        {
          talker: "musician",
          talkerCHN: "宫廷乐师",
          content: "如果我们能打败他，他就会心甘情愿的和我们去找独角兽啦。",
          audio: require("@/assets/audio/lesson2/page2-musician-voice3.mp3"),
        },
      ],
      talkIndex: -1,
      isTalking: false,
      audio: {
        // 消息通知
        src: require("@/assets/music/fengniao.mp3"),
      },
    };
  },
  components: {},
  mounted() {
    // this.$refs.audioTip.play();
  },
  methods: {
    gonext() {
      this.$router.push("/lesson2/game-1");
    },
    changetalk() {
      if (this.talkIndex < this.talkList.length - 1) {
        if (this.isTalking) {
          // 正在读旁白或者正在播放对话内容
          return;
        }
        this.isTalking = true;
        this.talkIndex += 1;
        if (this.talkIndex == 3) {
          this.$refs.audioTip.play();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.musician-box {
  left: 520px;
  bottom: 160px;
}
.bird-box {
  left: 760px;
  bottom: 480px;
}
</style>