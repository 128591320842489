<template>
	<div class="common-page">
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson13/page?page=2')"
			class="next-btn"
		/>
		<div class="container">
			<img
				v-for="(item, index) in list"
				:key="index"
				class="pillar"
				:src="item.img"
				:style="item.style"
				@click="playMusic(item.audio)"
			/>
		</div>
		<audio
			ref="audioPlayer"
			:src="audioSrc"
			style="display: none"
		></audio>
	</div>
</template>

<script>
export default {
	data() {
		return {
			audioSrc: "",
			list: [
				{
					img: require("./images/梅花桩 V2@2x(1).png"),
					style: {
						width: "20vw",
						bottom: "4vh",
						left: "18vw",
					},
				},
				{
					img: require("./images/梅花桩Suo@2x.png"),
					audio: require("@/assets/audio/s.mp3"),
					style: {
						width: "24vw",
						bottom: "12vh",
						left: "30vw",
					},
				},
				{
					img: require("./images/梅花桩Mi@2x.png"),
					audio: require("@/assets/audio/m.mp3"),
					style: {
						width: "20vw",
						bottom: "8vh",
						left: "43.5vw",
					},
				},
				{
					img: require("./images/梅花桩La@2x.png"),
					audio: require("@/assets/audio/l.mp3"),
					style: {
						width: "24vw",
						bottom: "5vh",
						left: "53vw",
					},
				},
				{
					img: require("./images/梅花桩Duo@2x.png"),
					audio: require("@/assets/audio/d.mp3"),
					style: {
						width: "18vw",
						bottom: "19vh",
						left: "65.5vw",
					},
				},
				{
					img: require("./images/梅花桩 V2@2x.png"),
					style: {
						width: "20vw",
						bottom: "5vh",
						left: "81.2vw",
					},
				},
			],
		};
	},
	methods: {
		playMusic(source) {
			if (source) {
				this.audioSrc = source;
				this.$nextTick(() => {
					this.$refs.audioPlayer.play();
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson13/images/bg.png");
}
.container {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.pillar {
		position: absolute;
		cursor: pointer;
	}
}
</style>
