import { debounce } from "@/utils";

/**
 * 监听窗口变化
 */
export class Resize {
	static instance = null;

	static cacheEvent = new Set();

	constructor() {
		if (Resize.instance) {
			return Resize.instance;
		}
		this._handler = debounce(this.handler, 200);
		this.listen();
		Resize.instance = this;
	}

	listen() {
		if (Resize.instance) return;
		window.addEventListener("resize", this._handler);
	}

	unListen() {
		Resize.cacheEvent.clear();
		Resize.instance = null;
		window.removeEventListener("resize", this._handler);
	}

	/**
	 * resize回调函数
	 * @param {EventListener} e resize回调函数携带的参数
	 */
	handler() {
		for (const event of Resize.cacheEvent) {
			typeof event === "function" && event();
		}
	}

	/**
	 * 监听回调所执行的方法
	 * @param {Function} event
	 */
	on(event) {
		Resize.cacheEvent.add(event);
	}

	/**
	 * 移除回调方法
	 * @param {Function} event
	 */
	remove(event) {
		Resize.cacheEvent.delete(event);
		if (!Resize.cacheEvent.size) {
			this.unListen();
		}
	}
}
