<template>
	<div
		class="common-page"
		id="game-page-wrapper"
	>
		<img
			class="next-btn"
			src="@/assets/image/next.png"
			@click.stop="gonext()"
		/>
		<div>
			<img
				v-for="item in players"
				:key="item.class"
				class="play-btn"
				:class="item.class"
				src="./images/播放器背景_small@2x.png"
				@click="player(item.url)"
			/>
		</div>
		<div class="bottom-btns">
			<div
				class="custom-btn"
				@click="redo()"
			>
				撤回
			</div>
			<div
				class="custom-btn"
				@click="cleanScreen"
			>
				清屏
			</div>
			<div
				class="custom-btn"
				@click="showAnswer()"
			>
				查看答案
			</div>
		</div>
		<div class="custom-audio">
			<audio
				ref="audioPlayer"
				:src="mediaUrl"
				controls
			></audio>
		</div>
	</div>
</template>

<script>
import { DrawLine } from "@/DrawLine";
export default {
	components: {},
	data() {
		return {
			drawLine: new DrawLine(),
			mediaUrl: "",
			lines: Object.freeze([
				[
					[0.39, 0.228],
					[0.488, 0.85],
				],
				[
					[0.39, 0.43],
					[0.488, 0.228],
				],
				[
					[0.39, 0.63],
					[0.488, 0.428],
				],
				[
					[0.39, 0.84],
					[0.488, 0.64],
				],
			]),
			players: Object.freeze([
				{
					class: "b1",
					url: require("@/views/lesson18/audio/1_123565351.mp3"),
				},
				{
					class: "b2",
					url: require("@/views/lesson18/audio/2_333335123.mp3"),
				},
				{
					class: "b3",
					url: require("@/views/lesson18/audio/3_321235665321.mp3"),
				},
				{
					class: "b4",
					url: require("@/views/lesson18/audio/4_53535353561.mp3"),
				},
			]),
		};
	},
	beforeDestroy() {
		this.drawLine.destroy();
	},
	methods: {
		gonext() {
			this.$router.push("/lesson18/page?page=3");
		},
		redo() {
			this.drawLine.back();
		},
		showAnswer() {
			this.drawLine.clean();
			const ctx = this.drawLine.ctx;
			const { width, height } = this.drawLine.canvas;
			function getPx(direction, val) {
				if (direction === "x") {
					return width * val;
				} else {
					return height * val;
				}
			}
			ctx.beginPath();
			for (const [origin, target] of this.lines) {
				ctx.moveTo(getPx("x", origin[0]), getPx("y", origin[1]));
				ctx.lineTo(getPx("x", target[0]), getPx("y", target[1]));
			}
			ctx.stroke();
		},
		cleanScreen() {
			this.drawLine.clean();
		},
		player(url) {
			this.mediaUrl = url;
			this.$nextTick(() => {
				this.$refs.audioPlayer.play();
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("./images/bg-notes.png");
}
.drawer-pannel {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
}
.custom-audio {
	display: none;
}
.bottom-btns {
	position: absolute;
	width: 100%;
	left: 0;
	top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;

	.custom-btn {
		& + .custom-btn {
			margin-left: 100px;
		}
	}
}
.play-btn {
	position: absolute;
	right: 50px;
	top: 16vh;
	width: 200px;
	z-index: 30;
	cursor: pointer;
	&.b2 {
		top: 36vh;
	}
	&.b3 {
		top: 57vh;
	}
	&.b4 {
		top: 77.5vh;
	}
}
</style>
