import Vue from 'vue';
import VueRouter from 'vue-router';

// 自动注入路由
const modules = require.context('@/views', true, /.vue/);
const autoRoutes = modules.keys().map((item) => {
  const viewName = item.slice(2);
  const path = item.slice(1).replace('.vue', '').replace('index', '');
  const viewModule = () => import(`@/views/${viewName}`);
  return {
    path,
    component: viewModule,
  };
});

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [{
  path: "/",
  // redirect: "/lesson1/page-home"
  redirect: "Login"
}].concat(autoRoutes,
  [
    {
      path: "*",
      redirect: "/"
    }]
);
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});
export default router;
