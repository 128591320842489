<template>
    <div class="common-page">
        <img class="next-btn" src="@/assets/image/next.png" @click.stop="gonext()" />

        <img class="cloud-s" @click="playAudio('s')" src="./images/L5_cloud_s.png" />
        <img class="cloud-m" @click="playAudio('m')" src="./images/L5_cloud_m.png" />
        <div class="role-box" v-for="role of roles" :class="role.roleClass + '-box'" :style="role.style"
            :key="role.roleClass">
            <img :src="role.imgSrc" :class="role.roleClass" :style="role.roleStyle" />
        </div>
        <audio ref="palyAudio" style="display: none;" :src="audioSrc"></audio>
    </div>
</template>
  
<script>
import { roles } from "./config/index";
export default {
    components: {},
    data() {
        return {
            audioSrc: '',
            roles: roles(.865),
        };
    },
    methods: {
        gonext() {
            this.$router.push("/lesson5/page?page=5");
        },
        playAudio(type) {
            let audio = this.$refs.palyAudio;
            audio.pause();
            if (type == 's') {
                this.audioSrc = require("./audio/sol.mp3");
            }
            if (type == 'm') {
                this.audioSrc = require("./audio/mi.mp3");
            }
            audio.load();
            this.$nextTick(() => {
                audio.currentTime = 1;
                audio.play();
            });

        }
    }
};
</script>
<style lang="scss" scoped>
.common-page {
    background-image: url("./images/bg_L5_normal.jpg");
}

.cloud-s {
    position: absolute;
    width: 19vw;
    top: 4.722vh;
    left: 31.042vw;
    cursor: pointer;
    z-index: 10
}

.cloud-m {
    position: absolute;
    width: 19vw;
    top: 23.796vh;
    left: 53.594vw;
    cursor: pointer;
    z-index: 10;
}
</style>
  