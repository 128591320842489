<template>
  <div class="common-page" >
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />
    <audio ref="audioTip" autoplay :src="audio.src" style="display: none">
      <!-- <source :src="audio.src"> -->
    </audio>
    <div class="cont1" >
      <img
        src="@/assets/image/cloud1.png"
        class="img2"
        
        
        @click="start1"
      />
      <img
        src="@/assets/image/cloud2.png"
        class="img2"
        
        
        @click="start2"
      />
    </div>
    <div
      class="cont11"
      style="display: flex; align-items: center; justify-content: center"
    >
      <img
        src="@/assets/image/cloud3.png"
        class="img3"
        
        
        @click="start3"
      />
      <img
        src="@/assets/image/cloud4.png"
        class="img2"
        
        
        @click="start4"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audio:{
        scr:""
      }
    };
  },

  mounted() {},
  methods: {
    start1() {
      // let src = require("@/assets/music/s.mp3")
      // this.audio.src = src
      this.$refs.audioTip.load();
      // this.$refs.audioTip.play();
    },
    start2() {
      // let src = require("@/assets/music/s.mp3")
      // this.audio.src = src
      this.$refs.audioTip.load();
      // this.$refs.audioTip.play();
    },
    start3() {
      // let src = require("@/assets/music/s.mp3")
      // this.audio.src = src
      this.$refs.audioTip.load();
      // this.$refs.audioTip.play();
    },
    start4() {
      // let src = require("@/assets/music/s.mp3")
      // this.audio.src = src
      this.$refs.audioTip.load();
      // this.$refs.audioTip.play();
    },
    gonext() {
      this.$router.push("/lesson4/page-2");
    },
  },
};
</script>

<style scoped>
.cont4 {
  height: 504px;
  width: 300px;
}

.cont3 {
  height: 426px;
  width: 300px;
}

.cont2 {
  height: 504px;
  width: 300px;
  margin-right: 200px;
}

.img4 {
  width: 300px;
  height: 400px;
}

.img3 {
  width: 427px;
  height: 189px;
  margin-right: 50px;
}

.img2 {
  width: 427px;
  height: 189px;
}
.cont1 .img2:nth-child(1) {
  margin-right: 160px;
}

.notalk {
  min-width: 300px;
  min-height: 100px;
  margin-bottom: 22px;
}

.cont1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.img1 {
  width: 148px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.common-page {
  background-image: url("~@/assets/image/back04.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.imgtalk {
  background-image: url("~@/assets/image/talk.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 300px;
  height: 100px;
  margin-bottom: 22px;
  padding: 0 40px 10px 40px;
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
  font-size: 13px;
  color: #634a0b;
  align-items: center;
  box-sizing: border-box;
  animation: glow-in 2s both;
}
</style>