const pageBg = require("../images/bg.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "49vw",
				bottom: "23vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "52vw",
				bottom: "23vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${31 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "58.5vw",
				bottom: "23vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${44 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

export default {
	lessonStart: {
		// 首页
		pageBg: require("../images/bg_blur.png"), // 首页背景
		pageTitleImage: require("@/views/lesson23/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson23/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson23/page?page=2",
		audio: {
			src: require("../audio/旁白2301.mp3"),
			autoplay: true,
		},
		roles: roles(0.5),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"大家小心，还记得我们在山顶神殿遇到的那些机关吗？这里的机关恐怕会更厉害。",
				audio: require("../audio/宫_补2.mp3"),
			},
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson23/page?page=3",
		talkList: [
			{
				talker: "mirror",
				talkerCHN: "魔镜",
				content:
					"我听到了你们在门外的歌声，你们要教会我唱那首歌《鲸鱼：生命之歌》，然后用我的方式来闯关",
				audio: require("../audio/魔镜.mp3"),
			},
		],
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/mirror.png"),
				style: {
					position: "absolute",
					width: "50vw",
					bottom: "0",
					left: "50%",
					transform: "translateX(-50%)",
				},
			},
		],
	},
	3: {
		pageBg: require("../images/音乐课_23_生命音符争夺战上_03.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson23/game-1",
		audioPlayer: {
			src: require("@/views/lesson22/audio/鲸鱼—生命之歌.wav"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "3vh",
			},
		},
	},
	4: {
		pageBg: require("../images/bg_mirror.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson23/game-2",
		otherElement: [
			{
				type: "img",
				src: require("../images/note_18.png"),
				style: {
					position: "absolute",
					width: "90vw",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				},
			},
		],
	},
	5: {
		pageBg: require("../images/bg_mirror.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson23/page?page=6",
		audio: {
			src: require("../audio/旁白2302.mp3"),
			autoplay: true,
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/bg_beat_and_music_close@2x.png"),
				style: {
					position: "absolute",
					height: "80vh",
					bottom: "10vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
					cursor: "pointer",
				},
				click(vm) {
					vm.$router.push("/lesson23/page?page=6");
				},
			},
		],
	},
	6: {
		pageBg: require("../images/bg_mirror.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson23/page?page=7",
		otherElement: [
			{
				type: "img",
				src: require("../images/bg_beat_and_music@2x.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/do_wuxianpu.png"),
				style: {
					position: "absolute",
					width: "32vw",
					top: "28vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/Si.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "60vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	7: {
		pageBg: require("../images/bg_mirror.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson23/page?page=8",
		otherElement: [
			{
				type: "img",
				src: require("../images/bg_beat_and_music@2x.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/B.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "50vh",
					left: "50vw",
					transform: "translate(-50%, -50%)",
					zIndex: 10,
				},
			},
		],
	},
	8: {
		pageBg: require("../images/bg_deep.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson23/page?page=9",
		audio: {
			src: require("../audio/旁白2304.mp3"),
			autoplay: true,
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/生命音符.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	9: {
		pageBg: require("../images/bg_deep.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson23/page?page=10",
		audioPlayer: {
			src: require("../audio/生命音符圣光之歌.mp3"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "3vh",
			},
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/视唱谱子.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "12vh",
					left: "41vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	10: {
		pageBg: require("../images/bg_deep.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson23/page?page=11",
		roles: [
			{
				roleClass: "musician",
				imgSrc: require("@/assets/image/role/musician.png"),
				style: {
					width: "auto",
					left: "40vw",
					bottom: "9vh",
					zIndex: 8,
				},
				roleStyle: {
					height: `${40}vh`,
					transform: "rotateY(180deg)",
				},
			},
			{
				roleClass: "magician",
				imgSrc: require("@/assets/image/role/magician.png"),
				style: {
					width: "auto",
					left: "52vw",
					bottom: "9vh",
					zIndex: 7,
				},
				roleStyle: {
					height: `${31}vh`,
				},
			},
			{
				roleClass: "knight",
				imgSrc: require("@/assets/image/role/knight.png"),
				style: {
					width: "auto",
					left: "65.5vw",
					bottom: "9vh",
					zIndex: 9,
				},
				roleStyle: {
					height: `${44}vh`,
					transform: "rotateY(180deg)",
				},
			},
		],
		talkList: [
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "这就是生命音符吗？",
				audio: require("../audio/骑士2301.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "这么强大的力量，似乎整个海洋都在随着他的节奏跳动。",
				audio: require("../audio/魔法师2301.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "这种感觉，是生命的节奏吗？让我看看我们该怎么拿走它。",
				audio: require("../audio/宫_补1.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/生命音符.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "10vh",
					left: "-20vw",
					zIndex: 10,
				},
			},
		],
	},
	11: {
		pageBg: require("../images/bg_deep.png"),
		pageNextBtnImage,
		isEnd: true,
		nextPageRoute: "/lesson24/page",
		audio: {
			src: require("../audio/旁白_补1.mp3"),
			autoplay: true,
		},
		roles: [
			{
				roleClass: "musician",
				imgSrc: require("@/assets/image/role/musician.png"),
				style: {
					width: "auto",
					left: "40vw",
					bottom: "9vh",
					zIndex: 8,
				},
				roleStyle: {
					height: `${40}vh`,
					transform: "rotateY(180deg)",
				},
			},
			{
				roleClass: "magician",
				imgSrc: require("@/assets/image/role/magician.png"),
				style: {
					width: "auto",
					left: "52vw",
					bottom: "9vh",
					zIndex: 7,
				},
				roleStyle: {
					height: `${31}vh`,
				},
			},
			{
				roleClass: "knight",
				imgSrc: require("@/assets/image/role/knight.png"),
				style: {
					width: "auto",
					left: "65.5vw",
					bottom: "9vh",
					zIndex: 9,
				},
				roleStyle: {
					height: `${44}vh`,
					transform: "rotateY(180deg)",
				},
			},
		],
		talkList: [
			{
				talker: "blackman",
				talkerCHN: "黑衣人",
				content: "你们不用考虑怎么拿走他，他属于我了！",
				audio: require("../audio/黑衣人2301.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "你是什么人?",
				audio: require("../audio/宫廷乐师2302.mp3"),
			},
			{
				audio: require("../audio/旁白2306.mp3"),
			},
		],
		otherElement: [
			{
				type: "img",
				src: require("../images/黑衣人_blur.png"),
				style: {
					position: "absolute",
					height: "90vh",
					bottom: "0",
					left: "0",
					zIndex: 10,
				},
			},
		],
	},
};
