<template>
	<div
		class="common-page"
		@mousemove="onMove"
		@mouseup="onEnd"
	>
		<img
			src="@/assets/image/next.png"
			@click.stop="$router.push('/lesson6/page?page=3')"
			class="next-btn"
		/>
		<div class="container">
			<div class="nodes">
				<!-- TODO: 绑定drag事件无效 -->
				<div
					class="node"
					v-for="(node, index) in notesArray"
					:key="index"
					:data-draggable="node.draggable"
					:data-index="index"
					:class="{ hide: node.hide }"
					@mousedown="onDragStart"
				>
					<img
						:src="node.src"
						:style="{ height: node.height }"
					/>
				</div>
			</div>
		</div>

		<!-- <img src="./images/" alt="" class="redo"> -->
		<div class="page-footer">
			<div
				class="custom-btn"
				@click="redo()"
			>
				撤回
			</div>
			<div
				class="custom-btn"
				@click="doRestore()"
			>
				还原
			</div>
		</div>
	</div>
</template>

<script>
import { Resize } from "@/utils/Resize";
import { getElementRect } from "@/utils";
class Notes {
	height = "5.1vw";
	src = "";
	constructor({ src, height, hide = false, draggable = 1 }) {
		this.height = height || this.height;
		this.src = src;
		this.draggable = draggable;
		this.hide = hide;
	}
}
const notesArray = [
	// 第一行
	new Notes({
		src: require("./images/44p.png"),
		draggable: 0,
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_1.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_xiaojiexian.png"),
		height: "6.354vw",
		draggable: 0,
	}),
	// 第二行
	new Notes({
		src: require("./images/44p.png"),
		draggable: 0,
		hide: true,
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_1.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_xiaojiexian.png"),
		height: "6.354vw",
		draggable: 0,
	}),
	// 第三行
	new Notes({
		src: require("./images/44p.png"),
		draggable: 0,
		hide: true,
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_1.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_xiaojiexian.png"),
		height: "6.354vw",
		draggable: 0,
	}),
	// 第四行
	new Notes({
		src: require("./images/44p.png"),
		draggable: 0,
		hide: true,
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_2.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_1.png"),
	}),
	new Notes({
		src: require("./images/L6_notes_deepblue_zhongzhixian.png"),
		height: "6.354vw",
		draggable: 0,
	}),
];
export default {
	name: "",
	data() {
		return {
			resize: new Resize(),
			isEle: null,
			isDrag: false,
			offset: null,
			dragElement: null,
			notesArray: JSON.parse(JSON.stringify(notesArray)),
			dragHistory: [], // 存放拖拽数据
		};
	},
	computed: {},
	watch: {},
	filter: {},
	created() {},
	mounted() {
		this.isEle = getElementRect("node", true);
		this.resize.on(this.getRect);
	},
	beforeDestroy() {
		this.resize.unListen();
	},
	methods: {
		getRect() {
			this.isEle = getElementRect("node", true);
		},
		doRestore() {
			this.notesArray = JSON.parse(JSON.stringify(notesArray));
			this.dragHistory.length = 0;
		},
		redo() {
			const result = this.dragHistory.pop();
			if (result) {
				this.notesArray = JSON.parse(result);
			}
		},
		onMove(e) {
			if (this.isDrag) {
				const { clientX, clientY } = e;
				this.dragElement.style.cssText = `left: ${
					clientX - this.offset.x
				}px;top: ${clientY - this.offset.y}px`;
			}
		},
		onEnd({ clientX, clientY }) {
			if (!this.isDrag) return;
			this.isDrag = false;
			const current = this.dragElement.dataset.index;
			this.dragElement.remove();
			this.isEle(clientX, clientY, ({ el }) => {
				if (el.dataset.draggable == 1) {
					this.dragHistory.push(JSON.stringify(this.notesArray));
					const index = el.dataset.index;
					[this.notesArray[current], this.notesArray[index]] = [
						this.notesArray[index],
						this.notesArray[current],
					];
					this.$forceUpdate();
				}
			});
		},
		onDragStart(e) {
			const { target, clientX, clientY, offsetX, offsetY } = e;
			if (e.currentTarget.dataset.draggable == 1) {
				this.isDrag = true;
				this.dragElement = e.currentTarget.cloneNode(target);
				this.dragElement.classList.add("test");
				this.offset = {
					x: offsetX,
					y: offsetY,
				};
				this.dragElement.style.cssText = `left: ${clientX - offsetX}px;top: ${
					clientY - offsetY
				}px`;
				const father = document.querySelector(".common-page");
				father.appendChild(this.dragElement);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.common-page {
	background-image: url("~@/views/lesson5/images/bg_L5_normal.jpg");
}

.container {
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	margin-left: 510px;
	margin-top: 160px;
	.header {
		display: flex;
		flex-direction: column;
		width: 100px;

		.imgbox {
			height: 25%;
		}
	}
}

.nodes {
	width: 800px;
	display: flex;
	flex-wrap: wrap;
}
.node {
	width: 133.333px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: auto 100%;
	&.hide {
		opacity: 0;
	}
}
.test {
	position: absolute;
}

.notes-container {
	position: relative;
	width: 800px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.notes-box {
	flex-shrink: 0;
	width: 100px;
	height: 122px;
	margin-right: 40px;
	margin-bottom: 27px;
	line-height: 122px;
	text-align: center;
	-webkit-user-drag: element;
	cursor: move;
}

.page-footer {
	position: absolute;
	width: 100%;
	bottom: 136px;
	display: flex;
	align-items: center;
	justify-content: center;

	.custom-btn {
		& + .custom-btn {
			margin-left: 193px;
		}
	}
}
</style>
