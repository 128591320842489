import axios from '@/utils/request';

// 音乐课登录接口
export const musicAuthLoginAPI = (params)=>{
	return axios.post('/web/loginAiViewB/musicAuthLogin', params)
}
// 登录token验证
export const musicAuthTokenAPI = (params)=>{
	return axios.post('/web/loginAiViewB/musicAuthToken', params)
}
