<template>
  <div class="common-page">
    <img
      src="@/assets/image/next.png"
      @click.stop="gonext()"
      class="next-btn"
    />
    <LessonEnd v-if="isEnd" nextLessonPath="/lesson3/page-home" />
    <audio ref="audioTip" style="display: none">
      <source :src="audio.src" />
    </audio>

    <div class="imgtalk">
      <div class="context">宫廷乐师和他的学生们又带着独角兽的祝福</div>
      <div class="context1">向幽光森林深处走去。</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEnd:false,
      audio: {
        // 消息通知
        src: require("@/assets/music/end.mp3"),
      },
    };
  },
  mounted() {
    this.$refs.audioTip.play();
  },
  methods: {
    gonext() {
      this.$refs.audioTip.pause();
      this.isEnd = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.common-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
}
.context1 {
  font-size: 30px;
  color: #ffffff;
  margin-top: 20px;
}

.context {
  font-size: 30px;
  color: #ffffff;
}

.img1 {
  width: 148px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.imgtalk {
  background-image: url("~@/assets/image/back03.png");
  width: 65vw;
  height: 70vh;
  background-size: 100%;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 38px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  box-sizing: border-box;
  justify-content: center;
}
</style>
