const pageBg = require("@/views/lesson19/images/bg.png");
const pageNextBtnImage = require("@/assets/image/next.png");

const roles = (scale, indexs, data) => {
	const arr = [
		{
			roleClass: "musician",
			imgSrc: require("@/assets/image/role/musician.png"),
			style: {
				width: "auto",
				left: "34vw",
				bottom: "12vh",
				zIndex: 8,
			},
			roleStyle: {
				height: `${40 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "magician",
			imgSrc: require("@/assets/image/role/magician.png"),
			style: {
				width: "auto",
				left: "12vw",
				bottom: "12vh",
				zIndex: 7,
			},
			roleStyle: {
				height: `${31 * scale}vh`,
			},
		},
		{
			roleClass: "knight",
			imgSrc: require("@/assets/image/role/knight.png"),
			style: {
				width: "auto",
				left: "8vw",
				bottom: "10vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${44 * scale}vh`,
				transform: "rotateY(180deg)",
			},
		},
		{
			roleClass: "seadog",
			imgSrc: require("@/assets/image/role/seadog.png"),
			style: {
				width: "auto",
				right: "4vw",
				bottom: "10vh",
				zIndex: 9,
			},
			roleStyle: {
				height: `${24 * scale}vh`,
			},
		},
	];
	if (Object.prototype.toString.call(indexs) === "[object Array]") {
		indexs.forEach((index) => {
			arr[index] = data[index];
		});
	}
	return arr;
};

export default {
	lessonStart: {
		// 首页
		pageBg, // 首页背景
		pageTitleImage: require("@/views/lesson19/images/title.png"), // 首页标题
		pageBtnImage: require("@/assets/image/startbtn.png"), // 首页按钮
		nextPageRoute: "/lesson19/page?page=1", // 下一页按钮
		audio: {
			src: require("@/assets/music/start.mp3"),
			loop: true,
			controls: true,
			autoplay: true,
		},
	},
	1: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson19/page?page=2",
		roles: roles(1.5, [3], { 3: {} }),
		audio: {
			src: require("../audio/旁白1901.mp3"),
			autoplay: true,
		},
		talkList: [
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "好饿啊，我们还有吃的吗？",
				audio: require("../audio/骑士1901.mp3"),
			},
			{
				talker: "magician",
				talkerCHN: "魔法师",
				content: "三天前我们就没有吃的了，饿的话就喝点儿水吧。",
				audio: require("../audio/魔法师1901.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "不能这样下去，要不然我们还没到神殿就都饿死了。",
				audio: require("../audio/宫廷乐师1901.mp3"),
			},
		],
	},
	2: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson19/page?page=3-1",
		roles: roles(1.5),
		talkList: [
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "哎，你们看，这是什么？是鱼，甲板上怎么会有鱼。",
				audio: require("../audio/骑士1902.mp3"),
			},
			{
				audio: require("../audio/旁白1902.mp3"),
			},
			{
				talker: "seadog",
				talkerCHN: "海豹",
				content:
					"嗨嗨，你们好啊，我已经观察你们三天了，这边很少有人类过来，看来你们都饿坏了吧。",
				audio: require("../audio/海豹1901.mp3"),
			},
			{
				talker: "knight",
				talkerCHN: "骑士",
				content: "是啊，这鱼是你给我们送来的吗？",
				audio: require("../audio/骑士1903.mp3"),
			},
			{
				talker: "seadog",
				talkerCHN: "海豹",
				content: "是哦，我看你们都饿坏了，等等我，我再给你们抓一点儿上来。",
				audio: require("../audio/海豹1902.mp3"),
			},
			{
				audio: require("../audio/旁白1903.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content: "大家快点儿抓啊，不要让鱼跳回海里",
				audio: require("../audio/宫廷乐师1902.mp3"),
			},
		],
	},
	"3-1": {
		pageBg: require("@/views/lesson19/images/bg_blur.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson19/page?page=3-2",
		audioPlayer: {
			src: require("../audio/捞鱼1204.mp3"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "7vh",
			},
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/paper.png"),
				style: {
					position: "absolute",
					width: "60vw",
					top: "50vh",
					left: "20vw",
					cursor: "pointer",
					transform: "translateY(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "text-lines",
				style: {
					position: "absolute",
					left: "50vw",
					top: "35vh",
					fontSize: "2.604vw",
					fontFamily: "Alimama ShuHeiTi",
					fontWeight: "bold",
					color: "#9A5000",
					lineHeight: "1.36em",
					transform: "translateX(-50%)",
					wordSpacing: "50px",
					zIndex: 11,
				},
				textLines: [
					{
						style: {
							textAlign: "center",
							letterSpacing: "8px",
						},
						text: "一网不捞鱼呀",
					},
					{
						style: {
							textAlign: "center",
							letterSpacing: "8px",
						},
						text: "二网不捞鱼呀",
					},
					{
						style: {
							textAlign: "center",
							letterSpacing: "8px",
						},
						text: "三网捞个大金鱼呀",
					},
				],
			},
		],
	},
	"3-2": {
		pageBg: require("@/views/lesson19/images/bg_blur.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson19/game-1",
		audioPlayer: {
			src: require("../audio/捞鱼1204.mp3"),
			autoplay: false,
			controls: true,
			style: {
				bottom: "7vh",
			},
		},
		otherElement: [
			{
				type: "img",
				src: require("../images/paper.png"),
				style: {
					position: "absolute",
					width: "60vw",
					top: "50vh",
					left: "20vw",
					cursor: "pointer",
					transform: "translateY(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/五线谱_捞鱼.png"),
				style: {
					position: "absolute",
					width: "52vw",
					top: "50vh",
					left: "24vw",
					cursor: "pointer",
					transform: "translateY(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	3: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson19/page?page=4",
		roles: roles(1.5),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"感谢小海豹的帮助，要不是你及时给我们送来鱼，恐怕我们就无法前进了。",
				audio: require("../audio/宫廷乐师1903.mp3"),
			},
			{
				talker: "seadog",
				talkerCHN: "海豹",
				content: "不用客气，这里已经很久很久没出现过人类了。我也是很好奇。",
				audio: require("../audio/海豹1903.mp3"),
			},
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"现在大家已经吃饱了，我们要开始学习的新的魔法了，上次的暗礁符号，大家还记得吗？它们其实也有自己的名字哦，叫做“四分休止符”。",
				audio: require("../audio/宫廷乐师1904.mp3"),
			},
		],
	},
	4: {
		pageBg: require("../images/bg_blur.png"),
		pageNextBtnImage,
		nextPageRoute: "/lesson19/page?page=5",
		otherElement: [
			{
				type: "img",
				src: require("../images/paper.png"),
				style: {
					position: "absolute",
					width: "60vw",
					top: "50vh",
					left: "20vw",
					cursor: "pointer",
					transform: "translateY(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "text-lines",
				style: {
					position: "absolute",
					left: "50vw",
					top: "28vh",
					fontSize: "2.604vw",
					fontFamily: "Alimama ShuHeiTi",
					fontWeight: "bold",
					color: "#9A5000",
					lineHeight: "1.36em",
					transform: "translateX(-50%)",
					wordSpacing: "50px",
					zIndex: 11,
				},
				textLines: [
					{
						style: {},
						text: "四分休止符",
					},
				],
			},
			{
				type: "img",
				src: require("../images/四份休止符.png"),
				style: {
					position: "absolute",
					width: "8vw",
					top: "48vh",
					left: "50vw",
					cursor: "pointer",
					transform: "translate(-50%, -50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/xuuuuuuu.png"),
				style: {
					position: "absolute",
					width: "8vw",
					top: "65vh",
					left: "50vw",
					cursor: "pointer",
					transform: "translate(-50%, -50%)",
					zIndex: 10,
				},
			},
		],
	},
	5: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson19/page?page=6",
		roles: roles(1.5),
		talkList: [
			{
				talker: "musician",
				talkerCHN: "宫廷乐师",
				content:
					"我这儿还有一个卷轴，就是等到大家掌握了这个魔法以后才可以打开，里面也有我们的咒语",
				audio: require("../audio/宫廷乐师1905.mp3"),
			},
		],
	},
	6: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson19/page?page=7",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/paper_close.png"),
				style: {
					position: "absolute",
					height: "80vh",
					bottom: "10vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
					cursor: "pointer",
				},
				click(vm) {
					vm.$router.push("/lesson19/page?page=7");
				},
			},
		],
	},
	7: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson19/page?page=8",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/oldpaper.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/do_wuxianpu@2x.png"),
				style: {
					position: "absolute",
					width: "32vw",
					top: "22vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/Re@2x.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "60vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
		],
	},
	8: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson19/page?page=9",
		otherElement: [
			{
				type: "html",
				style: {
					position: "absolute",
					inset: 0,
					backgroundColor: "rgba(0,0,0,.5)",
				},
			},
			{
				type: "img",
				src: require("../images/oldpaper.png"),
				style: {
					position: "absolute",
					height: "76vh",
					bottom: "12vh",
					left: "50vw",
					transform: "translateX(-50%)",
					zIndex: 10,
				},
			},
			{
				type: "img",
				src: require("../images/C@2x.png"),
				style: {
					position: "absolute",
					width: "12vw",
					top: "50vh",
					left: "50vw",
					transform: "translate(-50%, -50%)",
					zIndex: 10,
				},
			},
		],
	},
	9: {
		pageBg,
		pageNextBtnImage,
		nextPageRoute: "/lesson20/page",
		roles: roles(1.5),
		isEnd: true,
		talkList: [
			{
				talker: "seadog",
				talkerCHN: "海豹",
				content:
					"咦，我看你们带着乐器，还会这么多神奇的音符，你们可以帮我一件事情吗？",
				audio: require("../audio/海豹1904.mp3"),
			},
		],
	},
};
