<template>
  <div class="common-page" @click="changetalk(1)">
    <img
      src="@/assets/image/next.png"
      class="next-btn"
      @click.stop="gonext()"
    />
    <!-- <Medal
      style="position: absolute; top: 16%; z-index: 10"
      v-if="showmedal"
    ></Medal> -->
    <audio ref="audioTip" style="display: none">
      <source :src="audio.src" />
    </audio>
    <div style="display: flex; align-items: flex-end">
      <div class="cont1">
        <img
          :class="{ imgtalk1: chooseme == 11, nimgtalk1: chooseme != 11 }"
          draggable="true"
          @dragstart="onDragErrorNote(11)"
          src="@/assets/image/mnote1.png"
        />
        <img
          :class="{ imgtalk2: chooseme == 1, nimgtalk2: chooseme != 1 }"
          @dragstart="onDragErrorNote(1)"
          draggable="true"
          src="@/assets/image/mnote2.png"
        />
        <img
          :class="{ imgtalk3: chooseme == 2, nimgtalk3: chooseme != 2 }"
          @dragstart="onDragErrorNote(2)"
          draggable="true"
          src="@/assets/image/mnote7.png"
        />
        <img
          :class="{ imgtalk4: chooseme == 3, nimgtalk4: chooseme != 3 }"
          @dragstart="onDragErrorNote(3)"
          draggable="true"
          src="@/assets/image/mnote4.png"
        />
        <img
          :class="{ imgtalk5: chooseme == 4, nimgtalk5: chooseme != 4 }"
          @dragstart="onDragErrorNote(4)"
          draggable="true"
          src="@/assets/image/mnote5.png"
        />
        <img
          :class="{ imgtalk6: chooseme == 5, nimgtalk6: chooseme != 5 }"
          @dragstart="onDragErrorNote(5)"
          draggable="true"
          src="@/assets/image/mnote6.png"
        />
        <img
          :class="{ imgtalk8: chooseme == 7, nimgtalk8: chooseme != 7 }"
          @dragstart="onDragErrorNote(7)"
          draggable="true"
          src="@/assets/image/mnote8.png"
        />
        <img
          :class="{ imgtalk9: chooseme == 8, nimgtalk9: chooseme != 8 }"
          @dragstart="onDragErrorNote(8)"
          draggable="true"
          src="@/assets/image/mnote9.png"
        />
        <img
          :class="{ imgtalk10: chooseme == 9, nimgtalk10: chooseme != 9 }"
          @dragstart="onDragErrorNote(9)"
          draggable="true"
          src="@/assets/image/mnote10.png"
        />
        <img
          :class="{ imgtalk11: chooseme == 10, nimgtalk11: chooseme != 10 }"
          @dragstart="onDragErrorNote(10)"
          draggable="true"
          src="@/assets/image/mnote11.png"
        />
        <img
          v-for="(quarterNote, index) of quaterNotes"
          :key="index"
          class="quarter-note"
          :class="{
            disappear: correctQuaterNotes.includes(index),
          }"
          :style="quarterNote.style"
          :src="quarterNote.src"
          @dragstart="onDragCorrectNote(index)"
        />
        <img src="@/assets/image/role/dujiaoshou.png" class="horse" />
        <div @dragenter="ondragenter" class="dujiaoshouin"></div>
      </div>
      <div class="cont2">
        <img
          draggable="false"
          src="@/assets/image/role/fengniao.png"
          class="bird"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import Medal from "@/components/medal.vue";
export default {
  data() {
    return {
      openmagic: false,
      audio: {
        // 消息通知
        src: require("@/assets/video/magic_horse_tune.mp3"),
      },
      quaterNotes: [
        {
          src: require("@/assets/image/quarter note-4.png"),
          style: "top: 60px;right: 0px;",
        },
        {
          src: require("@/assets/image/quarter note-5.png"),
          style: "top: 30px;right: 140px;",
        },
        {
          src: require("@/assets/image/quarter note-3.png"),
          style: "top: 28%;left: -10%;transform: rotate(-15deg);",
        },
        {
          src: require("@/assets/image/quarter note-1.png"),
          style: "top: 10%;left: -35%;",
        },
      ],
      correctQuaterNotes: [],
      medalstate: false,
      showmedal: false,
      chooseme: 0,
      chooseme1: 0,
    };
  },
  components: {
    // Medal,
  },
  mounted() {
    // this.$refs.audioTip.play();
  },
  methods: {
    onDragCorrectNote(index) {
      this.correctQuaterNotes.push(index);
      this.$refs.audioTip.currentTime = 0;
      this.$refs.audioTip.play();
    },
    onDragErrorNote(index) {
      this.chooseme1 = index;
      this.medalstate = false;
    },
    ondragenter() {
      if (this.medalstate) {
        this.chooseme = this.chooseme1;
        this.showmedal = true;
        this.medalstate = false;
        this.$refs.audioTip.currentTime = 0;
        this.$refs.audioTip.play();
        setTimeout(() => {
          this.showmedal = false;
          // this.$router.push("/Btnmagic")
        }, 4000);
      } else {
        this.chooseme = this.chooseme1;
        // this.classList.remove('imgtalk')
        // let arr=document.getElementsByClassName(".imgtalk")
        // arr.removeAttribute('imgtalk')
      }
    },
    gonext() {
      this.$router.push("/lesson2/page-5");
    },
  },
};
</script>

<style scoped lang="scss">
.cont2 {
  height: 706px;
  width: 300px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
}
.quarter-note {
  position: absolute;
  width: 83px;
  &.disappear {
    opacity: 0;
    transition: opacity 1s linear;
  }
}
.dujiaoshouin {
  position: absolute;
  top: 115px;
  left: 132px;
  width: 200px;
  height: 170px;
}

.nimgtalk1 {
  width: 100px;
  height: 160px;
  position: absolute;
  top: -22%;
  left: -38%;
}
.nimgtalk13 {
  width: 83px;
  height: 138px;
  position: absolute;
  top: 10%;
  left: -35%;
}
.imgtalk13 {
  width: 83px;
  height: 138px;
  position: absolute;
  top: 10%;
  left: -35%;
  animation: glow 3s both;
}
.nimgtalk12 {
  width: 80px;
  position: absolute;
  top: 28%;
  left: -10%;
  transform: rotate(-15deg);
}
.imgtalk12 {
  width: 80px;
  position: absolute;
  top: 28%;
  left: -10%;
  animation: glow 3s both;
  transform: rotate(-15deg);
}

.imgtalk1 {
  width: 100px;
  height: 160px;
  position: absolute;
  top: -22%;
  left: -38%;
  animation: glow-in 6s both;
}

.nimgtalk2 {
  width: 70px;
  height: 177px;
  position: absolute;
  top: -13px;
  left: -12%;
}

.imgtalk2 {
  width: 70px;
  height: 177px;
  position: absolute;
  top: -13px;
  left: -12%;
  animation: glow-in 6s both;
}

.nimgtalk3 {
  width: 103px;
  height: 63px;
  position: absolute;
  top: -21%;
  left: 0;
}

.imgtalk3 {
  width: 103px;
  height: 63px;
  position: absolute;
  top: -21%;
  left: 0;
  animation: glow-in 6s both;
}

.nimgtalk4 {
  width: 155px;
  height: 159px;
  position: absolute;
  top: -22%;
  left: 40%;
}

.imgtalk4 {
  width: 155px;
  height: 159px;
  position: absolute;
  top: -22%;
  left: 40%;
  animation: glow-in 6s both;
}

.nimgtalk5 {
  width: 89px;
  height: 151px;
  position: absolute;
  top: -15%;
  left: 79%;
}

.imgtalk5 {
  width: 89px;
  height: 151px;
  position: absolute;
  top: -15%;
  left: 79%;
  animation: glow-in 6s both;
}

.nimgtalk6 {
  width: 113px;
  height: 172px;
  position: absolute;
  top: -15%;
  left: 100%;
}

.imgtalk6 {
  width: 113px;
  height: 172px;
  position: absolute;
  top: -15%;
  left: 100%;
  animation: glow-in 6s both;
}

.nimgtalk7 {
  width: 80px;
  position: absolute;
  top: 30px;
  right: 140px;
}

.imgtalk7 {
  width: 80px;
  position: absolute;
  top: 30px;
  right: 140px;
  animation: glow-in 6s both;
}

.nimgtalk8 {
  width: 118px;
  height: 174px;
  position: absolute;
  top: 10%;
  left: 105%;
}

.imgtalk8 {
  width: 118px;
  height: 174px;
  position: absolute;
  top: 10%;
  left: 105%;
  animation: glow-in 6s both;
}

.nimgtalk9 {
  width: 195px;
  height: 135px;
  position: absolute;
  top: 45%;
  left: 95%;
}

.imgtalk9 {
  width: 195px;
  height: 135px;
  position: absolute;
  top: 45%;
  left: 95%;
  animation: glow-in 6s both;
}

.nimgtalk10 {
  width: 81px;
  height: 150px;
  position: absolute;
  top: 65%;
  left: 75%;
}

.imgtalk10 {
  width: 81px;
  height: 150px;
  position: absolute;
  top: 65%;
  left: 75%;
  animation: glow-in 6s both;
}

.nimgtalk11 {
  width: 79px;
  height: 155px;
  position: absolute;
  top: 55%;
  left: -10%;
}

.imgtalk11 {
  width: 79px;
  height: 155px;
  position: absolute;
  top: 55%;
  left: -10%;
  animation: glow-in 6s both;
}
.nimgtalk15 {
  width: 80px;
  position: absolute;
  top: 60px;
  right: 0px;
}

.imgtalk15 {
  width: 80px;
  position: absolute;
  top: 60px;
  right: 0px;
  animation: glow-in 6s both;
}
.cont1 {
  height: 80%;
  width: 550px;
  margin-right: 200px;
  margin-top: 100px;
  position: relative;
  display: flex;
  align-items: center;
  img {
    -webkit-user-drag: unset;
  }
}

.img1 {
  width: 148px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.common-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}

.imgtalk {
  animation: glow-in 6s both;
}

.glowIn {
  color: white;
}

.glowIn > span {
  animation: glow-in 2s both;
}

@keyframes glow-in {
  from {
    opacity: 0;
  }

  65% {
    opacity: 1;
    text-shadow: 0 0 25px white;
  }

  75% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}
@keyframes glow {
  from {
    opacity: 1;
  }

  65% {
    opacity: 0.7;
    text-shadow: 0 0 25px white;
  }

  75% {
    opacity: 0.5;
  }

  to {
    opacity: 0;
  }
}
</style>