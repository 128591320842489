<template>
  <div class="common-page" >
    <img src="@/assets/image/next.png" @click.stop="gonext()" class="next-btn"  >
    <!-- <audio ref="audioTip" :src="audio.src" autoplay style="display:none;">
    </audio> -->
    <div class="gamecon">
      <div>
        连线游戏：把左右两边的音符对应关系连起来
      </div>
      <!-- <div>
        ，需要有重新画、撤回、查看答案的选项
      </div> -->
    </div>

    <div class="imgtalk" >
      <div class="font2" >
        <div class="font1">
          <div>s</div>
          <div class="fontline">mm</div>
          <div class="fontline">ss</div>
          <div>m</div>
        </div>
        <img src="@/assets/image/fournote1.png" class="img21" >
      </div>
      <div class="font3" >
        <div class="font4">
          <div class="fontline">sm</div>
          <div class="fontline">ms</div>
          <div>s</div>
          <div>m</div>
        </div>
        <img src="@/assets/image/fournote2.png" class="img22" >
      </div>
      <div class="font3" >
        <div class="font5">
          <div class="fontline">mm</div>
          <div class="fontline">sm</div>
          <div>m</div>
          <div>s</div>
        </div>
        <img src="@/assets/image/fournote3.png" class="img23" >
      </div>
      <div class="font3" >
        <div class="font6">
          <div class="fontline">mm</div>
          <div class="fontline">ms</div>
          <div>m</div>
          <div>m</div>
        </div>
        <img src="@/assets/image/fournote4.png" class="img24" >
      </div>

    </div>
    <canvas id="canvas" @mousedown="mousedown1" @mouseup="mouseup1" style="
      position: absolute;width: 100vw;height: 100vh;z-index: 5;">
      </canvas>
    <div class="contbotoom">
        <div class="custom-btn" @click="back()">撤回</div>
        <div class="custom-btn" @click="clear()">清屏</div>
        <div class="custom-btn" @click="checkright()">查看答案</div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audio: {    // 消息通知
        src: require("@/assets/music/xiao-tiao-zao-banzou.mp3"),
      },
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 0,
      cliarr: [],
      cliarr1: [],
    };
  },
  mounted() {
  },
  methods: {
    back() {
      if (this.cliarr.length == 1) {
        this.x1 = 0;
        this.y1 = 0;
      }
      this.cliarr.length = this.cliarr.length - 1;
      this.cliarr1.length = this.cliarr1.length - 1;
      var canvas = document.getElementById('canvas');

      canvas.width = document.documentElement.clientWidth;
      canvas.height = document.documentElement.clientHeight;
      if (canvas.getContext('2d')) {
        var context = canvas.getContext('2d');
        // 应用context绘制
        // 从坐标(100,100)为终点开始
        context.moveTo(this.x1, this.y1);
        // 到坐标(700,700)为起点画一条直线
        for (let i = 0; i < this.cliarr.length; i++) {
          context.moveTo(this.cliarr1[i][0], this.cliarr1[i][1]);
          context.lineTo(this.cliarr[i][0], this.cliarr[i][1]);
        }

        // context.lineTo(100, 700)
        // 线宽为5
        context.lineWidth = 5;
        // 线的款式色彩
        context.strokeStyle = '#fff';
        // 执行划直线这个操作
        context.stroke();
      } else {
       alert("当前浏览器不支持canvas，请更换浏览器后重试");
      }
    },
    clear() {

      this.x1 = 0;
      this.y1 = 0;
      this.cliarr = [];
      this.cliarr1 = [];
      var canvas = document.getElementById('canvas');

      canvas.width = document.documentElement.clientWidth;
      canvas.height = document.documentElement.clientHeight;
      if (canvas.getContext('2d')) {
        var context = canvas.getContext('2d');
        // 应用context绘制
        // 从坐标(100,100)为终点开始
        // 到坐标(700,700)为起点画一条直线
        for (let i = 0; i < this.cliarr.length; i++) {
          context.moveTo(this.cliarr1[i][0], this.cliarr1[i][1]);
          context.lineTo(this.cliarr[i][0], this.cliarr[i][1]);
        }

        // context.lineTo(100, 700)
        // 线宽为5
        context.lineWidth = 5;
        // 线的款式色彩
        context.strokeStyle = '#fff';
        // 执行划直线这个操作
        context.stroke();
      } else {
       alert("当前浏览器不支持canvas，请更换浏览器后重试");
      }
    },
    checkright() {
      this.cliarr = [];
      this.cliarr1 = [];

      let btn1 = document.getElementsByClassName("font1");
      let btn2 = document.getElementsByClassName("font4");
      let btn3 = document.getElementsByClassName("font5");
      let btn4 = document.getElementsByClassName("font6");
      let img21 = document.getElementsByClassName("img21");
      let img22 = document.getElementsByClassName("img22");
      let img23 = document.getElementsByClassName("img23");
      let img24 = document.getElementsByClassName("img24");
      // let btn5 = document.getElementsByClassName("bbtn5")

      let btnn1x = btn1[0].getBoundingClientRect().x + btn1[0].getBoundingClientRect().width; 
      let btnn2x = btn2[0].getBoundingClientRect().x + btn2[0].getBoundingClientRect().width; 
      let btnn3x = btn3[0].getBoundingClientRect().x + btn3[0].getBoundingClientRect().width; 
      let btnn4x = btn4[0].getBoundingClientRect().x + btn4[0].getBoundingClientRect().width; 
      let btnn1y = btn1[0].getBoundingClientRect().y + btn1[0].getBoundingClientRect().height / 2;
      let btnn2y = btn2[0].getBoundingClientRect().y + btn2[0].getBoundingClientRect().height / 2;
      let btnn3y = btn3[0].getBoundingClientRect().y + btn3[0].getBoundingClientRect().height / 2;
      let btnn4y = btn4[0].getBoundingClientRect().y + btn4[0].getBoundingClientRect().height / 2;
      this.cliarr.push([btnn1x, btnn1y]);
      this.cliarr.push([btnn2x, btnn2y]);
      this.cliarr.push([btnn3x, btnn3y]);
      this.cliarr.push([btnn4x, btnn4y]);
      let img21x = img21[0].getBoundingClientRect().x; 
      let img22x = img22[0].getBoundingClientRect().x; 
      let img23x = img23[0].getBoundingClientRect().x; 
      let img24x = img24[0].getBoundingClientRect().x; 
      let img21y = img21[0].getBoundingClientRect().y + img21[0].getBoundingClientRect().height / 2;
      let img22y = img22[0].getBoundingClientRect().y + img22[0].getBoundingClientRect().height / 2;
      let img23y = img23[0].getBoundingClientRect().y + img23[0].getBoundingClientRect().height / 2;
      let img24y = img24[0].getBoundingClientRect().y + img24[0].getBoundingClientRect().height / 2;
      this.cliarr1.push([img24x, img24y]);
      this.cliarr1.push([img21x, img21y]);
      this.cliarr1.push([img22x, img22y]);
      this.cliarr1.push([img23x, img23y]);
      var canvas = document.getElementById('canvas');

      canvas.width = document.documentElement.clientWidth;
      canvas.height = document.documentElement.clientHeight;
      if (canvas.getContext('2d')) {
        var context = canvas.getContext('2d');
        // 应用context绘制
        // 从坐标(100,100)为终点开始
        // 到坐标(700,700)为起点画一条直线
        for (let i = 0; i < this.cliarr.length; i++) {
          context.moveTo(this.cliarr[i][0], this.cliarr[i][1]);

          context.lineTo(this.cliarr1[i][0], this.cliarr1[i][1]);
        }

        // context.lineTo(100, 700)
        // 线宽为5
        context.lineWidth = 5;
        // 线的款式色彩
        context.strokeStyle = '#fff';
        // 执行划直线这个操作
        context.stroke();
      } else {
       alert("当前浏览器不支持canvas，请更换浏览器后重试");
      }
    },
    gonext() {
      this.$router.push("/lesson4/page-3");
    },
    mousedown1(e) {

      // var cas = document.getElementById('cas');
      // var context = cas.getContext('2d');
      // context.lineTo(100,100)
      this.cliarr1.push([e.clientX, e.clientY]);
      // let img = document.getElementsByClassName('img');
      // console.log('img', img.getClientRects);



    },
    mouseup1(e) {

      // let x1 = this.x1;
      // let y1 = this.y1;
      // let x2 = e.clientX;
      // let y2 = e.clientY;
      this.cliarr.push([e.clientX, e.clientY]);
      var canvas = document.getElementById('canvas');

      canvas.width = document.documentElement.clientWidth;
      canvas.height = document.documentElement.clientHeight;
      if (canvas.getContext('2d')) {
        var context = canvas.getContext('2d');
        // 应用context绘制
        // 从坐标(100,100)为终点开始
        
        // 到坐标(700,700)为起点画一条直线
        for (let i = 0; i < this.cliarr.length; i++) {
          context.moveTo(this.cliarr1[i][0], this.cliarr1[i][1]);
          context.lineTo(this.cliarr[i][0], this.cliarr[i][1]);
        }

        // context.lineTo(100, 700)
        // 线宽为5
        context.lineWidth = 5;
        // 线的款式色彩
        context.strokeStyle = '#fff';
        // 执行划直线这个操作
        context.stroke();
      } else {
       alert("当前浏览器不支持canvas，请更换浏览器后重试");
      }


    },
  }
};
</script>

<style scoped>
.custom-btn {
  background-image: url('~@/assets/image/custom-btn01.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 145px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 23px;
  cursor: pointer;
}
.contbotoom {
  /* margin-top: 100px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 9;
  width: 700px;
}
.font2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 44px;
}
.font3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}


.fontline {
  text-decoration: underline;
}

.img21 {
  width: 333px;
  height: 90px;
}
.img22 {
  width: 333px;
  height: 90px;
}
.img23 {
  width: 333px;
  height: 90px;
}
.img24 {
  width: 333px;
  height: 90px;
}

.font1 {
  font-size: 33px;
  color: #FFFFFF;
  display: flex;
  width: 300px;
  justify-content: space-around;
  padding-left: 88px;
}
.font4 {
  font-size: 33px;
  color: #FFFFFF;
  display: flex;
  width: 300px;
  justify-content: space-around;
  padding-left: 88px;
}
.font5 {
  font-size: 33px;
  color: #FFFFFF;
  display: flex;
  width: 300px;
  justify-content: space-around;
  padding-left: 88px;
}
.font6 {
  font-size: 33px;
  color: #FFFFFF;
  display: flex;
  width: 300px;
  justify-content: space-around;
  padding-left: 88px;
}

.gamecon {
  font-size: 32px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 44px;
}

.imgmargin {
  margin-top: 90px;
}

.img4 {
  width: 130px;
  height: 135px;
}

.img3 {
  width: 130px;
  height: 135px;
  margin-right: 100px;
}

.img2 {
  width: 300px;
  height: 77px;
  margin-top: 50px;
}

.common-page {
  background-image: url('~@/assets/image/back04.png');
  background-size: 100%;
  width: 100vw;
  height: 100vh;
  /* background-repeat: no-repeat; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
}

.imgtalk {
  background-image: url('~@/assets/image/back06.png');
  width: 1000px;
  height: 520px;
  background-size: 100%;
  background-repeat: no-repeat;
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding-bottom: 30px;
  box-sizing: border-box;
}
</style>
